import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

export default function ContactUs() {
  return (
    <Flex
      py={{ base: "30px", lg: "60px" }}
      bgColor="#171788"
      maxW="1330px"
      w="100%"
      h="auto"
      mx="auto"
    >
      <Box
        mx="auto"
        maxW="1170px"
        w="100%"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box display="flex" gridGap="30px" width="full" flexDir={{ base: "column", xl: "row" }} alignItems="center" justifyContent={{ md: "center", xl: "space-between" }}>
          <Heading
            color="#FFFFFF"
            fontWeight="semibold"
            fontSize={{ xl: '80px', lg: '60px', md: '40px', base: '40px' }}
            lineHeight={{
              xl: '90px',
              lg: '61px',
              md: '47px',
              base: '91.33px',
            }}
            letterSpacing="2px"
            fontFamily="FedraSansCondensedMedium, sans-serif"
          >
            CONTACT US
          </Heading>

          <Box paddingBottom={{ base: '10px', md: '0px' }}>
            <LazyLoadImageComponent
              display="inline-block"
              src="https://static.sadhguru.org/d/46272/1692178867-vector-1.svg"
              width={{ base: '19px', md: 'auto' }}
            />
            <Text
              paddingLeft={{ md: '20px', base: '10px' }}
              display="inline-block"
              color="#FFFFFF"
              fontWeight={400}
              fontSize={{ xl: '24px', lg: '20px', md: '18px', base: '18px' }}
              lineHeight="25.66px"
            >
              +91 83000 84888
            </Text>
          </Box>
          <Box>
            <LazyLoadImageComponent
              display="inline-block"
              src="https://static.sadhguru.org/d/46272/1692178997-mail-01-1.svg"
              width={{ base: '19px', md: 'auto' }}
            />
            <Text
              paddingLeft={{ md: '20px', base: '10px' }}
              display="inline-block"
              color="#FFFFFF"
              fontWeight={400}
              fontSize={{ xl: '24px', lg: '20px', md: '18px', base: '18px' }}
              lineHeight="25.66px"
            >
              leadership@ishainsight.org
            </Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
