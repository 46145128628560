/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import CommonComponents from '@ila/Common/CommonComponents';
import StyleConfig from '@ila/Common/StyleConfig';
import ILASection from '@ila/Section/ILASection';
import ImpactSection from '@ila/Section/ImpactSection';
import InitiativesLedBySadhguru from '@ila/Section/InitiativesLedBySadhguru';
import InsightPastSpeaker from '@ila/Section/InsightPastSpeaker';
import InsightVideo from '@ila/Section/InsightVideo';
import LeadersBanner from '@ila/Section/LeadersBanner';
import LeadershipPrograms from '@ila/Section/LeadershipPrograms';
import LookBackSection from '@ila/Section/LookBackSection';
import SadhguruOnLeadership from '@ila/Section/SadhguruOnLeadership';
import StickyBar from '@ila/Section/StickyBar';
import SubscribeSection from '@ila/Section/SubscribeSection';
import LeaderReimagined from '@ila/components/Gallery/LeaderReimagined';
import MidContainer from '@ila/components/Layout/MidContainer';
import KeyResourceTestimonial from '@ila/components/Testimonial/KeyResourceTestimonial';
import ContactUs from '@landing_pages/ila/Section/ContactUs';
import HeroSection from '@landing_pages/ila/Section/HeroSection';
import InsightTopCarouselSection from '@landing_pages/ila/Section/InsightTopCarouselSection';
import InsightWhyAttend from '@landing_pages/ila/Section/InsightWhyAttend';
import InterestedForm from '@ila/Section/InterestedForm';
import Offers from '@landing_pages/ila/Section/OfferSection';
import ParticipantsSharing from '@landing_pages/ila/Section/ParticipantsSharing';
import PastSpeakersShare from '@landing_pages/ila/Section/PastSpeakersShare';
import Pricing from '@landing_pages/ila/Section/PricingSection';
import ProgramOverview from '@landing_pages/ila/Section/ProgramOverview';
import WhatToExpect from '@landing_pages/ila/Section/WhatToExpect';

/**
 * Renders Render ILA Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render ILA Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  pageData,
  mode,
  currRegion,
}) => {
  const sectionId = section?.sectionId;

  if (sectionId === 'blogs') {
    return <></>;
  } else if (sectionId === 'top-banner-insight') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <InsightTopCarouselSection sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'key-resource-leader') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <KeyResourceTestimonial sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'sticky-bar') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <StickyBar sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'insight-intro') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <InsightVideo sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'ila-intro') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <HeroSection sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'leadership-programs') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <LeadershipPrograms sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'leaders-banner') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <LeadersBanner sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'sadhguru-on-leadership') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <SadhguruOnLeadership sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'past-speakers-share') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <PastSpeakersShare sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'why-you-should-attend-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <InsightWhyAttend sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'participant-sharings') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ParticipantsSharing sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (currRegion !== 'us' && sectionId === 'offers-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Offers sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (currRegion === 'us' && sectionId === 'offers-section-us') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Offers sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'interested-form-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <InterestedForm
          sectionContent={section.sectionContent}
          secId={section.sectionId}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'look-back-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <LookBackSection sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'what-to-expect-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <WhatToExpect sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  }
  if (sectionId === 'impact-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ImpactSection sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'initiatives-led-by-sadhguru') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <InitiativesLedBySadhguru sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'program-pricing-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Pricing sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (sectionId === 'insight-from-past-speakers') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MidContainer w="1330px" mx="auto">
          <InsightPastSpeaker sectionContent={section?.sectionContent} />
        </MidContainer>
      </StyleConfig>
    );
  } else if (sectionId === 'at-leadership-academy') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MidContainer w="1330px" mx="auto">
          <ILASection sectionContent={section.sectionContent} />
        </MidContainer>
      </StyleConfig>
    );
  } else if (sectionId === 'leadership-reimagined') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MidContainer w="1330px" mx="auto">
          <LeaderReimagined sectionContent={section.sectionContent} />
        </MidContainer>
      </StyleConfig>
    );
  } else if (sectionId === 'newsletter-subscription') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MidContainer w="100%" mx="auto">
          <SubscribeSection
            sectionContent={section.sectionContent}
            lang={lang}
          />
        </MidContainer>
      </StyleConfig>
    );
  } else if (sectionId === 'program-overview-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ProgramOverview sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'contact') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ContactUs sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else {
    return (
      <Box as="div" display="block" placeContent="center">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
              return (
                <StyleConfig
                  id={section?.sectionId}
                  key={section?.sectionId}
                  style={section?.styleConfig}
                  section={section}
                  // pB={{ base: '0', md: '15px' }}
                >
                  <CommonComponents
                    key={nanoid()}
                    sectionContent={secCon}
                    section={section}
                    region={region}
                    language={lang}
                    pType={pType}
                    csrData={csrData}
                    pageData={pageData}
                    index={i}
                    mode={mode}
                  />
                </StyleConfig>
              );
            })
          : null}
      </Box>
    );
  }
};

export default RenderSections;
