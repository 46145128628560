
import {
  Box,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import Slider from 'react-slick';

import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';


const NextArrow = props => {
  const { onClick } = props;
  return (
    <Box
      pos="absolute"
      textAlign={'center'}
      top={{ base:"20%", md:"25%", lg:"45%"}}
      zIndex="10"
      cursor="pointer"
      right={{ base:"10px", lg:"-19px"}}
      bgSize="13px"
      onClick={onClick}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="20"
          r="20"
          transform="matrix(-1 0 0 1 40 0)"
          fill="white"
        />
        <path d="M17 12L25 20L17 28" stroke="black" strokeWidth="2.5" />
      </svg>
    </Box>
  );
};

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <Box
      pos="absolute"
      borderRadius="100%"
      top={{ base:"20%", md:"25%", lg:"45%"}}
      zIndex="10"
      cursor="pointer"
      bgSize="13px"
      bgColor="#fff"
      left={{ base:"10px", lg:"-19px"}}
      onClick={onClick}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="white" />
        <path d="M23 12L15 20L23 28" stroke="black" strokeWidth="2.5" />
      </svg>
    </Box>
  );
};

export default function InsightTopCarouselSection({ sectionContent }) {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex bottom="30px" display={{ base: 'none', md: 'block' }} className="custom dots for slider">
        <Flex
          marginRight="auto"
          marginLeft="auto"
          // width="72px"
          height="7px"
          zIndex={5}
          borderRadius="9px"
          justifyContent="space-around"
        >
          <ul style={{ margin: '0px' }} className="slideDots">
          {dots}{' '}
        </ul>
      
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false
        }
      }
    ]
  };

  return (
    <Box maxW={{ base: '100%', sm: '540px', md: '720px', lg: '960px', xl: '1170px',  '2xl': '1330px' }} pb={{ base: "30x", md: "40px", xl: "60px" }} mx="auto">
      <Box w="full" height="full" position="relative" minH="520px">
        <Slider
          {...settings}
        >
          {
            sectionContent[0]?.slides.map((item, ind) => (
              <Box key={ind} width="full" h={{ base: "100vh", lg: "440px", xl: '520px' }} minH={{ base: "90vh", lg: "440px", xl: '520px' }} bgSize="cover" display="flex" bgImage={{ base: 'none', lg: item?.desktopImage?.url }} bgPos="right top" bgRepeat="no-repeat">
                <Box width="full" minW="320px" bgColor={{ base: "#000", lg: 'transparent' }}>
                  <Box display={{ lg: "none" }}>
                    <Image
                      width="100%"
                      src={item?.mobileImage?.url}
                      alt={item?.mobileImage?.alt || ''}
                      objectFit='cover'
                      objectPosition="top"
                      height={{ base: "320px", md: "520px" }}
                    />
                  </Box>
                
                  <Box height={{ base: "500px", md: "360px", lg: "100%" }} w={{ base: "100%", md: '90%', lg: "40%", xl: "42%" }} p={{ base: '6px', md: '4%', lg: '2%', xl: "3%" }}>
                    <Box pl="10px">
                      <Text
                        fontFamily="'FedraSansStd-medium', sans-serif"
                        fontWeight={500}
                        letterSpacing="0em"
                        fontSize="34px"
                        lineHeight="44px"
                        color="#FFF"
                        as="h1"
                      >
                        {item.title}
                      </Text>
                      <Text
                        className="noP"
                      >
                        <StructuredTextParser
                          str={render(item.titleSubtext)}
                          color="#F27123"
                          fontSize="20px"
                          lineHeight="22px"
                          letterSpacing="0em"
                          mt="0"
                          mb="0"
                          fontFamily="'FedraSansStd-medium', sans-serif"
                        />
                      </Text>
                    </Box>

                    <Box margin="15px 10px 0px 10px">
                      <Text
                        fontSize="18px"
                        lineHeight="24px"
                        fontWeight={400}
                        letterSpacing="0em"
                      >
                        <StructuredTextParser str={render(item.description)}
                          fontSize="18px"
                          lineHeight="160%"
                          fontWeight={400}
                          letterSpacing="0em"
                          mt="0"
                          p="0"
                          mb="0"
                          color="#FDE7C3"
                        />
                      </Text>
                    </Box>

                    <Box paddingLeft="10px" marginBottom="0px">
                      <Text
                        fontWeight={500}
                        letterSpacing="0em"
                        fontSize="24px"
                        lineHeight="33px"
                        color="#FFFFFF"
                      >
                        <StructuredTextParser
                          str={render(item.date)}
                          fontWeight={500}
                          letterSpacing="0em"
                          fontSize="24px"
                          lineHeight="33px"
                          color="#FFFFFF"
                          mt="0"
                          p="0"
                          mb="0"
                          className="noP"
                          fontFamily="'FedraSansStd-medium', sans-serif"
                        />
                      </Text>

                      <Text
                        fontWeight={400}
                        letterSpacing="0em"
                        fontSize="18px"
                        lineHeight="24.48px"
                        color="#F58220"
                      >
                        <StructuredTextParser
                          str={render(item.bottomText)}
                          fontWeight={400}
                          letterSpacing="0em"
                          fontSize="18px"
                          lineHeight="24.48px"
                          color="#F58220"
                          mt="0"
                          mb="0"
                          fontFamily="'FedraSansStd-book', sans-serif"
                        />
                      </Text>
                    </Box>

                  </Box>
                </Box>
              </Box>
            ))
          }
        </Slider>
      </Box>
      <style jsx global>
        {`
            .slideDots li {
              width: 5px;
            }
            .slideDots li button:before {
              color: #FFFFFF;
              width: 9px;
              height: 9px;
              font-size: 9px;
              opacity: 1;
            }
            .slideDots li.slick-active button:before {
              color: #F27123;
              width: 13px;
              height: 13px;
              font-size: 13px;
              opacity: 1;
            }
            `}
      </style>
    
    </Box>
  );
}
