/* Built In Imports */

/* External Imports */
import {
    Box, Flex
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { nanoid } from 'nanoid';
import LeadersCard from '../components/Card/LeadersCard';


/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const LeadersBanner = ({ sectionContent }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const [cards, setCards] = useState([]);
    // const [popupCard, setPopupCard] = useState({});
    // const initialCardCount = 9;
    // const router = useRouter();

    // const loadMorePosts = async () => {
    //     const morePosts = sectionContent[3]?.cards.slice(cards.length, cards.length + initialCardCount);

    //     if (morePosts?.length > 0) {
    //         setCards(prev => [...prev, ...morePosts]);
    //     }
    // };

    // const openPopup = (leader) => {
    //     const parentUrl = `${router.asPath}?popup=1`;
    //     window.history.pushState(window.history.state, '', parentUrl);
    //     setPopupCard(leader);
    //     onOpen();
    // };

    // useEffect(() => {
    //     loadMorePosts();
    // }, []);
    // console.log(sectionContent, "topbanner")
    return (
        <>
            <Box
                maxW={1330}
                mx="auto"
                w="100%"
                bg={{
                    base: `url(${sectionContent?.[0]?.mobileImage?.url || sectionContent?.[0]?.image?.url}) no-repeat`,
                    md: `url(${sectionContent?.[0]?.image?.url}) no-repeat`,
                }}
                bgPos="top center"
                bgSize="cover">
                <Flex justifyContent="center" alignItems="end" width="full" pt={{ base: "100px", sm: "150px", md: "355px" }} pb={{ base: "20px", md: "60px" }} flexWrap="wrap">
                    <LazyLoadImageComponent w={{ base: "109px", md: "230px" }} src={sectionContent?.[1]?.image?.url} title={sectionContent?.[1]?.title || "slide img"} alt={sectionContent?.[1]?.alt || "Leaders Banner"} />
                </Flex>

            </Box>

            <Flex justify="center" flexWrap="wrap" maxW={{
                base: '100%',
                sm: '540px',
                md: '720px',
                lg: '960px',
                xl: '1170px',
                '2xl': '1330px',
            }}
                px={{ base: '16px', md: 0 }}
                mx="auto">
                {/* <Flex flexWrap="wrap" w="full">
                    <Heading
                        fontFamily="FedraSansCondensedMedium, sans-serif"
                        textTransform="uppercase"
                        color="#fff"
                        fontSize={['40px', '40px', '70px', '90px', '90px']}
                        lineHeight={['42px', '42px', '70px', '90px', '90px']}
                        letterSpacing={{ base: '1px', lg: '2px' }}
                        as="h1"
                    >
                        {sectionContent?.[2]?.title}
                    </Heading>

                    <Flex flexWrap="wrap" w="full" fontSize={{ base: "14px", md: "18px" }} color="#D4D4FA" pb="26px">
                        <StructuredTextParser str={render(sectionContent?.[2]?.description)} />
                    </Flex>
                </Flex> */}

                {/* <Flex textAlign="center" gridGap={{ md: "30px" }} flexWrap="wrap" mb="40px">
                    {cards && cards?.map((item) => (
                        <Flex
                            key={item?.uuid}
                            flexDirection="column"
                            maxW={{ base: '293.513px', md: '47%', lg: "31%", xl: '355px' }}
                            mb="40px">

                            <Box
                                display="flex"
                                flexDirection={{ md: 'row' }}
                                marginTop="25px"
                                className="Individual container"
                            >
                                <Box width={{ base: 'auto' }}>
                                    <LazyLoadImageComponent
                                        alt="Participant"
                                        src={item?.image?.url}
                                        w={{ base: '293.513px', md: '100%', lg: '355px' }}
                                        h={{ base: '370.646px', md: '100%', lg: '447.16px' }}
                                        objectFit="cover"
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Text
                                    fontFamily="'FedraSansStd-medium', sans-serif"
                                    fontStyle="normal"
                                    letterSpacing="-0.098px"
                                    fontWeight={500}
                                    color="#fff"
                                    fontSize={{ base: '20px', md: '24px' }}
                                    lineHeight={{ base: '22.495px', md: '27.139px' }}
                                    mt={{ base: '30px', md: '30px' }}
                                >
                                    {item?.title}
                                </Text>
                                <Text
                                    color="#D4D4FA"
                                    lineHeight={{ base: '20px', md: '24px' }}
                                    fontSize={{ base: '14px', md: '16px' }}
                                    mt={{ base: '1px', md: '5px' }}
                                    fontWeight={400}
                                    fontStyle="normal"
                                >
                                    {item?.designation}
                                </Text>
                                <Text
                                    onClick={() => { openPopup(item) }} cursor="pointer"
                                    color="#F27123"
                                    fontFamily="FedraSansStd-book"
                                    fontSize={{ base: '16px', md: '18px' }}
                                    fontStyle="normal"
                                    fontWeight="450"
                                    lineHeight={{ base: '16.871px', md: '20.354px' }}
                                    letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                                    textDecoration="underline"
                                    display="flex"
                                    justifyContent="center"
                                    mt={{ base: '16px', md: '26px' }}
                                >
                                    <Text as="span" mr="5px" >
                                        Read More
                                    </Text>
                                    <BsArrowRightCircle style={{ marginTop: '3px' }} />
                                </Text>
                            </Box>
                        </Flex>
                    ))}
                </Flex> */}
                {/* {sectionContent[3]?.cards && cards.length < sectionContent[3]?.cards.length && (
                    <Link
                        onClick={loadMorePosts}
                        display="block"
                        width="216px"
                        p="10px 23px"
                        fontWeight="500"
                        color="#D8A24C"
                        fontSize="16px"
                        fontFamily="FedraSansStd-medium"
                        textAlign="center"
                        m="30px auto 50px"
                        border="2px solid #D8A24C"
                        borderRadius="1px"
                        title="Load More"
                        rel="next"
                        _hover={{
                            textDecoration: 'none',
                            bgColor: '#D8A24C',
                            color: 'white',
                        }}
                    >
                        LOAD MORE ({cards?.length}/{sectionContent[3]?.cards?.length})
                    </Link>
                )}
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    size={{ base: 'xs', md: 'xs' }}
                    isCentered
                >
                    <ModalOverlay />
                    <ModalContent
                        rounded="none"
                        maxW={1150}
                        p={{ base: '69px 18px 0 32px', md: '40px 0px 31px 26px' }}
                        height={{ base: "100vh", md: 'auto' }}
                        overflow={{ base: "scroll", md: 'unset' }}
                    >
                        <ModalCloseButton outline="0" />
                        <ModalBody px={{ base: "0px", md: '20px' }}>
                            <PopUpCard postData={popupCard} />
                        </ModalBody>
                    </ModalContent>
                </Modal> */}
                {sectionContent.map((section, index) => {
                    if (section._modelApiKey === "ila_leaders_card") {
                        return (
                            <LeadersCard section={section} key={nanoid} loadMorePost={index === sectionContent.length - 1}>
                                {/* <Flex textAlign="center" gridGap={{ md: "30px" }} flexWrap="wrap" mb="40px">
                                    {section.cards && section.cards?.map((item) => (
                                        <Flex
                                            key={item?.uuid}
                                            flexDirection="column"
                                            maxW={{ base: '293.513px', md: '47%', lg: "31%", xl: '355px' }}
                                            mb="40px">

                                            <Box
                                                display="flex"
                                                flexDirection={{ md: 'row' }}
                                                marginTop="25px"
                                                className="Individual container"
                                            >
                                                <Box width={{ base: 'auto' }}>
                                                    <LazyLoadImageComponent
                                                        alt="Participant"
                                                        src={item?.image?.url}
                                                        w={{ base: '293.513px', md: '100%', lg: '355px' }}
                                                        h={{ base: '370.646px', md: '100%', lg: '447.16px' }}
                                                        objectFit="cover"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Text
                                                    fontFamily="'FedraSansStd-medium', sans-serif"
                                                    fontStyle="normal"
                                                    letterSpacing="-0.098px"
                                                    fontWeight={500}
                                                    color="#fff"
                                                    fontSize={{ base: '20px', md: '24px' }}
                                                    lineHeight={{ base: '22.495px', md: '27.139px' }}
                                                    mt={{ base: '30px', md: '30px' }}
                                                >
                                                    {item?.title}
                                                </Text>
                                                <Text
                                                    color="#D4D4FA"
                                                    lineHeight={{ base: '20px', md: '24px' }}
                                                    fontSize={{ base: '14px', md: '16px' }}
                                                    mt={{ base: '1px', md: '5px' }}
                                                    fontWeight={400}
                                                    fontStyle="normal"
                                                >
                                                    {item?.designation}
                                                </Text>
                                                <Text
                                                    onClick={() => { openPopup(item) }} cursor="pointer"
                                                    color="#F27123"
                                                    fontFamily="FedraSansStd-book"
                                                    fontSize={{ base: '16px', md: '18px' }}
                                                    fontStyle="normal"
                                                    fontWeight="450"
                                                    lineHeight={{ base: '16.871px', md: '20.354px' }}
                                                    letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                                                    textDecoration="underline"
                                                    display="flex"
                                                    justifyContent="center"
                                                    mt={{ base: '16px', md: '26px' }}
                                                >
                                                    <Text as="span" mr="5px" >
                                                        Read More
                                                    </Text>
                                                    <BsArrowRightCircle style={{ marginTop: '3px' }} />
                                                </Text>
                                            </Box>
                                        </Flex>
                                    ))}
                                </Flex>
                                {section.cards && cards.length < section?.cards.length && (
                                    <Link
                                        onClick={loadMorePosts}
                                        display="block"
                                        width="216px"
                                        p="10px 23px"
                                        fontWeight="500"
                                        color="#D8A24C"
                                        fontSize="16px"
                                        fontFamily="FedraSansStd-medium"
                                        textAlign="center"
                                        m="30px auto 50px"
                                        border="2px solid #D8A24C"
                                        borderRadius="1px"
                                        title="Load More"
                                        rel="next"
                                        _hover={{
                                            textDecoration: 'none',
                                            bgColor: '#D8A24C',
                                            color: 'white',
                                        }}
                                    >
                                        LOAD MORE ({section.cards?.length}/{section?.cards?.length})
                                    </Link>
                                )}
                                <Modal
                                    isOpen={isOpen}
                                    onClose={onClose}
                                    size={{ base: 'xs', md: 'xs' }}
                                    isCentered
                                >
                                    <ModalOverlay />
                                    <ModalContent
                                        rounded="none"
                                        maxW={1150}
                                        p={{ base: '69px 18px 0 32px', md: '40px 0px 31px 26px' }}
                                        height={{ base: "100vh", md: 'auto' }}
                                        overflow={{ base: "scroll", md: 'unset' }}
                                    >
                                        <ModalCloseButton outline="0" />
                                        <ModalBody px={{ base: "0px", md: '20px' }}>
                                            <PopUpCard postData={popupCard} />
                                        </ModalBody>
                                    </ModalContent>
                                </Modal> */}
                            </LeadersCard>
                        )
                    }
                })}
            </Flex>
        </>
    );
};

export default LeadersBanner;
