/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Link } from '@chakra-ui/react';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';

/* Services */
// import { consoleLog } from '@components/Utility/Shared/SharedService';

let allTags = ['readmore'];

/**
 * Renders parsed string for structured text ShortCode.
 *
 * @param {string} str - HTML string.
 * @param {string} className - Class Name
 * @param {string} region - Region of the page
 * @param {string} textAlign - Alignment of the page
 * @returns {ReactElement} Structured Text Parser Code component
 */

const StructuredTextParser = ({
  sectionContent,
  str,
  mt,
  className,
  textAlign,
  region,
  lang,
  maxW,
  fontSize,
  ...props
}) => {
  const [readMore, setReadMore] = useState(true);
  const structured = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (textAlign && textAlign === 'center-align') {
    textAlign = 'center';
  }

  let innerText = '';
  let initialString = '';
  let startTagIndex = -1;

  // <a([^>]* )href=\"([^\"]+)\"
  const re = new RegExp('href="([^"]+)"', 'g');
  let strMatched = str?.match(re);
  if (strMatched?.length) {
    forEach(strMatched, function (matched) {
      matched = matched.replace('href=', '').replace(/"/g, '');
      str = str.replace(
        `"${matched}"`,
        `"${refineUrlDomain(matched, region, lang)}"`
      );
    });
  }

  forEach(allTags, function (tag) {
    let matched = str?.match(/\[(.*?)\]/g);
    if (matched?.length && matched[0].indexOf(tag) > -1) {
      startTagIndex = str.indexOf(matched[0]);
    }
    initialString = str;
    if (sectionContent?.fieldId === 'read-more-ys' && !isMobile) {
      startTagIndex = -1;
      initialString = initialString
        .replace('[readmore]', '')
        .replace('[/readmore]', '');
    }
    initialString = initialString?.substring(0, startTagIndex) || initialString;

    if (startTagIndex && startTagIndex > -1) {
      let endTagStartIndex = initialString.indexOf(`[/${tag}]`);
      let endTagEndIndex = endTagStartIndex + tag.length + 3;
      let tagTextString = initialString?.substring(
        startTagIndex,
        endTagEndIndex
      );
      innerText = tagTextString
        .replace(`[${tag}]`, '')
        .replace(`[/${tag}]`, '')
        .replace(matched[0], '');
    }
  });

  useEffect(
    () => () => {
      setReadMore(true);
    },
    [str]
  );

  return (
    <Box ref={structured}>
      <Box
        className={className || ''}
        mx={{ base: '0', md: 'auto', lg: 'auto' }}
        mt={{ base: mt || '15px', md: mt || '15px', lg: mt || '15px' }}
        maxW={{
          base: '100%',
          md: maxW || '515px',
          lg:
            maxW || sectionContent?.fieldId === 'read-more-ys'
              ? '590px'
              : '600px',
          xl:
            maxW || sectionContent?.fieldId === 'read-more-ys'
              ? '590px'
              : '770px',
        }}
        textAlign={textAlign || 'left'}
        fontSize={fontSize || '1rem'}
        px={0}
        {...props}
      >
        {initialString && parser(initialString)}
      </Box>
      {startTagIndex > -1 && (
        <Box
          mx="auto"
          mt={{ base: mt || '10px', md: mt || '30px', lg: mt || '30px' }}
          maxW={{
            base: '100%',
            md: '515px',
            lg:
              maxW || sectionContent?.fieldId === 'read-more-ys'
                ? '590px'
                : '600px',
            xl:
              maxW || sectionContent?.fieldId === 'read-more-ys'
                ? '590px'
                : '770px',
          }}
        >
          <Collapse in={!readMore} animateOpacity duration={20000}>
            <Box
              fontFamily="FedraSansStd-book"
              pb="30px"
              className={`${className || ''}`}
              textAlign={textAlign || 'left'}
              {...props}
            >
              {parser(innerText)}
            </Box>
          </Collapse>
          <Box textAlign={'center'} position="relative">
            {sectionContent?.fieldId === 'read-more-ys' && readMore && (
              <Link
                display="inline-block"
                mb="20px"
                fontFamily="FedraSansStd-medium"
                borderRadius="3px"
                borderBottom="none !important"
                bgColor="transparent"
                border="none"
                color={'#721B0F !important'}
                fontSize={{ base: '16px !important', md: '18px !important' }}
                padding="10px 35px"
                textDecoration="none !important"
                transition="none !important"
                _hover={{
                  textDecoration: 'none',
                  bgColor: '#ffffff !important',
                  color: '#D04723 !important',
                  bgSize: '0% 0% !important',
                }}
                textAlign="center"
                width={'200px'}
                onClick={() => setReadMore(!readMore ? true : false)}
              >
                Read More <ChevronDownIcon w={7} h={10} />
              </Link>
            )}
            {sectionContent?.fieldId !== 'read-more-ys' && (
              <Link
                display="inline-block"
                mb="20px"
                fontFamily="FedraSansStd-medium"
                borderRadius="3px"
                bgColor="transparent"
                border="solid 1px #cf4520 !important"
                color={'#cf4520 !important'}
                fontSize="18px !important"
                padding="10px 35px"
                textDecoration="none !important"
                transition="none !important"
                _hover={{
                  textDecoration: 'none',
                  bgColor: '#cf4520',
                  color: 'white !important',
                }}
                textAlign="center"
                width={'200px'}
                onClick={() => setReadMore(!readMore ? true : false)}
              >
                Read {readMore ? 'More' : 'Less'}
                {readMore ? (
                  <ChevronDownIcon w={7} h={10} />
                ) : (
                  <ChevronUpIcon w={7} h={10} />
                )}
              </Link>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default StructuredTextParser;
