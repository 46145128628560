/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

// Utils

/**
 * Renders the Style Config on sectionId
 *
 * @param {object} StyleConfig- Data for section
 * @param StyleConfig-.id
 * @param {object} children - Child Elements
 * @param {string} id - id
 * @param StyleConfig-.children
 * @param StyleConfig-.style
 * @param StyleConfig-.section
 * @param StyleConfig-.bottomAngled
 * @param StyleConfig-.region
 * @param StyleConfig-.width
 * @returns {ReactElement} Designs style config for children component
 */
const StyleConfig = ({
  id,
  children,
  style,
  section,
  bottomAngled,
  region,
  width,
  ...props
}) => {
  return style && style?._modelApiKey ? (
    <Box
      // ? implement image_repeat_type property
      id={id}
      w={width}
      clipPath={
        style?.angledSection
          ? `polygon(0 2%, 100% 0, 100% 100%, 0% 100%)`
          : bottomAngled
          ? `polygon(0 0, 100% 0, 100% 100%, 0% 98%)`
          : 'unset'
      }
      // padding="60px"
      pt={{
        base: style?.angledSection
          ? '104px'
          : props.pT || props.pT?.base || '0',
        md: style?.angledSection ? '80px' : props.pT || props.pT?.md || '0',
      }}
      pb={props.pB || '0'}
      mb={{
        base: style?.additionalBottomPadding ? props.mB || '60px' : '0',
        xl: style?.additionalBottomPadding ? props.mB || '150px' : '0',
      }}
      bgColor={style?.bgColour?.hex || null}
      bgImage={style?.bgImage?.url || null}
      display="flex"
      bgRepeat="none"
      flexDirection="column"
      bgSize="cover"
      {...props}
    >
      {children}
    </Box>
  ) : (
    <Box id={id}>{children}</Box>
  );
};

export default StyleConfig;
