/* Built In Imports */
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { getEmbedUrl } from '@lb/components/Utility/utils';
// import YouTube from 'react-youtube';
import PlayIcon from '../components/Icons/PlayIcon';
/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * Renders WhatToExpect component
 * @param {Object} sectionContent
 * @returns {ReactElement}
 */
const SadhguruOnLeadership = ({ sectionContent }) => {
  const YouTube = dynamic(() => import('react-youtube'))
  const [opts, setOpts] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setOpts({
      height: document.getElementById('msr-vid')?.clientHeight,
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global.window]);

  const onReady = event => {
    event.target.playVideo();
  };
  return (
    <Box
      mx="auto"
      maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
      width="100%"
      bg={`url(${config.imgPath}/d/103700/1691417871-what-to-expect-bg.png)`}
      bgSize="cover"
      h={{ base: '230px', md: '360px', lg: '518px' }}
      bgRepeat="no-repeat"
      mt={{ base: "30px", md: '40px', xl: '60px' }}
      mb={{ base: "148px", md: '120px', lg: '143px', xl: '160px' }}
    >
      <Box
        maxW={{
          base: '100%',
          sm: '540px',
          md: '720px',
          lg: '960px',
          xl: '1070px',
        }}
        mx="auto"
        height="100%"
        width="100%"
      >
        <Box pos="relative" mt={{ base: '30px', lg: '0px' }}>
          <Flex
            w="100%"
            pt={{ base: '119px', sm: '80px', md: '75px', lg: '119px' }}
            pos="relative"
            flexWrap="wrap"
          >
            <Box
              w={{ base: '90%', sm: '450px', md: '530px', lg: '700px' }}
              h={{ base: '230px', sm: '280px', md: '365px', lg: '502px' }}
              pos="relative"
              mx={{ base: 'auto', md: '0' }}
              px={{ base: '16px', lg: 0 }}
              cursor={sectionContent?.[1]?.embedUrl && "pointer"}
            >
              {
                sectionContent?.[1]?.embedUrl && (
                  <Flex
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    onClick={onOpen}
                    alignItems="center"
                    justifyContent="center"
                    zIndex={{ md: '3' }}
                  >
                    <PlayIcon />
                  </Flex>
                )}

              <LazyLoadImageComponent
                src={sectionContent?.[1]?.image?.url}
                objectFit="fill"
                loading="eager"
                alt={sectionContent?.image?.alt || 'Sadhguru on Leadership'}
                w="100%"
                h="100%"
              />
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "6xl" }} isCentered={{ base: false, md: true }}>
              <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
              <ModalContent
                p={0}
                mx="auto"
                my={{ base: "0" }}
                maxW={{ base: '95dvw', xl: '95dvw' }}
                h={{ base: '95dvh', lg: '95dvh', xl: '95dvh' }}
              >
                <ModalCloseButton
                  _hover={{ bg: '#fff', color: '#000' }}
                  _active={{ bg: '#fff', color: '#000' }}
                  _focus={{ bg: '#fff', color: '#000' }}
                  zIndex="20"
                  top={{ base: "-10px", lg: "-24px" }}
                  bg="#fff"
                  color="#000"
                  w={{ base: "35px", md: "48px" }}
                  height={{ base: "35px", md: "48px" }}
                  right={{ base: "-5px", lg: "-15px" }}
                  className="closeButton"
                  rounded="full"
                />
                <ModalBody
                  p={0}
                  m="0"
                  w="100%"
                  position="relative"
                  maxH="100%"
                >
                  <Box
                    pos={{ base: 'static', md: 'static' }}
                    left="50%"
                    right="50%"
                    display={{ base: 'flex', md: 'block' }}
                    justifyContent={{ base: 'center' }}
                    height="100%"
                    alignItems={{ base: 'center' }}
                  >
                    <Box
                      bg="#fff"
                      onClick={onClose}
                      w="48px"
                      h="48px"
                      rounded="full"
                      _hover={{ bg: '#fff' }}
                      display={{ base: 'flex', md: 'none' }}
                      justifyContent="center"
                      alignItems="center"
                      pos="absolute"
                      top="-145px"
                      left="-185px"
                      fontFamily="FedraSansStd-Book"
                      zIndex="10"
                      cursor="pointer"
                      color="black"
                    >
                      x
                    </Box>
                    <Box w="100%" id="msr-vid" bg="#fff" p="14px" h="100%">
                      <YouTube
                        videoId={getEmbedUrl(sectionContent?.[1]?.embedUrl)}
                        onReady={onReady}
                        opts={opts}
                        loading="lazy"
                        className="sandhruguLeadership"
                      />
                    </Box>
                  </Box>
                  {/* </Box> */}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            pos="absolute"
            zIndex={2}
            top={{ base: '20px', md: '30%', xl: '30%' }}
            right="0"
            width={{ base: '100%', lg: '60%' }}
            px={{ base: '16px', lg: '0' }}
          >
            <Text
              color="#fff"
              fontFamily="FedraSansCondensedMedium, sans-serif"
              fontWeight="semibold"
              textTransform="uppercase"
              textAlign={{ base: 'left', md: 'right' }}
              fontSize={['40px', '40px', '70px', '90px', '90px']}
              lineHeight={['42px', '42px', '80px', '90px', '90px']}
              letterSpacing={{ base: '1px', lg: '2px', xl: '3px' }}
              as="h2"
            >
              {sectionContent?.[0]?.text}
            </Text>
          </Flex>
        </Box>

        <style global jsx>
          {`
          .sandhruguLeadership {
                width:100%;
                height: 100%;
              }
            .sandhruguLeadership iframe {
              height: 100%;
            }
            .closeButton:hover {
              background: #ffffff !important;
              color: black !important;
            }
            @media only screen and (max-width: 960px) {
              .sandhruguLeadership {
                width:100%;
                height: 100%;
              }
              .sandhruguLeadership iframe {
                width: 100%;
                height: 100%;
              }
            }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default SadhguruOnLeadership;
