/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import YouTube from 'react-youtube';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { getEmbedUrl } from '@components/Utility/utils';
import config from '@config';
import PlayIcon from '../components/Icons/PlayIcon';
import { LeftQuotes, RightQuotes } from '../components/Icons/Quotes';

/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * Renders InsightVideo component
 * @param {Object} embedContent
 * @returns {ReactElement}
 */
const InsightVideo = ({ embedContent, sectionContent }) => {

  const [opts, setOpts] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setOpts({
      height: "100%",
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global.window]);

  const onReady = event => {
    event.target.playVideo();
  };
  let url = sectionContent[2]?.embedUrl;
  const splitUrl = url?.split('&');
  url = splitUrl[0];

  return (
    <Box py={{ base: "30px", md: "40px", xl: "60px" }}>
      <Box
        mx="auto"
        maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
        width="100%"
        background={`url(${config.imgPath}/d/46272/1692760406-bg-yellow.jpg) no-repeat`}
        backgroundPosition="center"
        backgroundSize="100% 90%"
        height={{ base: 'auto', md: '1150px', lg: '974px', xl: '1060px' }}
        mb={{ base: '0px', md: '20px' }}
      >
        <Box
          maxW={{
            base: '100%',
            sm: '540px',
            md: '720px',
            lg: '960px',
            xl: '1170px',
            '2xl': '1330px',
          }}
          mx="auto"
          w="100%"
          backgroundImage=""
          backgroundPosition="center"
          backgroundSize="cover"
        >
          <Box>
            <Flex
              alignItems={{ base: 'center', md: 'end', lg: 'center' }}
              justifyContent="center"
              flexDir={{ base: 'column-reverse', md: 'row' }}
              h="auto"
              flexWrap="wrap"
            >
              <Box
                w={{ base: '100%', md: '50%' }}
                mt={{ base: '38px', md: '0' }}
                px={{ base: '20px', md: '0' }}
              >
                <Box
                  color="#FFF"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="32px"
                  fontFamily="'FedraSansStd-book', sans-serif"
                  w={{ base: '100%', md: '88%' }}
                  mx="auto"
                >
                  <StructuredTextParser
                    str={render(sectionContent?.[0]?.body)}
                    fontSize={{ base: '16px', lg: '20px' }}
                    color="#FFF"
                    fontStyle="normal"
                    mt={{ md: '59px', base: '40px' }}
                    fontWeight="400"
                    lineHeight="32px"
                    fontFamily="'FedraSansStd-book', sans-serif"
                    className="textWhite"
                  />
                </Box>
              </Box>
              <Flex
                w={{ base: '100%', md: '50%' }}
                maxW={{ base: '313px', lg: '398px' }}
                h={{ base: '224px', md: '300px', lg: '400px' }}
                bg="#FEF3E3"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                objectFit="container"
              >
                <LazyLoadImageComponent
                  src={sectionContent[1]?.image?.url}
                  h="auto"
                  loading="eager"
                  alt={sectionContent?.[1]?.image?.alt || 'Insight'}
                  maxW={{ base: '90%', xl: "350px" }}
                />
              </Flex>
            </Flex>
          </Box>
          <Box pos="relative" mt={{ base: '50px', lg: '100px' }}>
            <Box
              pos="absolute"
              h="100%"
              w={{ base: '100%', lg: '79%', xl: '88%' }}
              left={{ base: '0', lg: '21%', xl: '12%' }}
              bg="linear-gradient(360deg, rgba(8, 8, 74, 0.48) 0%, #08084A 100%)"
              backgroundPosition="right"
              zIndex={1}
              right={0}
              bottom="0"
            ></Box>
            <Flex
              w="100%"
              backgroundPosition="right"
              justifyContent={{ base: 'flex-end', xl: 'center' }}
              height={{ base: 'auto', md: '100%', lg: "500px", xl: '520px' }}
              // w="85%"
              flexDir={{ base: 'column-reverse', xl: 'column' }}
              pt={{ base: '20px', md: '20px', lg: '30px' }}
              pos="relative"
              zIndex={2}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={{ base: '0px', lg: '60px' }}
            >
              <Box
                maxW={{ base: '100%', md: 'none', lg: '670px' }}
                px="0px"
                mt={{ base: '0px', md: '0px' }}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <Box
                  w={{
                    base: '90%',
                    sm: '80%',
                    md: '440px',
                    lg: '470px',
                    xl: '570px',
                  }}
                  //pos="relative"
                  mx={{ base: 'auto', md: '0' }}
                  position={{ base: 'relative', lg: 'absolute' }}
                  top={{ base: '', lg: '70px', xl: '50px' }}
                  left={{ base: '', lg: '30px', xl: '30px' }}
                  cursor={url && 'pointer'}
                  onClick={url && onOpen}
                >
                  {
                    url && (
                      <Flex
                        pos="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PlayIcon />
                      </Flex>

                    )
                  }

                  <LazyLoadImageComponent
                    src={sectionContent[2]?.image?.url}
                    h="100%"
                    objectFit="fill"
                    loading="eager"
                    alt={sectionContent?.[2]?.image?.alt || 'Insight'}
                    w="100%"
                  />
                </Box>
                <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered maxW={{ base: 'auto', md: '1256px' }}
                  h={{ base: '100vh', md: '657px' }}>
                  <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
                  <ModalContent
                    p={0}
                    width={{ base: '100%', md: '95%' }}
                    m="0"
                    bg={{ base: '#000000' }}
                    // transform={{ base: 'rotate(270deg)', md: 'none' }}
                    h={{ base: "100%", md: "auto" }}
                  >
                    <ModalCloseButton
                      _hover={{ bg: '#fff', color: '#000' }}
                      _active={{ bg: '#fff', color: '#000' }}
                      _focus={{ bg: '#fff', color: '#000' }}
                      zIndex="20"
                      top={{ base: '12px', md: '-24px' }}
                      bg="#fff"
                      color="#000"
                      w={{ base: '30px', md: '48px' }}
                      height={{ base: '30px', md: '48px' }}
                      right={{ base: '0', md: '-24px' }}
                      className="closeButton"
                      rounded="full"
                    />
                    <ModalBody
                      p={0}
                      m="0"
                      w="100%"
                      position="relative"
                      height="100%"
                    >
                      <Box
                        pos={{ base: 'static', md: 'static' }}
                        left="50%"
                        top="50%"
                        // transform={{ md: 'none', base: 'translate(-50%, -50%)' }}
                        display={{ base: 'flex', md: 'block' }}
                        justifyContent={{ base: 'center' }}
                        alignItems={{ base: 'center' }}
                        height="100%"
                      >
                        <Box
                          bg="#FFFFFF"
                          onClick={onClose}
                          w="48px"
                          h="48px"
                          rounded="full"
                          _hover={{ bg: '#ffffff' }}
                          display={{ base: 'flex', md: 'none' }}
                          // placeItems="center"
                          justifyContent="center"
                          alignItems="center"
                          pos="absolute"
                          top="-145px"
                          left="-185px"
                          fontFamily="'FedraSansStd-Book', sans-serif"
                          zIndex="10"
                          cursor="pointer"
                          color="black"
                        >
                          x
                        </Box>
                        <Box
                          w="100%"
                          height={{ base: "100vh", md: "90vh", lg: "90vh" }}
                          id="msr-vid"
                          bg={{ base: "#000", md: "#fff" }}
                          p="14px"
                        >
                          <YouTube
                            videoId={getEmbedUrl(url)}
                            onReady={onReady}
                            opts={opts}
                            loading="lazy"
                            className="insightVideo"
                          />{' '}
                        </Box>
                      </Box>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
              <Flex
                w={{ base: 'auto', lg: '100%', xl: '94%' }}
                alignItems="center"
                justifyContent={{ base: 'center', lg: 'flex-end' }}
              >
                <Flex
                  alignItems="center"
                  justifyContent={{ base: 'center', lg: 'flex-end' }}
                  flexDir="column"
                  p={{ lg: '20px' }}
                  w={{
                    base: '264px',
                    md: '70%',
                    lg: '465px',
                    xl: '450px',
                  }}
                >
                  <Heading
                    color="#FFF"
                    fontFamily="'FedraSansCondensedMedium', sans-serif"
                    fontSize={{ base: '40px', xl: '64px' }}
                    fontWeight="semibold"
                    lineHeight={{ base: '42px', md: '64px', lg: '50px', xl: '66px' }}
                    textTransform="uppercase"
                    textAlign="center"
                    pb="24px"
                  >
                    {sectionContent?.[3]?.titleText}
                  </Heading>
                  <Text
                    color="#F8F8F8"
                    textAlign="center"
                    fontSize={{ base: '18px', lg: '20px' }}
                    fontStyle="italic"
                    fontWeight="400"
                    lineHeight="27.2px"
                  >
                    <LeftQuotes />
                    {sectionContent?.[4]?.quote}
                    <RightQuotes />
                  </Text>
                  <LazyLoadImageComponent
                    src="https://static.sadhguru.org/d/46272/1663673930-sadhguru_signature_white.svg"
                    w="137px"
                    h="64px"
                    alt="isha logo"
                    objectFit="contain"
                    my={{ base: '30px', md: '50px' }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>

          <style global jsx>
            {`
            .insightVideo{
              height:100%;
            }
            .insightVideo iframe {
              height: inherit;
            }
            .closeButton:hover {
              background: #ffffff !important;
              color: black !important;
            }
            .textWhite p {
              font-family: 'FedraSansStd-book', sans-serif;
            }
            .textWhite p strong {
              font-family: 'FedraSansStd-medium', sans-serif;
              font-weight: 400;
            }
            @media only screen and (max-width: 550px) {
              .insightVideo {
                width:100%;
                height: 100%;
              }
              .insightVideo iframe {
                width: 100%;
                height: 100%;
              }
            }
          `}
          </style>
        </Box>
      </Box>
    </Box>

  );
};

export default InsightVideo;
