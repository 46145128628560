import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import SliderDots from '@landing_pages/ila/components/Icons/SliderDots';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import Slider from 'react-slick';
import LookBackCard from '../Card/LookBackCard';
const NextArrow = props => {
  const { onClick, background, icon, width, height, rounded, ...rest } = props;
  return (
    <Box
      onClick={onClick}
      pos="absolute"
      zIndex={10}
      right="31.41px"
      cursor="pointer"
      color="#fff"
      fontWeight="bold"
      top="50%"
      fontSize="30px"
      width={width || "30px"}
      height={height || "30px"}
      background={background}
      rounded={rounded}
      _hover={{ color: "white", background: background }}

      _before={{
        content: '""',
        // display: "none"
      }}
      _after={{
        content: '""',
        // display: "none"
      }}
      {...rest}
    >
      {icon || <CgChevronRight />}
    </Box>
  );
};

const PrevArrow = props => {
  const { onClick, icon, background, height, width, rounded, ...rest } = props;
  return (
    <Box
      onClick={onClick}
      pos="absolute"
      cursor="pointer"
      zIndex={10}
      left={0}
      top="50%"
      color="#fff"
      fontWeight="bold"
      width={width || "30px"}
      height={height || "30px"}
      rounded={rounded}
      fontSize="30px"
      background={background}
      _hover={{ color: "white", background: background }}
      _before={{
        content: '""',
        // display: "none"
      }}
      _after={{
        content: '""',
        // display: "none"
      }}
      {...rest}
    >
      {icon || <CgChevronLeft />}
    </Box>
  );
};
const LookBackGallery = ({ gallery }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure()

  // const mSettings = {
  //   initialSlide: imgIndex ? imgIndex : 0,
  //   dots: false,
  //   arrows: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow background='#F27123' fontSize={{ base: "35px", md: "60px" }} height={{ base: "35px", md: "60px" }} width={{ base: "35px", md: "60px" }} rounded="full" right="5px" />,
  //   prevArrow: <PrevArrow background='#F27123' fontSize={{ base: "35px", md: "60px" }} height={{ base: "35px", md: "60px" }} width={{ base: "35px", md: "60px" }} rounded="full" />,
  // }
  const settings = {
    infinite: true,
    lazyLoad: true,
    arrows: true,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesPerRow: 1,
    centerMode: true,
    centerPadding: 40,
    useTransform: false,
    appendDots: dots => (
      <Flex height="40px">
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="2em"
        >
          {dots.map(dot => {
            const button = dot.props.children;

            return (
              <button
                key={dot.key}
                style={{
                  color:
                    Math.ceil(imgIndex).toString() === dot.key
                      ? '#F27123'
                      : '#0E0E6B',
                  width: '19px',
                  height: '13px',
                  borderRadius: '9px',
                }}
              >
                <SliderDots
                  onClick={e => {
                    button.props.onClick(e);
                  }}
                  color={
                    Math.ceil(imgIndex).toString() === dot.key
                      ? '#F27123'
                      : '#0E0E6B'
                  }
                />
              </button>
            );
          })}
        </Flex>
      </Flex>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: 15,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Box>
      {gallery.length > 2 ? (
        <Slider {...settings}>
          {gallery?.map((card, index) => {
            return (
              <LookBackCard
                // cursor="pointer"
                className={
                  imgIndex === index ? 'slickCurrent' : 'slickDeactive'
                }
                // onClick={onOpen}
                blackbg={imgIndex !== index}
                card={card}
                key={nanoid()}
              />
            );
          })}
        </Slider>
      ) : (
        <LookBackCard card={gallery[0]} />
      )}
      {/* <Modal isOpen={isOpen} onClose={onClose} size='6xl' maxW={{ base: 'auto', md: '1256px' }}
        h={{ base: '100vh', md: '657px' }} isCentered>
        <ModalOverlay />
        <ModalContent p={0}
          width={{ base: '100%', lg: '95%' }}
          m="0"
          bg={{ base: '#000000' }}
          h={{ base: "100%", md: "90dvh" }} background="transparent" >
          <ModalBody p={{ base: "0" }} background="transparent" maxH="100%" overflow="hidden">

            <Center pos="relative" bg={{ md: "transparent", base: "#000" }} maxH="100%" h="100%">
              <Box
                bg="#FFFFFF"
                onClick={onClose}
                w="48px"
                h="48px"
                rounded="full"
                _hover={{ bg: '#ffffff' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pos="absolute"
                top={{ base: "0", md: "0px", lg: "35px", }}
                right={{ lg: "30px", md: "10px", base: "0" }}
                fontFamily="'FedraSansStd-Book', sans-serif"
                zIndex="110"
                cursor="pointer"
                color="black"
              >
                <CloseIcon />
              </Box>
              <Box w="90%" h="auto" >
                <Slider {...mSettings}>
                  {gallery?.map((card) => {
                    return (
                      <Image
                        alt={card?.alt || ''}
                        src={card?.url}
                        key={nanoid()}
                        objectPosition="top"
                        maxW="100%"
                        w="100%"
                        height="100%"
                        objectFit="cover"
                        maxH="100%"
                      />
                    );
                  })}
                </Slider>
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <style>
        {`
          .slickDeactive img { padding: 30px 0}

          @media (min-width: 600px) and(max-width: 767px) {
            .slickDeactive img { padding: 20px 0 }
          }

          @media (min-width: 320px) and (max-width: 599px) {
            .slickDeactive img {
              padding: 0px 15px;
            }
          }
          `}
      </style>
    </Box>
  );
};

export default LookBackGallery;
