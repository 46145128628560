/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the YogaCommonUI component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SingleLineText Component.
 */

const SingleLineText = ({
  sectionContent,
  region,
  lang,
  fontStyle,
  lineHeight,
  fontWeight,
  color,
  my,
  ...props
}) => {
  return (
    // <MidContainer>
      <Box maxW="100%" my={my || 10} {...props}>
        <Box
          width="100%"
          fontSize={props.fontSize || { base: '16px', lg: '18px' }}
          color={color || '#28231e'}
          lineHeight={lineHeight}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
        >
          <Box
            textAlign={
              sectionContent?.fieldId === 'ys-benefits-title'
                ? 'center'
                : props.textAlign || 'left'
            }
            color={
              sectionContent?.fieldId === 'ys-benefits-title' ? '#FFF' : color
            }
            paddingTop={
              sectionContent?.fieldId === 'ys-benefits-title'
                ? { md: '155px', base: '96px' }
                : 'auto'
            }
            fontSize={
              sectionContent?.fieldId === 'ys-benefits-title'
                ? { md: '30px', base: '27px' }
                : 'auto'
            }
          >
            {sectionContent?.text}
          </Box>
        </Box>
      </Box>
    // </MidContainer>
  );
};

export default SingleLineText;
