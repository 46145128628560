/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import parser from 'html-react-parser';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';
const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'));

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import CountriesList from '@constants/CountriesList';
import SectionTitle from '../Text/SectionTitle';
const InputUI = dynamic(() => import('@ila/components/Input/InputUi'));

/* Services */
import { addSubscription } from 'services/newsletterService';

/**
 * Renders the Subscribe Box component
 *
 * @param sectionContent
 * @returns {ReactElement} Subscribe Box component.
 */

const SubScriptionBox = ({ sectionContent, section, lang }) => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');
  const [loading, setLoading] = useState(false);
  // const countries = CountriesList;
  const recaptchaRef = createRef();
  // memo(countries);
  // const isSmallScreen = useMediaQuery({ maxWidth: 350 });
  const initialVal = {
    name: '',
    email: '',
    designation: '',
    country: '',
    'opt-in': '',
    newsletters: {},
    dynamicfld2: 'Business',
    source: `${config.BASE_PATH}${router.asPath}`,
  };

  initialVal.newsletters[sectionContent?.newsletterIdentifier] =
    sectionContent?.newsletterIdentifier;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'Name is invalid',
      })
      .max(100, 'Name is invalid')
      .required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+(\.[a-zA-Z]{2,15})+$/, {
        message: 'Email is invalid',
      }),
    designation: Yup.string()
      .required('Designation is required')
      .max(100, 'Designation is invalid'),
    country: Yup.string().required('Country is required'),
    recaptcha: Yup.string().required('Cannot go past captcha'),
  });

  const onSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    const formValues = values;
    formValues['dynamicfld2'] = formValues.designation;
    formValues['language'] = 'en';
    setSubmitting(true);
    const formResponse = await addSubscription(formValues);
    setSubmitting(false);

    resetForm();

    if (typeof formResponse === 'string') {
      setMessageTemp(
        `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
      );
    } else if (
      formResponse[0]?.status_code === 101 ||
      formResponse[0]?.status_code === 102 ||
      formResponse[0]?.status_code === 103 ||
      formResponse[0]?.status_code === 104 ||
      formResponse[0]?.status_code === 105 ||
      formResponse[0]?.status_code === 106 ||
      formResponse[0]?.status_code === 500
    ) {
      setMessageTemp(formResponse[0]?.message_template);
    } else {
      setMessageTemp(
        `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
      );
    }
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   event: 'msr_vp_pushed',
    //   virtual_path: '/shiva_followers_vp_success',
    // });
    onOpen();
    setLoading(false);
    const popupUrl =
      router.asPath.indexOf('?popup=1') > -1
        ? router.asPath
        : router.asPath.indexOf('?') > -1
        ? `${router.asPath}&popup=1`
        : `${router.asPath}?popup=1`;
    window.history.pushState(window.history.state, '', popupUrl);
    window['loc'] = popupUrl;
  });

  useEffect(() => {
    if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
      const parentUrl =
        location.origin +
        location.pathname +
        location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
      window.history.pushState(window.history.state, '', parentUrl);
    }
    // recaptchaRef?.current?.reset();
  }, [isOpen]);

  useEffect(() => {
    window.onpopstate = e => {
      // consoleLog('onpopstate', router, window, e);
      if (window?.loc?.indexOf('popup=1') > -1) {
        // consoleLog('onpopstate return', router.asPath);
        onClose();
        window['loc'] = router.asPath;
      }
    };
  }, [router.asPath]);
  return (
    <>
      <Box w="100%" maxW={557} mx="auto" py={{ base: '30px', md: '60px' }}>
        <Box>
          <Box
            mb="8px"
            color="#8E8E8E"
            textAlign="center"
            fontSize="26px"
            lineHeight="34px"
            fontFamily="FedraSansStd-book"
            fontStyle="normal"
            fontWeight="400"
          >
            <Text as="span" color="#E19254">
              {sectionContent?.content?.json?.titleHighlight}{' '}
            </Text>
            <SectionTitle
              titleObj={{ title1: sectionContent?.content?.json?.title }}
            />
            {/* {sectionContent?.content?.json?.title} */}
          </Box>
          <Text
            color="#D3D3FF"
            textAlign="left"
            fontSize={{ base: '12px', md: '14px' }}
            lineHeight="20px"
            fontFamily="FedraSansStd-book"
            fontStyle="normal"
            fontWeight="400"
            my={{ base: '22px', md: '20px' }}
            paddingLeft="20px"
            mb="35px"
           
          >
            <ul>
              <li>
                {sectionContent?.content?.json?.description1}
              </li>
              <li>
                {sectionContent?.content?.json?.description2}
              </li>
            </ul>
          </Text>
        </Box>
        <Box textAlign="center" display={{ base: 'block', lg: 'none' }}>
          <LazyLoadImageComponent
            w="100%"
            verticalAlign="middle"
            src={section[0]?.content?.json?.subcrriptionImage}
            alt={sectionContent?.content?.json?.title || ''}
          />
        </Box>
        <Flex
          display="flex"
          flexWrap="wrap"
          alignItems={{ base: 'center', md: 'flex-start' }}
          mx={{ base: 'auto' }}
          justifyContent={{ base: 'center', md: 'center' }}
          w={{ base: '100%', md: '557px' }}
        >
          <Formik
            initialValues={initialVal}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {props => (
              <Form
                onSubmit={props.handleSubmit}
                style={{
                  boxSizing: 'border-box',
                }}
              >
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  // mx={{ base: 'auto' }}
                  flexWrap={{ base: 'wrap', md: 'wrap' }}
                  justifyContent="space-between"
                  w={{ base: '100%', md: '557px' }}
                >
                  {sectionContent?.content?.json?.name && (
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.name && form.touched.name}
                          display="flex"
                          flexGrow={1}
                          flexDir="column"
                          w={{
                            base: '100%',
                            md: '267.5px',
                          }}
                          fontFamily="FedraSansStd-book"
                        >
                          <FormLabel htmlFor="UserName">
                            <Text
                              color="#FFF"
                              fontFamily="'FedraSansStd-book'"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="20px"
                              as="span"
                            >
                              {sectionContent?.content?.json?.name ||
                                'Full Name'}
                            </Text>
                          </FormLabel>

                          <InputUI
                            fontFamily="'FedraSansStd-book', sans-serif"
                            color="#605D5D"
                            backgroundColor="#fff"
                            field={field}
                            id="UserName"
                            w={{
                              base: '100%',
                              md: '267.5px',
                            }}
                            height={{ base: '45px', md: '44px' }}
                            placeholder={
                              sectionContent?.content?.json?.name || 'Full Name'
                            }
                            onChange={props.handleChange}
                            padding="20px"
                            borderRadius="6px"
                            _placeholder={{
                              color: '#605D5D',
                              fontSize: '16px',
                            }}
                            m="0 0 26px"
                          />

                          <FormErrorMessage
                            justifyContent="left"
                            position="absolute"
                            margin="0"
                            bottom="0"
                            left="5px"
                          >
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {sectionContent?.content?.json?.email && (
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          w={{
                            base: '100%',
                            md: '267.5px',
                          }}
                          isInvalid={form.errors.email && form.touched.email}
                          flexGrow={1}
                        >
                          <FormLabel htmlFor="userEmail">
                            <Text
                              color="#FFF"
                              fontFamily="FedraSansStd-book"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="20px"
                              as="span"
                            >
                              {sectionContent?.content?.json?.email}
                            </Text>
                          </FormLabel>
                          <InputUI
                            fontFamily="'FedraSansStd-book', sans-serif"
                            color="#605D5D"
                            backgroundColor="#fff"
                            // border="0.3px solid #000000"
                            field={field}
                            id="userEmail"
                            height={{ base: '45px', md: '44px' }}
                            w={{
                              base: '100%',
                              md: '267.5px',
                            }}
                            placeholder={sectionContent?.content?.json?.email}
                            borderRadius="6px"
                            onChange={props.handleChange}
                            padding="20px"
                            _placeholder={{
                              color: '#605D5D',
                              fontSize: '16px',
                            }}
                            m="0 0 26px"
                          />
                          <FormErrorMessage
                            justifyContent="left"
                            position="absolute"
                            margin="0"
                            bottom="0"
                            left="5px"
                          >
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}

                  {sectionContent?.content?.json?.designation && (
                    <Field name="designation">
                      {({ field, form }) => (
                        <FormControl
                          w={{
                            base: '100%',
                            md: '267.5px',
                          }}
                          flexGrow={1}
                          isRequired
                          isInvalid={
                            form.errors.email && form.touched.designation
                          }
                        >
                          <FormLabel htmlFor="userDesignation">
                            <Text
                              color="#FFF"
                              fontFamily="FedraSansStd-book"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="20px"
                              as="span"
                            >
                              {sectionContent?.content?.json?.designation}
                            </Text>
                          </FormLabel>
                          <InputUI
                            fontFamily="'FedraSansStd-book', sans-serif"
                            // border="0.3px solid #000000"
                            field={field}
                            id="userDesignation"
                            height={{ base: '45px', md: '44px' }}
                            w={{
                              base: '100%',
                              md: '267.5px',
                            }}
                            backgroundColor="#fff"
                            placeholder={
                              sectionContent?.content?.json?.designation
                            }
                            borderRadius="6px"
                            onChange={props.handleChange}
                            padding="20px"
                            _placeholder={{
                              color: '#605D5D',
                              fontSize: '16px',
                            }}
                            m="0 0 26px"
                          />
                          <FormErrorMessage
                            justifyContent="left"
                            position="absolute"
                            margin="0"
                            bottom="0"
                            left="5px"
                          >
                            {form.errors.designation}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {sectionContent?.content?.json?.country && (
                    <Field name="country">
                      {({ field, form }) => (
                        <FormControl
                          flexGrow={1}
                          w={{
                            base: '100%',
                            md: '267.5px',
                          }}
                          isRequired
                          isInvalid={
                            form.errors.country && form.touched.country
                          }
                          width="100%"
                          // m={{ base: '0 0 10px 0' }}
                        >
                          <FormLabel htmlFor="userCountry">
                            <Text
                              color="#FFF"
                              fontFamily="FedraSansStd-book"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="20px"
                              as="span"
                            >
                              {sectionContent?.content?.json?.country ||
                                'Country'}
                            </Text>
                          </FormLabel>
                          <Select
                            key="country"
                            {...field}
                            id="userCountry"
                            isRequired={true}
                            placeholder={
                              sectionContent?.content?.json?.country ||
                              'Country'
                            }
                            border="none"
                            width={{ base: '100%', md: '267.5px' }}
                            height={{ base: '45px', md: '44px' }}
                            maxW={{
                              base: '100%',
                              md: '267.5px',
                            }}
                            rounded="0"
                            margin="4.5px"
                            fontFamily="FedraSansStd-book"
                            _focus={{}}
                            _hover={{}}
                            bg="#fff"
                            color="#957F68"
                            m="0 0 26px"
                          >
                            {CountriesList.map(country => {
                              return (
                                <option key={nanoid()} value={country.value}>
                                  {country.name}
                                </option>
                              );
                            })}
                          </Select>
                          <FormErrorMessage
                            justifyContent="left"
                            position="absolute"
                            margin="0"
                            bottom="0"
                            left="5px"
                          >
                            {form.errors.country}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                </Flex>
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  maxW={{ base: '304px', md: '470px' }}
                  gridAutoFlow={{ base: 'row', md: 'column' }}
                  alignItems="center"
                  justifyContent="center"
                  mt="6px"
                  mx={{ base: 'auto' }}
                >
                  <Field name="recaptcha">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.recaptcha && form.touched.recaptcha
                        }
                        m={{ base: '0', md: '0 0px' }}
                        textAlign="center"
                        display="flex"
                        justifyContent="center"
                      >
                        <Box
                          mx={{ base: 'auto', md: '0' }}
                          mt={{ base: '0', md: '0', lg: '4px' }}
                        >
                          {config.RECAPTCHA_KEY && (
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={config.RECAPTCHA_KEY}
                              onChange={success => {
                                success === null
                                  ? recaptchaRef.current.reset()
                                  : props.setFieldValue('recaptcha', success);
                              }}
                              onErrored={error => {
                                props.setFieldValue('recaptcha', '');
                                recaptchaRef.current.reset();
                              }}
                              size="normal"
                            />
                          )}
                        </Box>
                        <FormErrorMessage
                          justifyContent="left"
                          position="absolute"
                          margin="0"
                          bottom="0"
                          left="5px"
                        >
                          {form.errors.recaptcha}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Box h="100%" mt={{ base: '30px', md: '0' }}>
                    <Button
                      type="submit"
                      my="auto"
                      bg="#F27123"
                      w={{ base: '152px', md: '130px' }}
                      mx="auto"
                      h="42px"
                      margin="10px 4.5px"
                      textTransform="uppercase"
                      fontFamily="FedraSansStd-medium"
                      borderRadius="0"
                      border="none"
                      lineHeight="1"
                    >
                      <Flex justifyContent="center" alignItems="center">
                        <Text pr={'5px'}>
                          {loading
                            ? 'Submitting..'
                            : sectionContent?.content?.json?.subscribeButton}
                        </Text>
                      </Flex>
                    </Button>
                  </Box>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Box>

      {messageTemp && (
        <Box
          maxW={messageTemp ? { base: '90%', md: '476px' } : 'auto'}
          m="20px auto"
        >
          <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={!messageTemp ? 'xl' : '4xl'}
            blockScrollOnMount={true}
            closeOnOverlayClick={false}
            // scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent p="30px">
              <ModalCloseButton color="#9F9F9F" mt="1" />
              <ModalBody>{parser(messageTemp || '')}</ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default SubScriptionBox;
