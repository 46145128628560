/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, Flex, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@ila/components/Text/SectionTitle';
// import {
//   NextArrow,
//   PrevArrow,
// } from '@components/ILA/composed/BigScreenParticipantsSharing';
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';
import TestimonialCard from '../Card/TestimonialCard';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


const NextArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide % 1 == 0 && (
        <Box
          pos="absolute"
          textAlign={'center'}
          top="45%"
          display={{ base: 'none', md: 'flex' }}
          zIndex="10"
          cursor="pointer"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ md: '54px', base: '34.4px' }}
          h={{ md: '54px', base: '34.4px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
          right={{ base: '54px', md: '100px', lg: '10px' }}
          bgSize="13px"
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const PrevArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <Box
          pos="absolute"
          top="45%"
          zIndex="10"
          display={{ base: 'none', md: 'flex' }}
          cursor="pointer"
          bgSize="13px"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ base: '34.4px', md: '54px' }}
          h={{ base: '34.4px', md: '54px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
          left={{ base: '3px' }}
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

export default function KeyResourceTestimonial({ sectionContent }) {
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });
  const sliderDotSettings = {
    base: 1,
    md: 3
  };

  const settings = {
    dots: true,
    // arrows: false,
    infinite: false,
    autoplay: false,
    lazyLoad: false,
    slidesToShow: 3.2,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex height="auto" bottom={{base:'-50px', md:'-50px'}} overflow={{base:'hidden', md:'auto'}}>
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          //width="108px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="3em"
        >
          <UnorderedList
            m="0"
            display="flex"
          >
            {/* {dots}{' '} */}
            {dots.map((dot, index) => {
              const button = dot.props.children;
              return (
                <ListItem
                  onClick={e => {
                    // console.log('button.props', dot, activeTab, Math.ceil(activeTab) % sliderDotSettings.md);
                    button.props.onClick(e);
                  }}
                  key={index}
                  width="19px !important"
                  height="13px !important"
                  borderRadius="9px !important"
                  background={(Math.ceil((activeTab) / (sliderDotSettings[isSmallScreen ? 'base' : 'md'])) === parseInt(dot.key)) ? "#F27123" : "#0E0E6B"}
                  margin="0 !important"
                />
              );
            })}
          </UnorderedList>
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 934,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ],
  };

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      px={{ base: '16px', sm: 0 }}
      mx="auto"
      w="100%"
      pt={{ base: '30px', md: '40px', lg: '60px' }}
      pb={{ base: '50px', md: '70px', lg: '106px' }}
    >
      <SectionTitle titleObj={{ titleText: sectionContent[1].title }} />
      <Flex
        w="100%"
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        py={{ base: '0', lg: '0' }}
      >
        <Text
          color="#D4D4FA"
          fontFamily="FedraSansCondensedMedium, sans-serif"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="28.8px"
          maxW={{ base: '100%', md: 500, lg: 755 }}
          w="100%"
        >
          <StructuredTextParser
            str={render(sectionContent[1].description)}
            region={''}
            sectionContent={sectionContent[1]}
            lang={''}
            textAlign={sectionContent[1].description}
            m="0"
            p="0"
          />
        </Text>
        {sectionContent[0]?.linkUrl && (
          <NextLink href={sectionContent[0]?.linkUrl} passHref legacyBehavior>
            <Link
              color="#F17022"
              display={{ base: 'flex', lg: 'inline-flex' }}
              fontFamily="FedraSansStd-book"
              pt="20px"
              textTransform="capitalize"
              textAlign="end"
              justifyContent="end"
            >
              <Text
                as="span"
                mr={{ base: '8px', md: '8px' }}
                textDecor="underline"
                textTransform="uppercase"
              >
                {sectionContent[0]?.buttonText}
              </Text>
              <BsArrowRightCircle
                style={{ marginTop: '6px', fontSize: '14px' }}
              />
            </Link>
          </NextLink>
        )}

      </Flex>

      <Box
        position="relative"
        _before={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: { base: "0", md: '5%' },
          top: '0px',
          right: activeTab % 1 == 0 ? '0px' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
          //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)': 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}

        _after={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: { base: "0", md: '5%' },
          top: '0px',
          left: activeTab > 0 ? '0' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab  > 0 &&
          //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}
      >
        <Slider
          {...settings}
          beforeChange={(old, newChange) => {
            setActiveTab(newChange);
          }}
        >
          {sectionContent[1] &&
            sectionContent[1]?.cards?.map(postData => {
              return (
                <TestimonialCard
                  sectionContent={sectionContent[1]}
                  postData={postData}
                  key={nanoid()}
                />
              );
            })}
        </Slider>
      </Box>
    </Box>
  );
}
