import { Box, Heading, useBreakpointValue } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

const WhatToExpect = ({ sectionContent }) => {

  return (
    <Box pt={{ base: '30px', md: '40px', lg: '60px' }}
      pb={{ base: '50px', md: '70px', lg: '110px' }}>
      <Box
        mx="auto"
        maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
        bgImage={`url(https://static.sadhguru.org/d/103700/1691417871-what-to-expect-bg.png)`}
        bgRepeat="no-repeat"
        bgSize="cover"
        position="relative"
        display="flex"
        h={{ base: '', lg: '465px' }}
        flexDirection={{ base: 'column', md: 'row' }}
        fontFamily="FedraSansStd-book"
      >
        <Box
          w="full"
          maxW={{ base: '100%', md: "720px", lg: '1070px' }}
          mx="auto"
          display="flex"
          flexDir={{ base: 'column', lg: 'row' }}
          alignItems="center"
          justifyContent="center"
          pt={{ md: "30px" }}
          px={{ base: '16px', md: 0 }}

        >
          <Box w={{ base: '100%', sm: "60%", md: "70%", lg: '40%' }} >
            <Heading
              fontSize={{ base: '32px', xl: '90px', lg: '70px', md: '50px' }}
              lineHeight={{ base: '42px', xl: '80px', lg: '60px', md: '50px' }}
              paddingBottom={{ md: '10px', base: '0px' }}
              textTransform="uppercase"
              color="#fff"
              fontFamily="FedraSansCondensedMedium, sans-serif"
              as="h2"
            >
              {sectionContent?.[0]?.text}
            </Heading>
            <Heading
              textTransform="uppercase"
              fontSize={{ base: '26px', xl: '60px', lg: '45px', md: '35px' }}
              lineHeight={{ base: '28px', md: "50px", xl: '55px' }}
              color="#08084A"
              fontFamily="FedraSansCondensedMedium, sans-serif"
            >
              {sectionContent?.[1]?.text}
            </Heading>
          </Box>
          <Box
            width={{ base: '100%', md: "70%", lg: '55%' }}
            display="flex"
            justifyContent="center"
            textAlign={{ base: 'center', md: 'unset' }}
          >
            <LazyLoadImageComponent
              src={useBreakpointValue({
                base: sectionContent?.[2]?.mobileImage?.url,
                lg: sectionContent?.[2]?.image?.url,
              })}
              alt="image"
              h={{ base: 'auto', lg: '456px' }}
              width={{ md: '100%', xl: 'auto' }}
              position="relative"
              bottom={{ base: '-30px', md: '-50px' }}
            />
          </Box>
        </Box>
      </Box>

    </Box>

  );
};

export default WhatToExpect;
