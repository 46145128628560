/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Mid Container component
 *
 * @param {object} props - Layout Props
 * @returns {ReactElement} Mid Container component
 */
const MidContainer = props => {
  return (
    <Box
      w={{ base: '100%' }}
      maxW={props.w || '944px'}
      mx="auto"
      my={props.my}
      bgColor={props.bgColor}
    >
      {props.children}
    </Box>
  );
};
export default MidContainer;
