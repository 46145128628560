/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

/**
 * Renders the Card Info 4 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} CardInfo4 Card component
 */

const WhyAttendCards = ({ cards }) => {
  return (
    <Box maxW="1330px" mx="auto" width="100%">
      <SimpleGrid
        columns={{
          base: '1',
          sm: '2',
          md: '2',
          lg: '3',
          xl: '5',
        }}
        display="flex"
        justifyContent={{ base: 'center', md: "center", xl: 'space-between' }}
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems={{ base: 'center', md: 'unset' }}
        flexWrap={{ base: 'wrap', xl: 'nowrap' }}
        rowGap="20px"
        columnGap="15px"
      >
        {cards?.map((secCon, index) => {
          const margin =
            (index + 1) % 2 === 0
              ? '33px'
              : 0;
          return (
            <Box
              textColor="#fff"
              pos="relative"
              key={nanoid()}
              bgImg={secCon?.thumbnail?.url}
              bgSize="cover"
              h={{ base: '355px', sm: '360px', lg: '356px' }}
              w={{ base: '320px', sm: '260px', md: "31%", lg: '258px' }}
              bgRepeat="no-repeat"
              mt={{
                base: '0',
                xl: margin,
              }}
            >
              <Box display="flex" alignItems="flex-end" height="100%">
                <Box p="20px">
                  <Box
                    fontSize="34px"
                    lineHeight="29px"
                    fontWeight="semibold"
                    letterSpacing="0em"
                    textAlign="left"
                    fontFamily="FedraSansCondensedMedium, sans-serif"
                    pb="4px"
                  >
                    {secCon.title}
                  </Box>
                  <Box
                    mt="16px"
                    fontSize="15px"
                    lineHeight="21px"
                    fontWeight={400}
                    letterSpacing="0em"
                    textAlign="left"
                  >
                    <StructuredTextParser
                      str={render(secCon.description)}
                      region="in"
                      lang="en"
                      mt="0"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default WhyAttendCards;
