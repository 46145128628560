/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import FaqLinkedContent from '@ila/components/Accordion/Faq';
import TopBannerSimple from '@ila/components/Banners/TopBannerSimple';
import MidContainer from '@ila/components/Layout/MidContainer';
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';
import Glimpses from '@landing_pages/ila/components/Gallery/Glimpses';

/* Services */
/* Styles */

/**
 * Renders the CommonComponents Component
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @returns {ReactElement} UpcomingDates component
 */

const CommonComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageData,
  index,
  mode
}) => {
  const router = useRouter();
  return (
    <Box w="100%" display="flex" flexDirection="column" pb="0">
      {RenderComponents({
        sectionContent,
        region,
        language,
        section,
        isLeftMenu,
        pType,
        pageName,
        csrData,
        router,
        pageData,
        index,
        mode
      })}
    </Box>
  );
};

const RenderComponents = ({
  sectionContent,
  region,
  language,
  section,
  pType,
  pageName,
  csrData,
  router,
  pageData,
  index,
  mode
}) => {
  switch (sectionContent._modelApiKey) {
    case 'top_banner_simple':
      return <TopBannerSimple sectionContent={sectionContent} />;
    case 'structured_text':
      return (
        <MidContainer w={mode === 'light' ? '100%' : "696px"} bgColor={mode === 'light' ? "#fff" : ''}>
          <Box
            sx={{
              h2: {
                fontSize: '26px',
              },
              h3: {
                fontSize: '18px',
              },
            }}
            px={{ base: '15px', md: '0' }}
            pb={{ base: '40px', md: '40px' }}
          >
            <StructuredTextParser
              str={render(sectionContent.body)}
              region={region}
              sectionContent={sectionContent}
              lang={language}
              textAlign={sectionContent.style}
              mode={mode}
            />
          </Box>
        </MidContainer>
      );
    case 'gallery':
      return (
        <Glimpses sectionContent={sectionContent} />
      );
    case 'faq_section_group_v2':
      return (
        <FaqLinkedContent
          faqSection={sectionContent?.faqSections}
        />
      );

  }
};

export default CommonComponents;
