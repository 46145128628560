/* Built In Imports */
import Head from 'next/head';
import { useRouter } from 'next/router';

/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';
import { getUtmParameters } from '@utils/index.js';

/**
 * Renders the Head component
 *
 * @param {object} data - content from api
 * @param {object} availableLocals - region and country
 * @param {string} script - <script> tag in header
 * @returns {ReactElement} Head component
 */

const HeadComponent = ({ data, availableLocals, script }) => {
  // const [fonts, setFonts] = useState([]);
  const router = useRouter();
  let linkAdded = false; 

  const updateUtmParameters = () => {    
    return getUtmParameters(router.asPath);
  };

  const seoFieldsHandler = () => {
    if (data && data?.length && typeof data !== 'string') {
      return data.map(d => {
        const newTAG = [];
        let name = '';
        let content = '';
        let property = '';
        let href = '';
        let rel = '';
        if (d.tag === 'meta') {
          Object.entries(d.attributes).map(([k, v]) => {
            name = k.trim() === 'name' ? v : name;
            content = k.trim() === 'content' ? v : content;
            property = k.trim() === 'property' ? v : property;
            if (content !== '' && name !== '') {
              newTAG.push(<meta name={name} content={content} />);
            } else if (content !== '' && property !== '') {
              newTAG.push(
                <meta key={v} content={content} property={property} />
              );
            }
          });
        }
        if (d.tag === 'link' && d.attributes !== null) {
          Object.entries(d.attributes).map(([key, value]) => {
            href = key.trim() === 'href' ? value : href;
            rel = key.trim() === 'rel' ? value : rel;
            if (href !== '' && rel !== '') {
              if (rel === 'canonical' && !href.startsWith('http')) {
                href = `${config.BASE_PATH}${href}`;
              }
              newTAG.push(<link key={value} href={href} rel={rel} />);
            }
          });
        }
        if (d.tag === 'title') {
          newTAG.push(<title>{d.content}</title>);
        }
        return newTAG;
      });
    }
  };

  const setCSSLink = () => {
    setTimeout(function () {
      if (typeof document !== 'undefined' && !linkAdded) {
        linkAdded = true;
        let fileRef = document.createElement(`link`);
        fileRef.rel = `stylesheet`;
        fileRef.type = `text/css`;
        fileRef.href = `${config.staticPath}/assets/fonts/mukta-fonts.css`;
        document.getElementsByTagName(`head`)[0].appendChild(fileRef);
      }
    }, 1800);
    return null;
  };

  return (
    <Head>
      {updateUtmParameters()}
      {seoFieldsHandler()}
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=yes"
      />
      {setCSSLink()}
      <meta name="MobileOptimized" content="width" />
      <meta name="HandheldFriendly" content="true" />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="icon"
        href={`${config.imgPath}/d/46272/1720450736-favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`${config.imgPath}/d/46272/1690792477-isha_logo_black.svg`}
      />
    </Head>
  );
};

export default HeadComponent;
