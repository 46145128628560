import { Box, Flex, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

/**
 * 
 * @param {Object} card 
 * @returns 
 */
const IlaSectionCard = ({ card, ...props }) => {
  return (
    <Flex
      {...props}
    >
      <Flex
        flexDir={{ base: 'row', md: 'column' }}
        mb={{ base: '24px', md: '0' }}
        alignItems={{ base: 'flex-end', md: 'flex-start' }}
      >
        <Box
          mb={{ base: '0px', md: '49.16px' }}
          mr={{ base: '16px', md: '0' }}
        >
          <LazyLoadImageComponent
            src={card?.thumbnail?.url}
            alt={card?.thumbnail?.alt}
            width="100%"
          />
        </Box>
        <Text
          maxW={{ base: 152, md: 218 }}
          color="#FFF"
          fontFamily="FedraSansStd-medium"
          fontSize={{ base: '20px', md: '24px' }}
          fontStyle="normal"
          fontWeight="500"
          lineHeight="26px"
        >
          {card?.title}
        </Text>
      </Flex>
      <StructuredTextParser
        color="#D4D4FA"
        fontFamily="FedraSansStd-book"
        fontSize={{ base: '16px', md: '16px' }}
        fontStyle="normal"
        fontWeight="400"
        lineHeight={{ base: '24px', md: '160%' }}
        str={render(card?.description)}
      />
    </Flex>
  )
}

export default IlaSectionCard