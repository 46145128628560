/* Built In Imports */
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
/* External Imports */
import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Internal Imports */
import { getEmbedUrl } from '@components/Utility/utils';
/* Components */
import SectionTitle from '@ila/components/Text/SectionTitle';
/* Styles */

// import LiteYouTubeEmbed from '@components/Embeds/YoutubeEmbed';
/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */


const NextArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide % 1 == 0 && (
        <Box
          pos="absolute"
          textAlign={'center'}
          top="45%"
          display={{ base: 'none', md: 'flex' }}
          zIndex="10"
          cursor="pointer"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ md: '54px', base: '34.4px' }}
          h={{ md: '54px', base: '34.4px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
          right={{ base: '54px', md: '100px', lg: '10px' }}
          bgSize="13px"
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const PrevArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <Box
          pos="absolute"
          top="45%"
          zIndex="10"
          display={{ base: 'none', md: 'flex' }}
          cursor="pointer"
          bgSize="13px"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ base: '34.4px', md: '54px' }}
          h={{ base: '34.4px', md: '54px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
          left={{ base: '3px' }}
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};
const InsightPastSpeaker = ({ sectionContent }) => {
  const LiteYouTubeEmbed = dynamic(() => import('@components/Embeds/YoutubeEmbed'))
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });

  const sliderDotSettings = {
    base: 1,
    md: 2
  };

  const [opts, setOpts] = useState({});
  // const isMobile = useMediaQuery({ maxWidth: 768 });
  useEffect(() => {
    setOpts({
      height: document.getElementsByClassName('msr-vid')[0]?.clientHeight,
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window]);

  const shares = [];
  sectionContent.map(video => {
    if (video._modelApiKey === 'media_embed_v2') {
      shares.push(video);
    }
  });

  const settings = {
    dots: true,
    // arrows: false,
    infinite: false,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1.9,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex
        height="40px"
        position="relative"
        bottom="0 !important"
      >
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          width="auto"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="3em"
        >
          <UnorderedList
            m="0"
            display="flex"
          >
            {/* {dots}{' '} */}
            {dots.map((dot, index) => {
              const button = dot.props.children;
              return (
                <ListItem
                  onClick={e => {
                    // console.log('button.props', dot, activeTab, Math.ceil(activeTab) % sliderDotSettings.md);
                    button.props.onClick(e);
                  }}
                  key={index}
                  width="19px !important"
                  height="13px !important"
                  borderRadius="9px !important"
                  background={(Math.ceil((activeTab) / (sliderDotSettings[isSmallScreen ? 'base' : 'md'])) === parseInt(dot.key)) ? "#F27123" : "#0E0E6B"}
                  margin="0 !important"
                ></ListItem>
              );
            })}
          </UnorderedList>
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 934,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <Box py={{ base: '30px', md: '40px', xl: '60px' }}>

      <Box
        w="100%"
        h="100%"
        background="#08084A"
        pos="relative"
        pt={{ base: '107.45px', sm: '150px', md: '300px', lg: '380px' }}
      >
        <Box
          backgroundImage={sectionContent?.[1]?.image?.url}
          pos="absolute"
          top={0}
          bottom={{ base: 335, md: 219 }}
          h={{ base: '194.492px', md: '620px' }}
          backgroundSize={{
            base: 'cover',
            md: 'contain',
            lg: 'contain',
            xl: 'cover',
          }}
          backgroundPosition="top"
          backgroundRepeat="no-repeat"
          width="100%"
          zIndex={1}
        ></Box>
        <Flex
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          pos="relative"
          zIndex={2}
          p={{ base: "0 0 0 16px", md: 0 }}
        >
          <Box
            maxW={{ base: '100%', md: '100%', lg: '50%' }}
            ml={{ base: '0px', md: '20px', lg: '10px', xl: '80px' }}
            mb={{ base: '20px', md: '0', lg: '80px' }}
            mt={{ base: '0', md: '0', lg: '57px' }}
          >
            {sectionContent[0] && (
              <SectionTitle
                titleObj={sectionContent[0]}
              />
            )}
          </Box>
          <Box
            w="100%"
            maxW={{ base: '100%', lg: 'calc(100% - 204px)' }}
            id="boxContianingslider"
            // h={{ base: '183px', md: '183px', xl: '268px' }}
            overflow="hidden"
            position="relative"
            p={{ md: "20px" }}
          >
            <Slider
              {...settings}
              beforeChange={(old, newChange) => {
                setActiveTab(newChange);
              }}
            >
              {shares?.map(a => {
                return (
                  <Box
                    key={nanoid()}
                    pr={{ base: '16px', md: '20px' }}
                  // w={{ base: '298px', md: '436px' }}
                  >
                    <Box
                      bg="#FFF4E4"
                      boxShadow={{
                        base: '0px 10px 23.399999618530273px 0px rgba(0, 0, 0, 0.04), 0px 50px 144px 0px rgba(0, 0, 0, 0.07)',
                      }}
                      h={{ base: '183px', md: '183px', xl: '232px' }}
                      maxW={{ base: '298px', md: "100%", lg: '436px' }}
                      className="msr-vid"
                    >
                      <LiteYouTubeEmbed
                        id={getEmbedUrl(a?.video.providerUid)}
                        // autoplay={autoplay}
                        scrollBehavior={true}
                        boxId={'boxContianingslider'}
                        embedUrl={getEmbedUrl(a?.video.providerUid)}
                        cookie
                      />{' '}
                    </Box>
                    <Text
                      color="#FFF"
                      maxW={{ base: '298px', md: "100%", lg: 436 }}
                      textAlign="center"
                      fontFeatureSettings="'case' on"
                      fontFamily="FedraSansCondensedMedium, sans-serif"
                      fontSize={{ base: '22px', md: '32px' }}
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight={{ base: '28px', md: '42px' }}
                      pt={{ base: "10px", md: '20px' }}
                    >
                      {a?.video?.title}
                    </Text>
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default InsightPastSpeaker;
