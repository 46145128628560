import { Box, Container, Flex, Image } from '@chakra-ui/react';
import Slider from 'react-slick';

import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SliderDots from '@landing_pages/ila/components/Icons/SliderDots';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SectionTitle from '../components/Text/SectionTitle';
// import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

const NextArrow = props => {
  const { onClick, currentSlide, slideCount, activeTab } = props;
  return (
    <>
      {currentSlide % 1 == 0 && (
        <Box
          pos="absolute"
          textAlign={'center'}
          top="45%"
          display={{ base: 'none', md: 'flex' }}
          zIndex="10"
          cursor="pointer"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ md: '54px', base: '34.4px' }}
          h={{ md: '54px', base: '34.4px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
          right={{ md: '100px', base: '54px', lg: '10px' }}
          bgSize="13px"
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const PrevArrow = props => {
  const { onClick, activeTab, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <Box
          pos="absolute"
          top="45%"
          zIndex="10"
          display={{ base: 'none', md: 'flex' }}
          cursor="pointer"
          bgSize="13px"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ md: '54px', base: '34.4px' }}
          h={{ md: '54px', base: '34.4px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
          left={{ base: '3px' }}
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const InitiativesLedBySadhguru = ({
  sectionContent,
  maxW,
  pb,
  ml,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });

  const settings = {
    dots: true,
    // arrows: false,
    infinite: false,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex height="40px">
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          width="108px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="3em"
        >
          {dots.map(dot => {
            const button = dot.props.children;
            return (
              <button
                key={dot.key}
                style={{
                  color:
                    Math.ceil(activeTab).toString() === dot.key
                      ? '#F27123'
                      : '#0E0E6B',
                  width: '19px',
                  height: '13px',
                  borderRadius: '9px',
                }}
              >
                <SliderDots
                  onClick={e => {
                    button.props.onClick(e);
                  }}
                  color={
                    Math.ceil(activeTab).toString() === dot.key
                      ? '#F27123'
                      : '#0E0E6B'
                  }
                />
              </button>
            );
          })}
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <Container
      maxW={{ base: 400, md: 690, lg: 896, xl: 1170 }}
      width="100%"
      p="0"
      pt={{ base: "30px", md: '40px', xl: '60px' }}
      pb={{ base: "60px", md: '70px', xl: '110px' }}
    >
      <Box pl={{ base: '16px', md: 0 }}>
        <Box pb={{ base: '30px', lg: '38px' }}>
          {' '}
          <SectionTitle titleObj={sectionContent[0]} />{' '}
        </Box>

        <Box
          position="relative"
          maxWidth="1330px"
          _before={{
            display: true,
            content: "''",
            height: '99%',
            position: 'absolute',
            bottom: '0px',
            width: { base: "0", md: '5%' },
            top: '0px',
            right: activeTab % 1 == 0 ? '0px' : 'none',
            zIndex: 2,
            // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
            //   'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)' : 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
          }}

          _after={{
            display: true,
            content: "''",
            height: '99%',
            position: 'absolute',
            bottom: '0px',
            width: { base: "0", md: '5%' },
            top: '0px',

            left: activeTab > 0 ? '0' : 'none',
            zIndex: 2,
            // background: isSmallScreen ? 'none' : (activeTab > 0 &&
            //   'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
          }}
        >
          <Slider
            {...settings}
            beforeChange={(old, newChange) => {
              setActiveTab(newChange);
            }}
          >
            {sectionContent &&
              sectionContent?.[1]?.testimonials?.map((item, i) => {
                return (
                  <Box key={nanoid()} pr={{ base: '16px', md: '20px' }}>
                    <Flex flexDir={{ base: 'column', xl: 'row' }}>
                      <Image
                        src={item?.photo?.url}
                        alt={item?.photo?.alt}
                        width={{ base: '', md: '100%', xl: '526px' }}
                        height={{
                          base: '',
                          md: '552px',
                          lg: '412px',
                          xl: '589px',
                        }}
                        objectFit="cover"
                        id={i}
                      />
                      <Box p={{ base: '0px', xl: '24px' }}>
                        <Box
                          mb="24px"
                          pt="34px"
                          color="#FFF"
                          fontFamily="FedraSansCondensedMedium, sans-serif"
                          fontSize="40px"
                          fontStyle="normal"
                          fontWeight="500"
                          lineHeight="110%"
                        >
                          {item?.userName}
                        </Box>
                        <Box
                          width="100%"
                          color="#D4D4FA"
                          fontFamily="FedraSansStd-book"
                          fontSize="18px"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="160%" /* 28.8px */
                          display="flex"
                        >
                          <StructuredTextParser
                            str={render(item?.testimonialText)}
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};
export default InitiativesLedBySadhguru;
