/* Built In Imports */
/* External Imports */
import { Box, Heading, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const HeroSection = ({ sectionContent }) => {
  function updatePageObject(sectionContent) {
    const object = sectionContent.reduce((acc, item) => {
      acc[item.fieldId] = item;
      return acc;
    }, {});
    return object;
  }
  const updatedPageObject = updatePageObject(sectionContent);

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      px={{ base: '16px', sm: 0 }}
      mx="auto"
      w="100%"
    >
      <Box
        display="flex"
        alignItems={{ md: 'center' }}
        gridRowGap="20px"
        flexDirection={{ base: 'column', md: 'row' }}
        color="#fff"
      >
        <Box
          fontSize={{ lg: '16px', xl: '18px' }}
          maxW={{ base: '90%', md: '60%', lg: '370px', xl: '310px' }}
          order={{ base: 2, md: 1 }}
          p={{ base: '30px 0 0 0', md: 0 }}
          pt={{ base: 0, xl: '70px' }}

        >

          <StructuredTextParser
            str={render(sectionContent[3].body)}
            region={''}
            sectionContent={sectionContent[3]}
            lang={''}
            textAlign={sectionContent[3]?.style}
            color="#D4D4FA"
            w="full"
            mx="auto"
            fontSize="18px"
            lineHeight="29px"
            fontFamily="'FedraSansStd-book', sans-serif"
            className='textWhite'
            mt="0px"
            letterSpacing="0.3px"
          />
        </Box>
        <Box order={{ base: 1, md: 2 }} width="100%" >
          <Heading
            pt={{ base: '2px', lg: '3px', lg: '30px' }}
            as="h1"
            lineHeight={{
              base: '59px',
              xl: '80px',
            }}
            fontSize={{
              base: '60px',
              lg: '88px',
              md: '68px',
              xl: '140px',
            }}
            //mr={{ base: '0', md: '-30px', xl: '-115px' }}
            fontFamily="'FedraSansCondensedMedium', sans-serif"
            letterSpacing={{ base: '2px', lg: '4px', xl: '1px' }}

          >
            {updatedPageObject?.line1?.text}
            <Box
              as="span"
              display="flex"
              my={{ base: '0px', md: '10px', lg: '25px', xl: '55px' }}
              width="100%"
              justifyContent={{ base: 'flex-start', md: 'space-around' }}
              pl={{ base: '0px', xl: '0px' }}
              letterSpacing="1px"
            >
              {updatedPageObject.line2.text}
            </Box>
            <Box
              as="span"
              display="flex"
              pl={{ base: 0, xl: "35px" }}
              letterSpacing="1px"
            >
              {updatedPageObject.line3.text}
            </Box>
          </Heading>{' '}
        </Box>
      </Box>
      <Box
        py={{ base: '15px', md: '40px', lg: '30px' }}
        mt={{ base: 0, md: '40px' }}
        d={{ base: 'none', md: 'block' }}
      >
        <Image src={sectionContent[4]?.desktopImage?.url} width="100%" />
      </Box>
      <Box
        py={{ base: '15px', md: '40px', lg: '30px' }}
        mt={{ base: 0, md: '40px' }}
        d={{ base: 'block', md: 'none' }}
      >
        <Image src={sectionContent[4]?.mobileImage?.url} width="100%" />
      </Box>
      <Box mx="auto">
        <StructuredTextParser
          str={render(sectionContent[5].body)}
          region={''}
          sectionContent={sectionContent[5]}
          lang={''}
          textAlign={sectionContent[5]?.style}
          color="#D4D4FA"
          w="full"
          mx="auto"
          fontSize="18px"
          lineHeight="29px"
          fontFamily="'FedraSansStd-book', sans-serif"
          className='textWhite'
        />
      </Box>
      <style jsx global>{`
        .textWhite p {
          font-family: 'FedraSansStd-book', sans-serif;
          padding-bottom:15px;
          font-size:18px;
        }
        .textWhite p strong {
          font-family:'FedraSansStd-medium', sans-serif;
          font-weight: 400;
        }
        @media (max-width:992px) {
          .textWhite {
            padding:0 !important
          }
          .textWhite p {
            font-size:16px;
            margin-top:0px
          }
        }
      `}</style>
    </Box>
  );
};

export default HeroSection;
