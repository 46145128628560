import { Box, Flex, Text } from '@chakra-ui/react';

/* Built In Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Components */
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';

const ImpactSection = ({ sectionContent }) => {
    return (
        <Box my={{ base: "30px", md: '40px', xl: '60px' }} maxW={{ base: '100%', sm: '540px', md: "720px", lg: '960px', xl: "1330px" }} w="100%" mx="auto"
            bg={{ base: `url(${sectionContent[2]?.mobileImage?.url}) no-repeat`, md: `url(${sectionContent[2]?.image?.url}) no-repeat` }}
            bgSize={{ base: "cover", lg: "auto" }}
        //bgImg={`linear-gradient(94deg, rgb(13 25 181 / 93%) 0%, rgb(217 104 34 / 94%) 100%), url(${config.imgPath}/d/46272/1692002943-group-1237-1-1.jpg)`}
        >
            <Box maxW={{ base: '100%', sm: '540px', md: "720px", lg: '960px', xl: "1170px" }} w="100%" px={{ base: "16px", lg: 0 }} mx="auto">
                <Flex alignItems="center" justifyContent={{ base: "center", md: "space-between" }} flexDir={{ base: "column", md: "row" }} gridGap={{ base: "0px", lg: "20px" }} px={{ base: "16px", md: 0 }} height={{ base: "400px", xl: "430px" }}>
                    <Flex className="impactSec" w={{ base: "full", md: "60%", xl: '50%' }}>
                        <StructuredTextParser str={render(sectionContent?.[0]?.body)} mt="0px" fontSize="54px" />
                    </Flex>
                    <Flex alignItems="stretch" w="full" flexWrap={{ base: "wrap", xl: "nowrap" }}>
                        {
                            sectionContent?.[1]?.cards.map((item, i) => (
                                <Flex key={`cardI_s${i}`} w={{ base: "50%", xl: "25%" }} color="#fff" borderBottom={{ base: i === 2 || i === 4 ? 'none' : i === 3 ? 'none' : '1px dashed #fff', xl: 'none' }} borderRight={{ base: i === 3 || i === 1 ? 'none' : '1px dashed #fff', xl: i === 3 ? 'none' : '1px dashed #fff' }}>
                                    <Flex w="full" pb={{ base: i === 0 || i === 1 ? "24px" : '0px', xl: 0 }} pl={{ base: i === 1 || i === 3 ? "24px" : '0px', xl: i === 1 || i === 2 || i === 3 ? "24px" : '0px' }}>
                                        <Text fontFamily="FedraSansCondensedMedium, sans-serif" fontWeight="semibold" fontSize="40px">
                                            {item?.title}
                                            <Box as="span" display="flex" fontSize="16px" fontFamily='FedraSansStd-book' fontWeight="normal">
                                                <StructuredTextParser str={render(item?.description)} color="#fff" mt="0" />
                                            </Box>
                                        </Text>
                                    </Flex>

                                </Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Box>
            <style>{`
    .impactSec {
        margin-top:0px
    }
    .impactSec p, .impactSec p mark  { color: #fff; font-weight: 600;}
    .impactSec p {
        font-family: "FedraSansCondensedMedium";
        font-size: 54px;
        letter-spacing: 2px;
        line-height: 116%;
    }
    .impactSec p mark {
        display:flex;
        background: none;
        font-size:90px;
        letter-spacing: 1px;
        line-height: 96%;
    }
    
    @media (max-width:767px) {
        .impactSec {
            margin-top:0px;
        }
        .impactSec p {
            margin-top:0px;
            font-size: 32px;
            letter-spacing: 0px;
            line-height: 116%;
        }
        .impactSec p mark {
            font-size: 56px;
            line-height: 50px;
            line-height: 96%;
        }
    }

    @media (min-width:768px) and (max-width:991px) {
        .impactSec {
            margin-top:0px;
        }
        .impactSec p {
            margin-top:0px;
            font-size: 40px;
            letter-spacing: 0px
        }
        .impactSec p mark {
            font-size: 70px;
            line-height: 50px;
        }
    }

    @media (min-width:1024px) and (max-width:1199px) {
        .impactSec {
            margin-top:0px;
        }
        .impactSec p {
            margin-top:0px;
            font-size: 40px;
            letter-spacing: 0px
        }
        .impactSec p mark {
            font-size: 70px;
            line-height: 50px;
        }
    }
    `}</style>
        </Box>
    );
};

export default ImpactSection;
