
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import GlimpsesSlider from '@landing_pages/ila/components/Gallery/GlimpsesSlider';


const Glimpses = ({ sectionContent }) => {
  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
      }}
      px={{ base: '16px', sm: 0 }}
      mx="auto"
      background="#08084A"
      pt={{ base: '30px', md: '40px', lg: '60px' }}
      pb={{ base: '50px', md: '70px', lg: '106px' }}
    >

      <Flex flexDirection="column" mb="20px">
        <Heading
          fontFamily="FedraSansCondensedMedium, sans-serif"
          color="#D8A24C"
          fontSize={['30px', '30px', '30px', '42px', '42px']}
          lineHeight={['31px', '31px', '31px', '51px', '51px']}
          letterSpacing={{ base: '1px', lg: '2px' }}
          as="h2"
        >
          {sectionContent.title}
        </Heading>
        <Text
          fontSize="42px"
          lineHeight="51px"
          fontWeight={500}
          letterSpacing="0em"
          zIndex={1}
          textAlign="left"
        >
        </Text>
      </Flex>
      <GlimpsesSlider images={sectionContent.images}></GlimpsesSlider>
    </Box>
  );
};

export default Glimpses;