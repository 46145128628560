import { Box, Image } from '@chakra-ui/react';

const LookBackCard = ({ card, blackbg, onClick, ...props }) => {
  // console.log(props, "lookback card")
  return (
    <Box
      margin={0}
      border={0}
      w="100%"
      pos={blackbg ? 'relative' : 'static'}
      {...props}
    >
      {blackbg && (
        <Box
          position="absolute"
          width="100%"
          // height="100%"
          top={{ base: '0px', sm: '30px', md: '30px' }}
          left="0"
          bottom={{ base: '0px', sm: '30px', md: '30px' }}
          right={0}
          display={{ base: 'none', sm: 'none', md: 'block' }}
          background="#000"
          opacity={'0.9'}
        ></Box>
      )}
      <Image
        onClick={onClick}
        w={{ base: '100%', md: '100%', lg: '600px' }}
        h={{ base: '181px', md: '370px' }}
        alt={card?.alt || ''}
        src={card?.url}
        objectFit="cover"
      />
    </Box>
  );
};

export default LookBackCard;
