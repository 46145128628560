import { Box, Flex } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import React from 'react';
import SubScriptionBox from '@ila/components/Subscription/SubScriptionBox';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const SubscribeSection = ({ sectionContent, lang }) => {
  return (
    <Box
      maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
      mx="auto"
      background="rgb(12 12 93)"
      w="100%"
      py="30px"
      mt={{ base: '30px', md: '60px' }}
    >
      <Box
        maxW={{
          base: '100%',
          sm: '540px',
          md: '720px',
          lg: '960px',
          xl: '1170px',
          '2xl': '1330px',
        }}
        px={{ base: '20px', xl: 0 }}
        mx="auto"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          maxW={{ base: '100%', md: '100%', lg: 1157 }}
          mx="auto"
          w="100%"
          px={{ base: '0px', md: '90px', lg: '0' }}
        >
          <Box
            w={{ base: '0', lg: '542px' }}
            display={{ base: 'none', lg: 'block' }}
            textAlign="center"
          >
            <LazyLoadImageComponent
              w="100%"
              src={sectionContent?.[1]?.image?.url}
              alt={sectionContent[1]?.image?.alt || 'SUBSCRIPTION'}
            />
          </Box>
          <Box
            maxW={{ base: '100%', md: 557 }}
            w="100%"
            ml={{ base: '0', lg: '58px' }}
          >
            <SubScriptionBox sectionContent={sectionContent[0]} section={sectionContent} lang={lang} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default SubscribeSection;
