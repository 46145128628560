export default function SliderDots({ ...props }) {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.928711" width="19" height="13" rx="6.5" fill={props.color} />
    </svg>
  );
}
