import { Box, Flex, Image, Text } from '@chakra-ui/react';
import StructuredTextParser from '@landing_pages/ila/components/Text/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

import SliderDots from '../Icons/SliderDots';

export default function MobileScreenPastShareSlider({
  activeTab,
  shares,
  setActiveTab,
}) {
  const settings = {
    dots: true,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    autoPlay: true,
    infinite: false,
    arrows: false,
    appendDots: dots => (
      <Flex height="40px" position='relative'>
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          width="108px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
        >
          {dots.map(dot => {
            const button = dot.props.children;

            return (
              <button
                key={dot.key}
                style={{
                  color:
                    activeTab.toString() === dot.key ? '#F27123' : '#0E0E6B',
                  width: '19px',
                  height: '13px',
                  borderRadius: '9px',
                }}
              >
                <SliderDots
                  onClick={e => {
                    button.props.onClick(e);
                  }}
                  color={
                    activeTab.toString() === dot.key ? '#F27123' : '#0E0E6B'
                  }
                />
              </button>
            );
          })}
        </Flex>
      </Flex>
    ),
  };

  return (
    <Box
      position="relative"
      _before={{
        display: activeTab === shares?.length - 1 ? 'none' : 'block',
        content: "''",
        position: 'absolute',
        bottom: '0px',
        width: { base: "0", md: '25%' },
        top: '0px',
        right: '0px',
        zIndex: 5,
        height: '99%',

      }}
      _after={{
        display: activeTab !== shares?.length - 1 ? 'none' : 'block',
        content: "''",
        position: 'absolute',
        bottom: '0px',
        height: '99%',
        width: { base: "0", md: '25%' },
        top: '0px',
        left: '0px',
        zIndex: 5,
      }}
    >
      <Slider
        {...settings}
        beforeChange={(old, newChange) => {
          setActiveTab(Math.ceil(newChange));
        }}
      >
        {shares?.map(postData => {
          return (
            <Box key={nanoid()} px="20px">
              <Box display="flex" flexDirection={{ md: 'row' }}>
                <Box>
                  <Image
                    src={postData?.photo?.url}
                    width="100%"
                    height="100%"
                    alt={postData?.photo?.alt || ''}
                  />
                </Box>

                <Flex flexDir="column" justify="flex-end" ml="10px">
                  <Text
                    paddingBottom={{ base: '10px' }}
                    color="#fff"
                    lineHeight={{ base: '17.34px', md: '27.14px' }}
                    fontSize={{ base: '20px', md: '28px' }}
                    fontWeight="semibold"
                  >
                    {postData.userName}
                  </Text>
                  <StructuredTextParser
                    paddingBottom={{ base: '15px' }}
                    color="#D8A24C !important"
                    mt="0px"
                    lineHeight={{ base: '14px', md: '24px' }}
                    fontSize={{ base: '12px', md: '16px' }}
                    str={render(postData?.userDescription)}
                  />
                </Flex>
              </Box>
              <Box>
                <StructuredTextParser
                  color="#D4D4FA"
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="24px"
                  str={render(postData.testimonialText)}
                />
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}
