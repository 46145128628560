/* Built In Imports */
/* External Imports */
import axios from 'axios';
import forEach from 'lodash/forEach';
import cache from 'memory-cache';

/* Internal Imports */
/* Components */
import config from '@config';
import CountriesList from '@constants/CountriesList';

/* Services */
import { consoleLog } from '@components/Utility/Shared/SharedService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';

const isCache = false;
const API_PREFIX = '/content/fetchiso';

export const filterBuildUrls = (section, level, url, notMatchText, data) => {
  const urls = [];
  // only urls with these patterns will be built
  // let isRegexFilters = ['/en/wisdom/article', '/sg/en/'];
  // to build everything , keep isRegexFilters empty
  //  consoleLog('urlArr', data);
  // let isRegexFilters = [];
  if (level === 'lvl0' || level === 'home_page') {
    if (data?.[level]?.length) {
      forEach(data[level], function (data) {
        if (data && data.length < 200 && data.indexOf(url) > -1) {
          // if (notMatchText && data.indexOf(notMatchText) > -1) {
          //   return;
          // }
          let isAvailable = false;
          if (notMatchText && data.indexOf(notMatchText) > -1) {
            return;
          } else if (Array.isArray(notMatchText)) {
            // consoleLog('Array', notMatchText);
            forEach(notMatchText, function (link) {
              if (link && data.indexOf(link) > -1) {
                isAvailable = true;
              }
            });
          }
          if (!isAvailable) {
            // consoleLog('data', data);
            urls.push(encodeURI(data));
          }
        }
      });
    }
  } else if (
    level === 'L1' ||
    level === 'L2' ||
    level === 'L3' ||
    level === 'L4'
  ) {
    let buildSection = data && data[section];
    if (!buildSection && section === 'others_section') {
      // consoleLog('buildSection', buildSection, section, level);
      const siteSections = [
        'wisdom',
        'sadhguru',
        'health',
        'center',
        'yoga&meditation',
        'events',
      ];
      forEach(siteSections, function (secType) {
        if (!buildSection) {
          buildSection = data && data[secType];
        }
      });
    }
    if (buildSection?.[level]?.length) {
      // consoleLog('if buildSection', buildSection?.[level]);
      forEach(buildSection[level], function (data, index) {
        if (data && data.length < 200 && data.indexOf(url) > -1) {
          let isAvailable = false;
          if (notMatchText && data.indexOf(notMatchText) > -1) {
            return;
          } else if (Array.isArray(notMatchText)) {
            forEach(notMatchText, function (link) {
              if (link && data.indexOf(link) > -1) {
                isAvailable = true;
              }
            });
          }
          if (!isAvailable) {
            urls.push(encodeURI(data));
          }
        }
      });
    }
  } else if (section === 'home_page') {
    const buildSection = data && data[section];
    if (buildSection?.length) {
      forEach(buildSection, function (data) {
        urls.push(encodeURI(data));
      });
    }
  } else if (section === 'maha_yoga_yagna') {
    const buildSection = data && data[section];
    if (buildSection?.length) {
      forEach(buildSection, function (data) {
        urls.push(encodeURI(data));
      });
    }
  }
  return urls;
};

export const getLatestBuild = async buildId => {
  let pageData = {};
  if (buildId && !process.env.SSR_MODE && !process.env.NEXT_PUBLIC_SSR_MODE) {
    const buildUrl = `${config.FACADE_BASE_PATH}/content/build_urls_info/${buildId}`;
    const cachedResponse = cache.get(buildUrl);
    if (!isCache && cachedResponse) {
      pageData = cachedResponse;
    } else {
      // console.log('Build Url calling::>', buildUrl, pageData);
      pageData = await fetch(buildUrl);
      pageData = await pageData?.json();
      cache.put(buildUrl, pageData, 24 * 1000 * 60 * 60);
      console.log('Build Url::>', buildUrl);
    }
  }
  return pageData;
};

export const getHomePageLandingPaths = async (level, url, notMatchText) => {
  const data = config.BUILD_URLS.HOME.enabled
    ? config.BUILD_URLS.HOME
    : await getLatestBuild(
        process.env.NEXT_PUBLIC_BUILD_ID ||
          process.env.env_var_home_build_id ||
          process.env.env_var_build_id
      );

  let urlArr = filterBuildUrls('home_page', level, url, notMatchText, data);
  if (!urlArr || !urlArr.length) {
    urlArr = filterBuildUrls('sadhguru', level, url, notMatchText, data);
  }
  return urlArr ? urlArr : [];
};

export const fetchHeaderTopMenuData = async (locale, menutype) => {
  let headerTopMenuResponse = [];
  try {
    headerTopMenuResponse = await new IsoBackendHttpService().get(
      `${API_PREFIX}/menu`,
      !isCache,
      {
        params: {
          format: 'json',
          menutype: menutype || '',
          locale,
        },
      }
    );
  } catch (e) {
    consoleLog(e);
  }
  return headerTopMenuResponse;
};

export const fetchHeaderMenuData = async (locale, menutype) => {
  let headerMenuResponse = [];
  try {
    headerMenuResponse = await new IsoBackendHttpService().get(
      `${API_PREFIX}/menu`,
      !isCache,
      {
        params: {
          format: 'json',
          menutype: menutype || '',
          locale,
        },
      }
    );
  } catch (e) {
    consoleLog(e);
  }
  return headerMenuResponse;
};

export const fetchFooterDetailData = async (locale, menutype) => {
  let footerResponse = [];
  try {
    footerResponse = await new IsoBackendHttpService().get(
      `${API_PREFIX}/menu`,
      !isCache,
      {
        params: {
          format: 'json',
          menutype: menutype || '',
          locale,
        },
      }
    );
  } catch (e) {
    consoleLog(e);
  }
  return footerResponse;
};
// export const fetchCategoryCards = async ids => {
//   let categoryCardsResponse = [];
//   try {
//     categoryCardsResponse = await fetch(config.YOGA_MEDITATION_SAD_CAT, {
//       auth: {
//         type: 'bearer',
//         bearer: [
//           {
//             key: 'token',
//             value: 'iBzg4y0TTFx1pgFFPZCkYPcyTHS32m',
//             type: 'string',
//           },
//         ],
//       },
//       method: 'GET',
//       body: [ids],
//     });
//   } catch (err) {
//     consoleLog(err);
//   }
//   return categoryCardsResponse;
// };
// export function fetchCategoryCards() {
//   // console.log('data1', data);
//   const headers = { Authorization: 'iBzg4y0TTFx1pgFFPZCkYPcyTHS32m' };
//   return axios
//     .post(
//       `${config.YOGA_MEDITATION_SAD_CAT}`,
//       { event_ids: [1166, 1169] },
//       { headers }
//     )
//     .then(res => {
//       // console.log('Register for gifting flow program response', res);
//       return res.data;
//     });
// }

export const fetchFooterData = async (locale, menutype) => {
  // console.log('locale', locale);
  let footerResponse = [];
  try {
    footerResponse = await new IsoBackendHttpService().get(
      `${API_PREFIX}/menu`,
      !isCache,
      {
        params: {
          format: 'json',
          menutype: menutype || 'isofooter',
          locale,
        },
      }
    );
  } catch (e) {
    consoleLog(e);
  }
  return footerResponse;
};

export const fetchMainPageData = async locale => {
  return await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    isCache,
    {
      params: {
        format: 'json',
        locale,
      },
    }
  );
};

export const fetchHomePage = async region => {
  return await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    isCache,
    {
      params: {
        format: 'json',
        sitesection: 'iso-home',
        slug: '/',
        slug: 'home',
        region,
      },
    }
  );
};

export const fetchPopUpData = async (url, type) => {
  const fetchUrl = `${
    config.FACADE_BASE_PATH
  }/content/poppup/iso?format=json&url=${url?.split('?')[0]}&type=${
    type || ''
  }`;
  let pageData = await fetch(fetchUrl);
  if (pageData.status === 500) {
    return { status: 'failure' };
  } else {
    pageData = await pageData.json();
  }
  return pageData;
};

export const fetchPopUpJson = async (url, type) => {
  const fetchUrl = `${config.MSR_VIDEO_BUCKET_URL}/popup/iso.json`;
  let pageData = await fetch(fetchUrl);
  if (pageData.status === 500) {
    return { status: 'failure' };
  } else {
    pageData = await pageData.json();
  }
  return pageData;
};

export const fetchPopUpBlacklistedUrls = async (url, type) => {
  const fetchUrl = `${config.MSR_VIDEO_BUCKET_URL}/popup/blacklisted_url.json`;
  let pageData = await fetch(fetchUrl);
  if (pageData.status === 500) {
    return { status: 'failure' };
  } else {
    pageData = await pageData.json();
  }
  return pageData;
};

export const fetchIpDetectWidget = async (url, country) => {
  const fetchUrl = `${
    config.FACADE_BASE_PATH
  }/content/ipdetectwidget/iso?format=json&url=${url?.split('?')[0]}&country=${
    country || ''
  }`;
  let pageData = await fetch(fetchUrl);
  if (pageData.status === 500) {
    return { status: 'failure' };
  } else {
    pageData = await pageData.json();
  }
  // let pageData = { "popup": [{ "title": "Inner Engineering", "bannerImage": { "alt": null, "url": "https://static.sadhguru.org/d/46272/1695488451-ieo-widget.jpg", "title": null }, "backgroundColor": { "hex": "#000000" }, "buttonCtaText": "Register Now", "buttonTextColor": { "hex": "#FFFFFF" }, "buttonCtaLink": "https://isha.sadhguru.org/in/en/inner-engineering?utm_campaign=ie-23-24&utm_medium=website&utm_source=iso_widget", "buttonBackgroundColor": { "hex": "#CF4520" }, "mobileCtaText": "<div class=\"popup-widget\" bis_skin_checked=\"1\"><span class=\"popup-widget-bold\">In 7 Steps<br />Transform Your Life<br />With Sadhguru</span></div>" }], "country": "IN", "region": country || "UK", "address": { "address": "India\nIsha Yoga Centre,\nVelliangiri Foothills,\nIshana Vihar Post,\nCoimbatore, Tamil Nadu - 641114", "phone": "+91 8300083111,\n04224283111,\n04223583111", "email": "info@ishafoundation.org" } };
  // if (country === 'us') {
  //   pageData = { "uspopup": "https://innerengineering.sadhguru.org/lib/js/exit-popup.js", "country": "US", "region": "US", "address": { "address": "USA\nIsha Institute of Inner\nSciences (USA)", "phone": "\n", "email": "support.ishafoundation.org" } }
  // }
  return pageData;
};

export const addSubscribeData = async ({
  uid = 0,
  name,
  email,
  newsletters,
  language,
  region,
  country,
  city,
  source = `${config.cdnPath}/subscribe/page`,
  type,
}) => {
  let data = await fetch(`${config.FACADE_BASE_PATH}/content/subscribe/add`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid,
      name,
      email,
      newsletters: {
        [newsletters]: newsletters,
      },
      region,
      language,
      country,
      city,
      source,
      type,
    }),
  });
  data = await data.json();
  return data;
};

export const addHomeSubscribeData = async ({
  uid = 0,
  name,
  email,
  newsletters,
  language,
  region,
  country,
  city,
  source = `${config.cdnPath}/subscribe/page`,
  type,
}) => {
  let data = await fetch(
    `${config.FACADE_BASE_PATH}/content/subscription/add`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid,
        name,
        email,
        newsletters: {
          [newsletters]: newsletters,
        },
        region,
        language,
        country,
        city,
        source,
        type,
      }),
    }
  );
  data = await data.json();
  return data;
};

export const addSubscribtionPopupData = async ({
  name,
  email,
  country,
  newsletters,
  language,
  source = `${config.cdnPath}/IDY`,
}) => {
  let data = await fetch(
    `${config.FACADE_BASE_PATH}/content/subscription/add`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        newsletters: {
          IDY2023Registration: 'IDY2023Registration',
        },
        language,
        country,
        source,
      }),
    }
  );
  data = await data.json();
  return data;
};

export const getMYYPageLandingPaths = async (level, url, notMatchText) => {
  const data = await getLatestBuild(process.env.env_var_build_id);
  const urlArr = filterBuildUrls(
    'maha_yoga_yagna',
    level,
    url,
    notMatchText,
    data
  );
  return urlArr ? urlArr : [];
};

export const fetchMYYData = async (region, lang) => {
  return await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: `maha-yoga-yagna`,
        region,
        lang,
      },
    }
  );
};

export const getCurrentRegion = async country => {
  if (country) {
    return {
      country: country,
    };
  }
  return await new IsoBackendHttpService().get(
    `/content/get-location/`,
    !isCache,
    {}
  );
};

export const getCurrentIpRegion = async () => {
  let region = '';
  const regData = await new IsoBackendHttpService().get(
    `/content/get-location/`,
    !isCache,
    {}
  );
  if (regData?.country) {
    const ipRegion = CountriesList.filter(item => {
      return item.value.toLowerCase() === regData.country.toLowerCase();
    });
    region = ipRegion?.length && ipRegion[0].reg;
  }
  // console.log('regData?.countr-------------------------', regData, region);
  return region;
};

export const getGoogleGeoLocation = async () => {
  return await axios.post(
    'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBFonpuPvDmywC__bOSFnowvB20hSyrhA0'
  );
};

export const pushLeadEnquiry = async data => {
  try {
    const response = await axios.post(`${config.PUSH_LEADS}/rl_leads`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('Success:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.response || error.message);
  }
};
