/* Built In Imports */
import { useEffect } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';
import SectionTitle from '../components/Text/SectionTitle';

/* Services */

/**
 *
 */
const LeadershipPrograms = ({ sectionContent }) => {
  // const [activeTab, setActiveTab] = useState({});
  // const [activeIndex, setActiveIndex] = useState(1);
  // const isSmallScreen = useMediaQuery({ maxWidth: 834 });
  let panels = [];

  useEffect(() => {
    panels = document.querySelectorAll('.cardSlide');
    panels.forEach((panel, key) => {
      if (key === 0) {
        panel.classList.add('active');
      }
      panel.addEventListener('click', () => {
        removeActiveClasses();
        panel.classList.add('active');
      });
    });

    const removeActiveClasses = () => {
      panels.forEach(panel => {
        panel.classList.remove('active');
      });
    };
  }, []);

  function changePanel(ind) {
    panels.forEach((panel, key) => {
      panel.classList.remove('active');
      if (ind === key) {
        panel.classList.add('active');
      }
    });
  }

  //   const NextArrow = (props) => {
  //     const { onClick, currentSlide, slideCount, activeTab } = props;
  //     // setActiveTab(props);
  //     // console.log('props', props);
  //     // setActiveIndex(currentSlide * 3);
  //     return (
  //       <>
  //         {(slideCount - currentSlide) > 3 && (
  //           <Box
  //             pos="absolute"
  //             textAlign={'center'}
  //             top="45%"
  //             display={{ base: 'none', md: 'flex' }}
  //             zIndex="10"
  //             cursor="pointer"
  //             bgColor="#F27123"
  //             _hover={{ background: '#BA5010' }}
  //             border="0"
  //             borderRadius="60px"
  //             w={{ md: '54px', base: '34.4px' }}
  //             h={{ md: '54px', base: '34.4px' }}
  //             bgPos="center"
  //             bgRepeat="no-repeat"
  //             color="transparent"
  //             alignItems="center"
  //             justifyContent='center'
  //             //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
  //             right={{ md: '100px', base: '54px', lg: '10px' }}
  //             bgSize="13px"
  //             onClick={onClick}
  //             boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
  //           >
  //             <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z" fill="white" />
  //             </svg>
  //           </Box>
  //         )}
  //       </>
  //     );
  // };

  //   const PrevArrow = props => {
  //     const { onClick, activeTab, currentSlide } = props;
  //     // setActiveTab(props);
  //     // setActiveIndex((currentSlide * 3) + 1);
  //     // console.log('onClick', onClick);
  //     return (
  //       <>
  //         {
  //           currentSlide !== 0 && (
  //             <Box
  //               pos="absolute"
  //               top="45%"
  //               zIndex="10"
  //               display={{ base: 'none', md: 'flex' }}
  //               cursor="pointer"
  //               bgSize="13px"
  //               bgColor="#F27123"
  //               _hover={{ background: '#BA5010' }}
  //               border="0"
  //               borderRadius="60px"
  //               w={{ md: '54px', base: '34.4px' }}
  //               h={{ md: '54px', base: '34.4px' }}
  //               bgPos="center"
  //               bgRepeat="no-repeat"
  //               color="transparent"
  //               alignItems="center"
  //               justifyContent='center'
  //               //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
  //               left={{ base: '3px' }}
  //               onClick={onClick}
  //               boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
  //             >
  //               <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z" fill="white" />
  //               </svg>
  //             </Box>
  //           )
  //         }
  //       </>
  //     );
  //   };

  // const settings = {
  //   // arrows: false,
  //   infinite: false,
  //   autoplay: false,
  //   lazyLoad: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   dots: false,
  //   variableWidth: true,
  //   swipeToSlide: true,
  //   beforeChange: (current, next) => {
  //     // setActiveIndex(next);
  //     // console.log('beforeChange', current, next);
  //     // if (next === 1) {
  //     //   setActiveIndex(2);
  //     // }
  //   },
  //   afterChange: (current) => {
  //     // console.log('afterChange', current);
  //     // setActiveIndex(Math.floor(2 * nextSlide));
  //   },
  //   onEdge: (current) => {
  //     // console.log('onEdge', current);
  //     // setActiveIndex(Math.floor(2 * nextSlide));
  //   },
  //   onReInit: (current) => {
  //     // console.log('onReInit', current);
  //     // setActiveIndex(Math.floor(2 * nextSlide));
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 1170,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 834,
  //       settings: {
  //         slidesToShow: 1.1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1.1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //   ],
  // };

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      mx="auto"
      mb={{ base: '30px', xl: '60px' }}
      width="100%"
      px={{ base: '16px', sm: '20px', md: '0px' }}
    >
      <Box
        pb={{ base: '30px', lg: '30px' }}
        pt={{ base: '40px', md: '40px', xl: '60px' }}
      >
        <SectionTitle titleObj={sectionContent[0]} />
      </Box>

      <Box display={{ base: 'none', lg: 'flex' }} gridGap="10px">
        {sectionContent
          ?.map((section, i) => {
            return (
              <Box
                onClick={changePanel(i)}
                key={i}
                className="cardSlide"
                cursor="pointer"
                color="#fff"
                bgImg={`url('${sectionContent[i]?.desktopImage?.url}')`}
                bgPos="30% top"
                w={{ base: '320px', lg: '286px' }}
                h={{ base: '411px', lg: '620px' }}
                position="relative"
              >
                {section?.tag && (
                  <Box
                    bgColor="#F27123"
                    px={{ base: '12px', lg: '23px' }}
                    py={{ base: '8px', lg: '15px' }}
                    position="absolute"
                    left="-12px"
                    top={{ base: '10px', lg: '34px' }}
                    zIndex="1"
                    _after={{
                      content: '" "',
                      width: '10px',
                      height: '10px',
                      position: 'absolute',
                      left: '0',
                      borderTop: '12px solid #9C430E',
                      borderLeft: '12px solid transparent',
                      zIndex: '1',
                      bottom: '-12px',
                    }}
                  >
                    <Text
                      fontSize={{ base: '10px', lg: '16px' }}
                      fontFamily="FedraSansStd-book"
                      fontWeight="600"
                      textTransform="uppercase"
                    >
                      {section?.tag}
                    </Text>
                  </Box>
                )}
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 3.13%, rgb(0 0 0 / 0%) 45.31%, #000 100%)"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    p="15px"
                  >
                    <Box
                      pb={{ base: '67px', md: '20px', lg: '18px' }}
                      display="flex"
                      className="alignEnd"
                      justifyContent="flex-end"
                    >
                      <LazyLoadImageComponent
                        src={section?.logo?.url}
                        title={section?.title}
                        alt={section?.alt}
                      />
                    </Box>
                    <Box
                      fontSize={{ base: '14px', md: '18px' }}
                      fontFamily="FedraSansStd-book"
                      className="cardPtext"
                      pb="10px"
                    >
                      <StructuredTextParser
                        str={render(section?.description)}
                        color="#fff"
                      />
                    </Box>

                    <Box
                      borderTop="1px solid #fff"
                      width="100%"
                      height="1px"
                    ></Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDir="row"
                      justifyContent="center"
                      className="secDate"
                      mt="15px"
                    >
                      <Text
                        fontSize={{ base: '14px', md: '18px' }}
                        fontFamily="FedraSansStd-book"
                        className="cardPtext"
                      >
                        {' '}
                        {section?.date}
                      </Text>
                      {/* <NextLink href={section?.link?.[0]?.linkUrl} passHref legacyBehavior> */}
                      <Link
                        href={section?.link?.[0]?.linkUrl}
                        display="flex"
                        gridGap="8px"
                        alignItems="center"
                      >
                        <Text fontFamily="FedraSansStd-book">
                          {' '}
                          {section?.link?.[0]?.buttonText}
                        </Text>
                        <Box
                          width={{ base: '34px', md: '46px' }}
                          height={{ base: '34px', md: '46px' }}
                          borderRadius="100%"
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          bgColor="#F27123"
                          _hover={{ background: '#BA5010' }}
                        >
                          <svg
                            width="11"
                            height="10"
                            viewBox="0 0 11 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.368 9.26775L5.088 7.98775L7.04 6.00375H0V4.01975H7.12L5.072 1.93975L6.304 0.707749L10.576 5.01175L6.368 9.26775Z"
                              fill="white"
                            />
                          </svg>
                        </Box>
                      </Link>
                      {/* </NextLink> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
          .slice(1)}
        {/* <Box
          position="relative"
          maxWidth="1170px"
          _before={{
            display: true,
            content: "''",
            height: '99%',
            position: 'absolute',
            bottom: '0px',
            width: activeTab.activeTab % 1 == 0 ? '10%' : '0',
            top: '0px',
            bgColor: 'red',
            right: activeTab.activeTab % 1 == 0 ? '0px' : 'none',
            zIndex: 2,
            // background: isSmallScreen ? 'none' : (activeTab.activeTab % 1 == 0 ?
            //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)': 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}

        _after={{
            display: true,
            content: "''",
            height: '99%',
            position: 'absolute',
            bottom: '0px',
            width: activeTab.activeTab > 0 ? '10%' : '0',
            top: '0px',           
            left: activeTab.activeTab > 0 ? '0' : 'none',
            zIndex: 2,
            // background: isSmallScreen ? 'none' : (activeTab.activeTab  > 0 &&
            //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}
        >
          <Slider
            {...settings}
          >
            {sectionContent?.map((section, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  flexDir={{ base: "column", md: "row" }}
                  flexWrap={{ base: "wrap", lg: "nowrap" }}
                  mr="10px"
                  gridGap={{ base: "20px", md: "10px" }}
                  cursor="pointer"
                >
                  <Box
                    className={activeIndex === i ? 'active cardSlide' : 'cardSlide'}
                    transitionDuration="700ms"
                    transitionTimingFunction="ease-in-out"
                    // _hover={{
                    //   width: { base: '320px', md: '587px' }
                    // }}
                    onClick={()=> {setActiveIndex(i)}}
                    bg={`no-repeat url('${section?.desktopImage?.url}')`}
                    bgSize="cover"
                    color="#fff"
                    w={{ base: "320px", lg: (activeIndex === i) ? '587px':  "286px" }}
                    h={{ base: "411px", lg: "620px" }}
                    position="relative"
                    key={nanoid()}
                  >
                    {section?.tag && (
                      <Box
                        bgColor="#F27123"
                        px={{ base: "12px", lg: "23px" }}
                        py={{ base: "8px", lg: "15px" }}
                        position="absolute"
                        left="-12px"
                        top={{ base: "10px", lg: "34px" }}
                        zIndex="1"
                        _after={{
                          content: '" "',
                          width: "10px",
                          height: "10px",
                          position: "absolute",
                          left: "0",
                          borderTop: "12px solid #9C430E",
                          borderLeft: "12px solid transparent",
                          zIndex: "1",
                          bottom: '-12px'
                        }}
                      >
                        <Text
                          fontSize={{ base: "10px", lg: "16px" }}
                          fontFamily="FedraSansStd-book"
                          fontWeight="600"
                          textTransform="uppercase"
                        >
                          {section?.tag}
                        </Text>
                      </Box>
                    )}
                    <Box
                      position="absolute"
                      width="100%"
                      height="100%"
                      bg='linear-gradient(180deg, rgba(0, 0, 0, 0.00) 3.13%, rgb(0 0 0 / 0%) 45.31%, #000 100%)'
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="end"
                        height="100%"
                        p="15px"
                      >
                        <Box
                          pb={{ base: '67px', md: '20px', lg: '88px' }}
                          display="flex"
                          className='alignEnd'
                          justifyContent={{ base: "end", lg: "center" }}
                        >
                          <LazyLoadImageComponent
                            src={section?.logo?.url}
                            title={section?.title}
                            alt={section?.alt}
                          />
                        </Box>
                        <Box
                          fontSize={{ base: "14px", md: "18px" }}
                          fontFamily="FedraSansStd-book"
                          className="cardPtext"
                          pb="10px"
                        >
                          <StructuredTextParser
                            str={render(section?.description)}
                            color="#fff"
                          />
                        </Box>

                        <Box
                          borderTop="1px solid #fff"
                          width="100%"
                          height="1px"
                        ></Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDir="row"
                          justifyContent="center"
                          className="secDate"
                          mt="15px"
                        >
                          <Text
                            fontSize={{ base: "14px", md: "18px" }}
                            fontFamily="FedraSansStd-book"
                            className="cardPtext"
                          > {section?.date}
                          </Text>
                          <NextLink href={section?.link?.[0]?.linkUrl} passHref legacyBehavior>
                            <Link display="flex" gridGap="8px" alignItems="center">
                              <Text fontFamily="FedraSansStd-book"> {section?.link?.[0]?.buttonText}</Text>
                              <Box width={{ base: "34px", md: "46px" }} height={{ base: "34px", md: "46px" }} borderRadius="100%" alignItems="center" display="flex" justifyContent="center" bgColor="#F27123" _hover={{ background: '#BA5010' }}>
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.368 9.26775L5.088 7.98775L7.04 6.00375H0V4.01975H7.12L5.072 1.93975L6.304 0.707749L10.576 5.01175L6.368 9.26775Z" fill="white" />
                                </svg>
                              </Box>
                            </Link>
                          </NextLink>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            }).slice(1)}
          </Slider>
        </Box> */}
      </Box>

      {/* Mobile */}

      <Box
        flexDir={{ base: 'column', md: 'row' }}
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        gridGap={{ base: '20px', md: '44px', lg: '10px' }}
        display={{ base: 'flex', lg: 'none' }}
        alignItems="center"
      >
        {sectionContent &&
          sectionContent
            ?.map((section, i) => (
              <Box
                className="cardSlide"
                bg={`no-repeat url('${section?.desktopImage?.url}')`}
                bgSize="cover"
                color="#fff"
                width="100%"
                maxW={{ base: '320px', lg: '286px' }}
                h={{ base: '411px', lg: '620px' }}
                position="relative"
                key={`sliderSec${i}`}
              >
                {section?.tag && (
                  <Box
                    bgColor="#F27123"
                    px={{ base: '12px', lg: '23px' }}
                    py={{ base: '8px', lg: '15px' }}
                    position="absolute"
                    left="-12px"
                    top={{ base: '10px', lg: '34px' }}
                    zIndex="1"
                    _after={{
                      content: '" "',
                      width: '10px',
                      height: '10px',
                      position: 'absolute',
                      left: '0',
                      borderTop: '12px solid #9C430E',
                      borderLeft: '12px solid transparent',
                      zIndex: '1',
                      bottom: '-12px',
                    }}
                  >
                    <Text
                      fontSize={{ base: '10px', lg: '16px' }}
                      fontFamily="FedraSansStd-book"
                      fontWeight="600"
                      textTransform="uppercase"
                    >
                      {section?.tag}
                    </Text>
                  </Box>
                )}

                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 3.13%, rgba(0, 0, 0, 0.60) 45.31%, #000 100%)"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    p="15px"
                    alignItems="flex-end"
                  >
                    <Box
                      pb={{ base: '37px', md: '20px', lg: '88px' }}
                      w={{ base: '134px', lg: '238px' }}
                      display="flex"
                      justifyContent={{ base: 'flex-end', lg: 'center' }}
                    >
                      <LazyLoadImageComponent
                        src={section?.logo?.url}
                        title={section?.title || 'slide img'}
                        alt={section?.alt || 'slide img'}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Box
                      fontSize={{ base: '14px', md: '18px' }}
                      fontFamily="FedraSansStd-book"
                      className="cardPtext"
                    >
                      <StructuredTextParser
                        str={render(section?.description)}
                        fontSize="14px"
                        color="#fff"
                        mt="0px"
                      />
                    </Box>

                    <Box
                      borderTop="1px solid #fff"
                      my={{ base: '0px', md: '24px' }}
                      width="100%"
                      height="1px"
                    ></Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      w="full"
                      flexDir="row"
                      justifyContent="space-between"
                      pt="15px"
                      className="secDate"
                    >
                      <Text
                        fontSize={{ base: '14px', md: '18px' }}
                        fontFamily="FedraSansStd-book"
                        className="cardPtext"
                        fontWeight="semibold"
                      >
                        {' '}
                        {section?.date}{' '}
                      </Text>
                      {/* <NextLink href={section?.link?.[0]?.linkUrl} passHref legacyBehavior> */}
                      <Link
                        href={section?.link?.[0]?.linkUrl}
                        display="flex"
                        gridGap="8px"
                        alignItems="center"
                      >
                        <Text
                          fontFamily="FedraSansStd-book"
                          fontSize={{ base: '14px', md: '16px' }}
                          fontWeight="semibold"
                        >
                          {' '}
                          {section?.link?.[0]?.buttonText}
                        </Text>
                        <Box
                          width={{ base: '34px', md: '46px' }}
                          height={{ base: '34px', md: '46px' }}
                          borderRadius="100%"
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          bgColor="#F27123"
                          _hover={{ background: '#BA5010' }}
                          transition="all 0.2s linear"
                        >
                          <svg
                            width="11"
                            height="10"
                            viewBox="0 0 11 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.368 9.26775L5.088 7.98775L7.04 6.00375H0V4.01975H7.12L5.072 1.93975L6.304 0.707749L10.576 5.01175L6.368 9.26775Z"
                              fill="white"
                            />
                          </svg>
                        </Box>
                      </Link>
                      {/* </NextLink> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
            .slice(1)}
      </Box>

      <style jsx global>
        {`
          .cardSlide {
            transition: all 200ms linear;
            -webkit-trasition: all 700ms linear;
          }
          .cardSlide .cardPtext {
            display: none;
          }
          .cardSlide.active {
            width: 578px;
            transition: all 200ms linear;
          }
          .cardSlide.active .cardPtext {
            display: block;
          }
          .cardSlide.active .secDate {
            justify-content: space-between;
          }
          @media (max-width: 992px) {
            .cardSlide .cardPtext {
              display: block;
            }
          }
        `}
      </style>

      {/* <style jsx global>
        {
          `
        .cardSlide .cardPtext {
          display:none
        }
        
        .cardSlide.active .cardPtext{
          display:block;
        } 
        .cardSlide.active .secDate {
          justify-content: space-between
        }
        .cardSlide.active .alignEnd {
          justify-content: end
        }
        
        @media (max-width:767px) {
          .cardSlide.active {
            width:320px;
            transition: all 200ms linear;
          }
          .cardSlide .cardPtext, .cardSlide.active .cardPtext {
            display:block
          }
          .cardSlide .secDate {
            justify-content: space-between
          }
        
        }

        @media (min-width:768px) and (max-width:1023px) {
          .cardSlide.active {
            width:320px;
            transition: all 200ms linear;
          }
          .cardSlide .cardPtext, .cardSlide.active .cardPtext {
            display:block
          }
          .cardSlide .secDate {
            justify-content: space-between
          }        
        }
        `
        }

      </style> */}
    </Box>
  );
};

export default LeadershipPrograms;
