/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders Top Banner Simple Component
 *
 * @param {object} sectionContent - data from api
 * @returns Top Banner Simple Component
 */

const TopBannerSimple = ({ sectionContent }) => {
  return (
    <Box
      textAlign={{ base: "left", sm: "center" }}
    >
      <Text
        fontSize={{ base: '64px', lg: '64px', xl: '90px' }}
        as="h1"
        py={{ base: "60px", md: '30px', xl: '60px' }}
        px={{ base: "16px", md: 0 }}
        m={0}
        textTransform="uppercase"
        lineHeight={{
          base: '65px',
          md: '60px',
          lg: '75px',
          xl: '85px',
        }}
        color="#fff"
        fontFamily="FedraSansCondensedMedium, sans-serif"
      >
        {sectionContent?.title}
      </Text>

    </Box>
  );
};

export default TopBannerSimple;
