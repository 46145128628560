import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import SectionTitle from '../components/Text/SectionTitle';
import LookBackGallery from '../components/Gallery/LookBackGallery';
import StructuredTextParser from '../components/Text/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

const LookBackSection = ({ sectionContent }) => {
  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      px={{ base: '16px', sm: 0 }}
      mx="auto"
      w="100%"
      py={{ base: '40px', md:'40px', xl: '60px' }}
    >
      <Flex flexDirection={{ base: 'column', lg: 'row' }}>
        <Box w={{ base: '100%', xl: '497px' }} px={{ base: '4px', md: '5px' }} mb="20px">
          <SectionTitle titleObj={sectionContent[0]} />
          <StructuredTextParser
            str={render(sectionContent[1]?.body)}
            mt="0px"
            p="30px 10px 0px 0px"
            maxW={{ base: '100%', md: 'none !important', xl: '412px' }}
            color="#fff"
            fontFeatureSettings="'case' on"
            fontFamily="FedraSansStd-book"
            fontSize={{ base: '16px', md: '18px' }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: '24px', md: '32px' }}
          />
        </Box>
        <Box
          maxW={{ lg: '550px', xl: 673 }}
          p={{ base: '0', md: '5px' }}
          pl={{ base: '4px', md: '0' }}
        >
          <LookBackGallery gallery={sectionContent[2]?.images} />
        </Box>
      </Flex>
    </Box>
  );
};

export default LookBackSection;
