import { Box, Center, Flex, Image, ListItem, Modal, ModalBody, ModalContent, ModalOverlay, UnorderedList, useDisclosure } from '@chakra-ui/react';
import Slider from 'react-slick';
// import SliderDots from '../micro/SliderDots';
import { CloseIcon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const NextArrow = (props) => {
    const { onClick, currentSlide, slideCount, activeTab, ...rest } = props;
    // console.log((slideCount - 1) / currentSlide, "currentslide")
    return (
        <>
            {/* {currentSlide % 1 == 0 && ( */}
            <Flex
                pos="absolute"
                textAlign={'center'}
                top="45%"
                // display={{ base: 'none', md: 'flex' }}
                zIndex="10"
                cursor="pointer"
                display={{ base: "none !important", md: currentSlide % 1 == 0 && currentSlide / (slideCount - 1) !== 1 ? "flex !important" : "none !important" }}
                bgColor="#F27123"
                _hover={{ background: '#BA5010' }}
                border="0"
                borderRadius="60px"
                w={{ md: '54px', base: '34.4px' }}
                h={{ md: '54px', base: '34.4px' }}
                bgPos="center"
                bgRepeat="no-repeat"
                color="transparent"
                alignItems="center"
                justifyContent='center'
                //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
                right={{ md: '100px', base: '54px', lg: '10px' }}
                bgSize="13px"
                onClick={onClick}
                boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
                {...rest}
                _before={{
                    content: '""',
                    // display: "none"
                }}
                _after={{
                    content: '""',
                    // display: "none"
                }}
            >
                <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z" fill="white" />
                </svg>
            </Flex>
            {/* )} */}
        </>
    );
};

const PrevArrow = props => {
    const { onClick, activeTab, currentSlide, ...rest } = props;
    return (
        <>
            {/* {
                currentSlide !== 0 && ( */}
            <Flex
                pos="absolute"
                top="45%"
                zIndex="10"
                display={{ base: 'none !important', md: currentSlide !== 0 ? "flex !important" : "none !important" }}
                cursor="pointer"
                bgSize="13px"
                bgColor="#F27123"
                _hover={{ background: '#BA5010' }}
                border="0"
                borderRadius="60px"
                w={{ md: '54px', base: '34.4px' }}
                h={{ md: '54px', base: '34.4px' }}
                bgPos="center"
                bgRepeat="no-repeat"
                color="transparent"
                alignItems="center"
                justifyContent='center'
                //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
                left={{ base: '3px' }}
                onClick={onClick}
                boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
                {...rest}
                _before={{
                    content: '""',
                    // display: "none"
                }}
                _after={{
                    content: '""',
                    // display: "none"
                }}
            >
                <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z" fill="white" />
                </svg>
            </Flex>
            {/* )
            } */}
        </>
    );
};

export default function GlimpsesSlider({ images }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [activeTab, setActiveTab] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const isSmallScreen = useMediaQuery({ maxWidth: 834 });
    const mSettings = {
        initialSlide: activeSlide ? activeSlide : 0,
        dots: false,
        accessibility: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow background='#F27123' fontSize={{ base: "35px", md: "60px" }} height={{ base: "35px", md: "60px" }} width={{ base: "35px", md: "60px" }} rounded="full" right="5px" />,
        prevArrow: <PrevArrow background='#F27123' fontSize={{ base: "35px", md: "60px" }} height={{ base: "35px", md: "60px" }} width={{ base: "35px", md: "60px" }} rounded="full" />,
    }
    const sliderDotSettings = {
        base: 1,
        md: 2
    };

    const settings = {
        dots: true,
        // arrows: false,
        infinite: false,
        autoplay: false,
        lazyLoad: true,
        slidesToShow: 2.2,
        slidesToScroll: sliderDotSettings.md,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerPadding: 0,
        appendDots: dots => (
            <Flex height="40px">
                <Flex
                    marginRight="auto"
                    marginLeft="auto"
                    bgColor="#0E0E6B"
                    width="auto"
                    height="14px"
                    borderRadius="9px"
                    justifyContent="space-between"
                    className="Glimpses appendDots"
                    marginTop="3em"
                >
                    <UnorderedList
                        m="0"
                        display="flex"
                    >
                        {/* {dots}{' '} */}
                        {dots.map((dot, index) => {
                            const button = dot.props.children;
                            return (
                                <ListItem
                                    onClick={e => {
                                        // console.log('button.props', dot, activeTab, Math.ceil(activeTab) % sliderDotSettings.md);
                                        button.props.onClick(e);
                                    }}
                                    key={index}
                                    width="19px !important"
                                    height="13px !important"
                                    borderRadius="9px !important"
                                    background={(Math.ceil((activeTab) / (sliderDotSettings[isSmallScreen ? 'base' : 'md'])) === parseInt(dot.key)) ? "#F27123" : "#0E0E6B"}
                                    margin="0 !important"
                                />
                            );
                        })}
                    </UnorderedList>
                </Flex>
            </Flex>
        ),
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: sliderDotSettings.md,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 834,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: sliderDotSettings.md,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: sliderDotSettings.base,
                    initialSlide: 0,
                },
            },
        ],
    };

    return (
        <Box
            position="relative"
            maxWidth={{ md: '100%', base: '100%' }}
            _before={{
                display: true,
                content: "''",
                height: '99%',
                position: 'absolute',
                bottom: '0px',
                width: { base: "0", md: '5%' },
                top: '0px',
                right: activeTab % 1 == 0 ? '0px' : 'none',
                zIndex: 2,
                // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
                //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)' : 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
            }}

            _after={{
                display: true,
                content: "''",
                height: '99%',
                position: 'absolute',
                bottom: '0px',
                width: { base: "0", md: '5%' },
                top: '0px',
                left: activeTab > 0 ? '0' : 'none',
                zIndex: 2,
                // background: isSmallScreen ? 'none' : (activeTab > 0 &&
                //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
            }}
        >
            <Slider
                {...settings}
                beforeChange={(old, newChange) => {
                    setActiveTab(newChange);
                }}
            >
                {images?.map((a, i) => {
                    return (
                        <Box
                            key={i}
                            onClick={() => setActiveSlide(i)}
                        >
                            <Image padding={3}
                                src={a.url}
                                alt={a.alt}
                                width="100%"
                                id={i}
                                onClick={onOpen}
                                cursor="pointer"
                            />
                            <Modal isOpen={isOpen} onClose={onClose} size='full'
                                h={{ base: '100dvh', md: '90vh' }} my="auto"
                                maxW="1265px" maxH="600px"
                                isCentered>
                                <ModalOverlay />
                                <ModalContent p={0}
                                    width={{ base: '100%', lg: '90%' }}
                                    m="0"
                                    bg={{ base: '#000000' }}
                                    h={{ base: "90dvh", md: "90%" }}
                                    py='5dvh'
                                    // maxH="80vh !important"
                                    my="auto"
                                    background="transparent"
                                    maxW="1265px" maxH="600px"
                                >
                                    <ModalBody p={{ base: "0" }} background="transparent" maxH="100%">

                                        <Center my="auto" pos="relative" bg={{ md: "transparent", base: "#000" }} maxH="100%" h="100%">
                                            <Box w="90%" h="auto" my="auto" className="sliderContainer" pos="relative" zIndex={2}>
                                                <Box
                                                    bg="#FFFFFF"
                                                    onClick={onClose}
                                                    w="48px"
                                                    h="48px"
                                                    rounded="full"
                                                    _hover={{ bg: '#ffffff' }}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    pos="absolute"
                                                    top={{ base: "0", md: "0px", lg: "-24px", }}
                                                    right='-24px'
                                                    fontFamily="'FedraSansStd-Book', sans-serif"
                                                    zIndex="110"
                                                    cursor="pointer"
                                                    color="black"
                                                >
                                                    <CloseIcon />
                                                </Box>
                                                <Slider {...mSettings}>
                                                    {images?.map((card) => {
                                                        return (
                                                            <Image
                                                                alt={card?.alt || ''}
                                                                src={card?.url}
                                                                key={nanoid()}
                                                                objectPosition="top"
                                                                maxW="100%"
                                                                w="100%"
                                                                height="100%"
                                                                objectFit="cover"
                                                                maxH="100%"
                                                            />
                                                        );
                                                    })}
                                                </Slider>
                                            </Box>
                                        </Center>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </Box>
                    );
                })
                }

            </Slider>
        </Box>
    );
}
