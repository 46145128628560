/* Built In Imports */
/* External Imports */
import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  Text
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';

/* Services */

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const Offers = ({ sectionContent }) => {
  return (
    <Box
      mx="auto"
      w="100%"
      maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
      py={{ base: "30px", md: "40px", xl: "60px" }}
    >
      <Box w="100%">
        <Center>
          <Text
            fontFamily="FedraSansStd-medium"
            color="#fff"
            fontSize={{ md: '26px', base: '22px' }}
            lineHeight="32.5px"
            textTransform="uppercase"
            as="h2"
            pb="20px"
          >
            {sectionContent[0]?.text}
          </Text>
        </Center>

        <Flex
          backgroundColor="#17175F"
          flexDirection={{ base: 'column', md: 'row' }}
          flexGrow={1}
          alignItems="stretch"
          margin="auto"
          w={{ base: '100%', md: `${100 / (4 - sectionContent[1]?.cards.length)}%` }}
        >
          {sectionContent && sectionContent[1]?.cards?.map((item, i) => (
            <Box
              key={`jUwj3${i}`}
              flexGrow={1}
              flexBasis={1}
              width="90%"
              mx="auto"
              my={{ md: '36px', lg: '34px' }}
              px={{ base: '0px', md: '18px', lg: '35px' }}
              borderLeft={{
                md: i === 0 ? 'none' : '1px solid rgba(255, 255, 255, 0.30)',
              }}
              borderBottom={{
                base:
                  i === 3 ? 'none' : '1px solid rgba(255, 255, 255, 0.30)',
                md: 'none',
              }}
            >
              <Text
                fontFamily="FedraSansStd-medium"
                color="#FFFFFF"
                fontSize={{ md: '20px' }}
                pt={{ base: '20px', md: '0px' }}
              >
                {item?.title}:
              </Text>
              <Box
                color="#D4D4FA"
                fontSize={{ md: '16px' }}
                p={{ base: '15px 0px', md: '15px 0px 0 0' }}
              >
                <StructuredTextParser
                  str={render(item?.description)}
                  mt="0"
                  color="#D4D4FA"
                  fontSize="16px"
                  maxW="none"
                  p="0"
                />
                <LazyLoadImageComponent src={item?.thumbnail?.url} pt="10px" />
              </Box>
            </Box>
          ))}
        </Flex>
        <Center>
          <Text
            margin="30px 0px 0px 0px"
            fontStyle="italic"
            color="#D8A24C"
            width={{ base: '85%', md: '100%' }}
            textAlign="center"
          >
            {sectionContent[2]?.text}
          </Text>
        </Center>
        {
          sectionContent[3]?.linkUrl && (
            <Center>
              <Button
                color="#FFFFFF"
                background="#F27123"
                size="md"
                fontFamily="FedraSansStd-medium"
                fontWeight="normal"
                border="none"
                variant="unstyled"
                textTransform="uppercase"
                borderRadius="0px"
                padding="20px 25px 42px 25px"
                margin="35px 0px 0 0"
                _hover={{ background: '#BA5010' }}
              >
                <Link target="_blank" outline="0" href={sectionContent[3]?.linkUrl} _hover={{ textDecoration: 'none' }}>
                  {sectionContent[3]?.buttonText}
                </Link>
              </Button>
            </Center>
          )
        }

      </Box>
    </Box>
  );
};

export default Offers;
