/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SectionTitle from '@ila/components/Text/SectionTitle';
import WhyAttendCards from '../components/Card/WhyAttendCards';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const InsightWhyAttend = ({ sectionContent }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });

  return (
    <Box padding={0} pb={{ base: "30px", md: '40px', lg: '60px' }}>
      <Box
        maxW={{
          base: '100%',
          sm: '540px',
          md: '720px',
          lg: '960px',
          xl: '1170px',
          '2xl': '1330px',
        }}
        mx="auto"
        width="100%"
        position="relative"
      >
        {isSmallScreen ? (
          <svg
            width="167"
            height="167"
            viewBox="0 0 104 126"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'absolute',
              top: '15%',
              left: '1%',
            }}
          >
            <path d="M31.5 0L103.813 125.25H-40.8131L31.5 0Z" fill="#0A0A69" />
          </svg>
        ) : (
          <svg width="440" height="381" viewBox="0 0 440 381" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            position: 'absolute',
            top: '-15%',
            left: '-13%',
          }}>
            <path d="M220 0L439.97 381H0.029541L220 0Z" fill="#0A0A69" />
          </svg>


        )}
        <Flex
          flexDirection="column"
          position="relative"
          mb="20px"
          pt="60px"
          pl="16px"
        >
          <SectionTitle titleObj={sectionContent[0]} />
        </Flex>
      </Box>
      <WhyAttendCards cards={sectionContent[1].cards} />
    </Box>
  );
};

export default InsightWhyAttend;
