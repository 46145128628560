import { Box, Flex, Image, Text } from '@chakra-ui/react';
// import config from '@config';
import StructuredTextParser from '@landing_pages/ila/components/Text/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';
import SliderDots from '../Icons/SliderDots';

export const NextArrow = props => {
  const { onClick, activeTab, shares } = props;
  return (
    <Box
      pos="absolute"
      textAlign={'center'}
      top="45%"
      zIndex="10"
      cursor="pointer"
      bgColor="#F27123"
      _hover={{ background: '#BA5010' }}
      transition="all 0.2s linear"
      border="0"
      borderRadius="100%"
      display={
        props.display || activeTab === shares?.length - 1 ? 'none' : 'flex'
      }
      w={{ md: '54px', base: '34.4px' }}
      h={{ md: '54px', base: '34.4px' }}
      bgPos="center"
      bgRepeat="no-repeat"
      color="transparent"
      onClick={e => {
        onClick(e);
      }}
      alignItems="center"
      justifyContent='center'
      // bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
      right={{ base: '3px' }}
      bgSize="13px"
      boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
    >
      <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z" fill="white" />
      </svg>

    </Box>
  );
};

export const PrevArrow = props => {
  const { onClick, activeTab } = props;
  return (
    <Box
      pos="absolute"
      top="45%"
      zIndex="10"
      cursor="pointer"
      bgSize="13px"
      bgColor="#F27123"
      _hover={{ background: '#BA5010' }}
      display={activeTab === 0 ? 'none' : 'flex'}
      border="0"
      transition="all 0.2s linear"
      borderRadius="100%"
      w={{ md: '54px', base: '34.4px' }}
      h={{ md: '54px', base: '34.4px' }}
      bgPos="center"
      bgRepeat="no-repeat"
      color="transparent"
      alignItems="center"
      justifyContent='center'
      // bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
      left={{ base: '3px' }}
      onClick={e => {
        onClick(e);
      }}
      boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
    >
      <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z" fill="white" />
      </svg>

    </Box>
  );
};

export default function BigScreenPastShareSlider({
  activeTab,
  shares,
  setActiveTab,
}) {
  // const isSmallScreen = useMediaQuery({ maxWidth: 834 });
  const settings = {
    dots: true,
    slidesToShow: 1.4,
    slidesToScroll: 1,
    autoPlay: true,
    infinite: false,
    nextArrow: <NextArrow activeTab={activeTab} shares={shares} />,
    prevArrow: <PrevArrow activeTab={activeTab} shares={shares} />,
    appendDots: dots => (
      <Flex height="40px">
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          width="108px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
        >
          {dots.map(dot => {
            const button = dot.props.children;

            return (
              <button
                key={dot.key}
                style={{
                  color:
                    activeTab.toString() === dot.key ? '#F27123' : '#0E0E6B',
                  width: '19px',
                  height: '13px',
                  borderRadius: '9px',
                }}
              >
                <SliderDots
                  onClick={e => {
                    button.props.onClick(e);
                  }}
                  color={
                    activeTab.toString() === dot.key ? '#F27123' : '#0E0E6B'
                  }
                />
              </button>
            );
          })}
        </Flex>
      </Flex>
    ),
  };

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      mx="auto"
      width="100%"
      px={{ base: '16px', sm: '20px', md: '0px' }}
    >
      <Box
        position="relative"
        maxWidth="1330px"
        _before={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: '5%',
          top: '0px',
          right: activeTab % 1 == 0 ? '0px' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
          //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)': 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}

        _after={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: '5%',
          top: '0px',
          left: activeTab > 0 ? '0' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab  > 0 &&
          //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}
      >
        <Slider
          {...settings}
          style={{ color: 'red' }}
          beforeChange={(old, newChange) => {
            setActiveTab(Math.ceil(newChange));
          }}
        >
          {shares?.map(postData => {
            return (
              <div key={nanoid()}>
                <Box
                  display="flex"
                  flexDirection={{ md: 'row' }}
                  padding={{ base: '24px', md: '18px', xl: 0 }}
                  alignItems={{ base: 'end', md: 'flex-start' }}
                >
                  <Box width={{ base: 'auto' }}>
                    <Image
                      src={postData?.photo?.url}
                      width="228px"
                      alt={postData?.photo?.alt || postData.userName}
                    />
                  </Box>

                  <Box
                    maxWidth={{ base: '60%' }}
                    margin={{ base: '30px 0 20px 0', md: '5px 0 0 24px' }}
                  >
                    <span style={{ display: 'block' }}>
                      <Text
                        fontWeight="semibold"
                        paddingBottom={{ base: '10px' }}
                        color="#FFFFFF"
                        lineHeight={{ base: '17.34px', md: '27.14px' }}
                        fontSize={{ base: '20px', md: '28px' }}
                      >
                        {postData.userName}
                      </Text>
                    </span>
                    <span style={{ display: 'block' }}>
                      <StructuredTextParser
                        paddingBottom={{ base: '15px' }}
                        color="#D8A24C !important"
                        lineHeight={{ base: '14px', md: '24px' }}
                        fontSize={{ base: '12px', md: '18px', lg: '18px' }}
                        str={render(postData?.userDescription)}
                      />
                    </span>
                    <Box
                      as="span"
                      display={{ base: 'none', md: 'block' }}
                      mb={{ base: '20px', lg: '40px', xl: '40px' }}
                    >
                      <StructuredTextParser
                        mt="0px"
                        color="#D4D4FA"
                        lineHeight={{ base: '24px', md: '30px' }}
                        fontSize={{ base: '16px', lg: '18px' }}
                        str={render(postData.testimonialText)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display={{ base: 'block', md: 'none' }}>
                  <Text
                    color="#D4D4FA"
                    lineHeight={{ base: '24px', md: '30px' }}
                    fontSize={{ base: '16px', md: '18px' }}
                  >
                    {postData.sharing}
                  </Text>
                </Box>
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
}
