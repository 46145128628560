/* Built In Imports */
/* External Imports */
import axios from 'axios';

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls, getLatestBuild } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText) => {
  const data = config.BUILD_URLS?.VOLUNTEER?.enabled
    ? config.BUILD_URLS.VOLUNTEER
    : await getLatestBuild(
      process.env.NEXT_PUBLIC_BUILD_ID || process.env.env_var_others_build_id || process.env.env_var_build_id
    );
  let urlArr = filterBuildUrls('others_section', level, url, notMatchText, data);
  return urlArr ? urlArr : [];
};

export const fetchDetails = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  }
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'others',
        slug: slug ? `newsletter-subscription/${slug}` : `newsletter-subscription`,
        region,
        lang
      }
    }
  );
  return pageData;
};

export const addSubscription = async formValues => {
  console.log('formValues', formValues);

  const request = await axios({
    method: 'POST',
    url: `${config.FACADE_BASE_PATH}/content/subscription/add`,
    data: formValues
  });

  return request?.data;
};

export const addAllInOneSubscription = async formValues => {
  const request = await axios({
    method: 'POST',
    url: `${config.FACADE_BASE_PATH}/content/subscription/add`,
    data: formValues
  });

  return request?.data;
};