/* Built In Imports */
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Heading,
  chakra
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

// Constants
/* Services */

/**
 * Renders the Faq Linked Content component.
 *
 * @param {Array} faqSection - Main FAQ data.
 * @returns {ReactElement} FaqLinkedContent component.
 */
export default function FaqLinkedContent({
  faqSection
}) {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };
  // useEffect(() => {
  faqSection?.map(faq => {
    schema.mainEntity.push({
      '@type': 'Question',
      name: `${faq?.question}`,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `${faq?.answer}`,
      },
    })
  });
  // }, [faqSection]);
  // console.log(faqSection, "faqSection")
  return (
    <Box maxW={1330} mx="auto" width="100%">
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="1.1rem"
        lineHeight="1.61"
        width="100%"
        m="0 auto 3rem auto"
        bg="#FEF3E3"
        px={{ base: '20px', md: '60px' }}
      >
        <Flex
          maxW={1170}
          mx="auto"
          w="100%"
          py={{ base: '40px', md: '120px' }}
          alignItems="flex-start"
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={{ base: "30px", md: "40px" }}
        >
          <Center
            textAlign="center"
            mb={{ base: "1.5rem", md: "1rem" }}
            height={{ base: "auto", md: "100px" }}
            w={{ base: '100%', md: '404px', lg: '30%' }}
          >
            <Heading
              color="#D8A24C"
              fontFeatureSettings="'case' on"
              fontFamily="FedraSansCondensedMedium, sans-serif"
              fontSize={{ base: '28px', md: '32px' }}
              fontStyle="normal"
              fontWeight="500"
              textAlign="left"
              lineHeight="51px"
              as="h2"
            >
              Frequently Asked Questions
            </Heading>
          </Center>
          <Box w="100%" maxW={766}>
            {faqSection?.map(fs => {
              return (
                <FaqSection
                  key={nanoid(4)}
                  faqSection={fs}
                  region={''}
                  lang={''}
                />
              );
            })}
          </Box>
        </Flex>
      </Box>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Box>
  );
}

const FaqSection = ({ faqSection, region, lang }) => {
  return (
    <Accordion
      key={nanoid(4)}
      allowMultiple
      // defaultIndex={0}
      width={{ base: '100%' }}
      m={{ base: '0 auto', lg: '0', md: '0' }}
    >
      <AccordionItem borderColor="#050505">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              bgColor="#FEF3E3"
              borderRadius="3px"
              mb="2px"
              fontFamily="FedraSansStd-medium"
              fontSize="20px"
              // borderColor="#ffffff"
              padding={{ base: "15px 0", md: "39px 0px" }}
              color="#000"
              _hover={{ bgColor: '#FEF3E3', color: '#000' }}
              _focus={{ shadow: 'transparent' }}
              minHeight="55px"
              lineHeight="1.5"
              fontWeight="normal"
            >
              <chakra.div flex="1" textAlign="left" fontFamily="FedraSansStd-medium">
                {faqSection.title}
              </chakra.div>
              {isExpanded ? (
                <MinusIcon color="#F27123" fontSize='17px' />
              ) : (
                <AddIcon color="#F27123" fontSize='17px' />
              )}
            </AccordionButton>
            <AccordionPanel
              pb={2}
              px={0}
              pr={{ base: "0", md: 5 }}
              fontSize="1.1rem"
              fontFamily="FedraSansStd-book"
              lineHeight="1.61"
            >
              {/* <Box
            display="block"
            width="100%"
            lineHeight="1.61"
            fontSize={{ base: '14px', lg: '16px' }}
            color="#666"
          > */}
              {faqSection?.faqs?.length
                ? faqSection.faqs.map((faq, index) => {
                  return (
                    <FaqSectionChildren
                      faqs={faq}
                      region={region}
                      lang={lang}
                      key={index}
                    />
                  );
                })
                : null}
              {/* </Box> */}
            </AccordionPanel>
            <style global jsx>
              {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }

          .faq p {
            padding-bottom: 20px;
          }
        `}
            </style>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const FaqSectionChildren = ({ faqs, region, lang }) => {
  return (
    <AccordionItem lineHeight="1.61" border="none">
      <AccordionButton
        pt="0rem"
        pb="30px"
        fontFamily="FedraSansStd-medium"
        fontSize="1.1rem"
        borderTop="none"
        border="none"
        px={0}
        _hover={{ background: 'none' }}
        _focus={{ shadow: 'transparent' }}
      >
        <chakra.div flex="1" textAlign="left">
          {faqs.question}
        </chakra.div>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        pt="0"
        pb={0}
        px={0}
        fontSize="1.1rem"
        fontFamily="FedraSansStd-book"
        lineHeight="1.61"
      >
        <Box
          display="block"
          width="100%"
          lineHeight="1.61"
          fontSize={{ base: '14px', lg: '16px' }}
          color="#666"
        >
          <Box className="event-text eventIlaText" maxW="679.277px" color="#666 !important">
            <StructuredTextParser
              str={render(faqs.answer)}
              mt="0"
              mx="0"
              region={region}
              lang={lang}
              color="#666 !important"
            />
          </Box>
          <style>
            {
              `
            .eventIlaText p{
              color:#666 !important;
            }
            `
            }
          </style>
        </Box>
      </AccordionPanel>
      <style global jsx>
        {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }

          .faq p {
            padding-bottom: 20px;
          }
        `}
      </style>
    </AccordionItem>
  );
};
