import { Flex } from '@chakra-ui/react';
import React from 'react';
import { TbPlayerPlayFilled } from 'react-icons/tb';
const PlayIcon = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      w="auto"
      h="auto"
      rounded="full"
      p="4px"
      border="2px solid white"
      _hover={{
        p: '8px',
        bg: 'rgba(255, 255, 255, 0.5)',
      }}
      transition="all 0.3s linear"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        rounded="full"
        bg="#fff"
        w={{ base: '42px', md: '102px' }}
        height={{ base: '42px', md: '102px' }}
        color="#FFB16A"
        fontSize={{ base: '15px', md: '30px' }}
      >
        <TbPlayerPlayFilled />
      </Flex>
    </Flex>
  );
};

export default PlayIcon;
