import { useState } from 'react';

import { Box, Container, Flex, Image, ListItem, UnorderedList } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Components */
import config from '@config';
import SectionTitle from '../Text/SectionTitle';
// import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */

const LeaderReimagined = ({ sectionContent, maxW, pb, ml, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });

  const sliderDotSettings = {
    base: 1,
    md: 2
  };

  const NextArrow = props => {
    const { onClick, currentSlide, slideCount } = props;
    // console.log('props', currentSlide, activeTab, slideCount);
    return (
      <>
        {/* {parseInt(currentSlide) < parseInt(activeTab) && ( */}
        {currentSlide % 1 == 0 && (
          <Box
            pos="absolute"
            textAlign={'center'}
            top="45%"
            display={{ base: 'none', md: 'flex' }}
            zIndex="10"
            cursor="pointer"
            bgColor="#F27123"
            _hover={{ background: '#BA5010' }}
            border="0"
            borderRadius="60px"
            w={{ md: '54px', base: '34.4px' }}
            h={{ md: '54px', base: '34.4px' }}
            bgPos="center"
            bgRepeat="no-repeat"
            color="transparent"
            alignItems="center"
            justifyContent="center"
            //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
            right={{ base: '54px', md: '100px', lg: '10px' }}
            bgSize="13px"
            onClick={onClick}
            boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
          >
            <svg
              width="15"
              height="24"
              viewBox="0 0 15 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z"
                fill="white"
              />
            </svg>
          </Box>
        )}
      </>
    );
  };

  const PrevArrow = props => {
    const { onClick, activeTab, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <Box
            pos="absolute"
            top="45%"
            zIndex="10"
            display={{ base: 'none', md: 'flex' }}
            cursor="pointer"
            bgSize="13px"
            bgColor="#F27123"
            _hover={{ background: '#BA5010' }}
            border="0"
            borderRadius="60px"
            w={{ base: '34.4px', md: '54px' }}
            h={{ base: '34.4px', md: '54px' }}
            bgPos="center"
            bgRepeat="no-repeat"
            color="transparent"
            alignItems="center"
            justifyContent="center"
            //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
            left={{ base: '3px' }}
            onClick={onClick}
            boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
          >
            <svg
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z"
                fill="white"
              />
            </svg>
          </Box>
        )}
      </>
    )
  };

  const settings = {
    dots: true,
    // arrows: false,
    infinite: false,
    autoplay: false,
    lazyLoad: false,
    slidesToShow: 2.2,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex height="40px">
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          //width="108px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="3em"
        >
          <UnorderedList
            m="0"
            display="flex"
          >
            {/* {dots}{' '} */}
            {dots.map((dot, index) => {
              const button = dot.props.children;
              return (
                <ListItem
                  onClick={e => {
                    // console.log('button.props', dot, activeTab, Math.ceil(activeTab) % sliderDotSettings.md);
                    button.props.onClick(e);
                  }}
                  key={index}
                  width="19px !important"
                  height="13px !important"
                  borderRadius="9px !important"
                  background={(Math.ceil((activeTab) / (sliderDotSettings[isSmallScreen ? 'base' : 'md'])) === parseInt(dot.key)) ? "#F27123" : "#0E0E6B"}
                  margin="0 !important"
                />
              );
            })}
          </UnorderedList>
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 934,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };
  // TODO: mobile responsive to be made

  return (
    <Container
      maxW={
        maxW || {
          base: '100%',
          sm: '540px',
          md: '720px',
          lg: '960px',
          xl: '1170px',
        }
      }
      // pl={{ base: '16px', xl: 0 }}
      mx="auto"
      w="100%"
      pr={0}
      pl={{ base: '16px', sm: '16px', md: '0' }}
      // ml={ml || '10px'}
      paddingBottom={pb || '5em'}
      py={{ base: '30px', md: '40px', lg: '60px' }}
      {...props}
    >
      <Box pb={{ base: '30px', lg: '38px' }} >
        <SectionTitle titleObj={sectionContent[0]} />
      </Box>
      <Box
        position="relative"

        _before={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: { base: "0", md: '5%' },
          top: '0px',
          right: activeTab % 1 == 0 ? '0px' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
          //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)': 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}

        _after={{
          display: true,
          content: "''",
          height: '99%',
          position: 'absolute',
          bottom: '0px',
          width: { base: "0", md: '5%' },
          top: '0px',
          left: activeTab > 0 ? '0' : 'none',
          zIndex: 2,
          // background: isSmallScreen ? 'none' : (activeTab  > 0 &&
          //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
        }}
      >
        <Slider
          {...settings}
          beforeChange={(old, newChange) => {
            setActiveTab(newChange);
          }}
        >
          {sectionContent
            .map((a, i) => {
              return (
                <Box
                  key={nanoid()}
                  pr={{ base: '16px', md: '20px' }}
                  h={{ base: '440px', md: '440px', xl: '312px' }}
                  w={{ base: '281px', md: '572px' }}
                >
                  <Box
                    display="flex"
                    pr="30px"
                    justifyContent="flex-end"
                    m="-5px"
                    pos="relative"
                    top={{ base: '5px', md: '8px' }}
                  >
                    <Image
                      src={`${config.staticPath}/d/46272/1692094446-group-1258.png`}
                      alt="quote"
                      width={{ base: '31px', md: '44px' }}
                    />
                  </Box>
                  <Box
                    bg="#FFF4E4"
                    boxShadow={{
                      base: '0px 10px 23.399999618530273px 0px rgba(0, 0, 0, 0.04), 0px 50px 144px 0px rgba(0, 0, 0, 0.07)',
                      md: 'none',
                    }}
                    h="100%"
                  >
                    <Flex
                      flexDir={{ base: 'column', lg: 'column', xl: 'row' }}
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent={{
                        base: 'flex-start',
                        lg: 'flex-start',
                        xl: 'space-between',
                      }}
                      h={{ base: 'auto', md: '100%' }}
                    >
                      <Box
                        w={{ base: '100%', lg: '100%', xl: '50%' }}
                        height={{ base: '188px', lg: '188px', xl: '100%' }}
                      >
                        <Image
                          // padding={3}
                          src={a?.desktopImage?.url}
                          alt={a?.desktopImage?.alt}
                          width={{ base: '100%', lg: '100%', xl: '258px' }}
                          objectFit="cover"
                          objectPosition="top center"
                          height={{ base: '188px', lg: '188px', xl: '100%' }}
                          id={i}
                        />
                      </Box>
                      <Flex
                        justifyContent={{
                          base: 'start',
                          md: 'start',
                          xl: 'center',
                        }}
                        alignItems="center"
                        w={{ base: '100%', md: '100%', xl: '50%' }}
                        px={{ base: '18px', md: '18px', xl: '0' }}
                      >
                        <Box
                          w={{ base: '100%', md: '100%', xl: '226px' }}
                          mx="auto"
                        >
                          <Box
                            h={{ base: '115px', md: '115px', xl: '178.416px' }}
                          >
                            {a?.description && (
                              <StructuredTextParser
                                color="#666"
                                textAlign="center"
                                fontFamily="FedraSansStd-book"
                                fontSize={{ base: '16px', md: '18px' }}
                                fontStyle="italic"
                                fontWeight="400"
                                style={{ fontFeatureSettings: 'case on' }}
                                lineHeight={{ base: '23px', md: '26px' }}
                                str={render(a?.description)}
                              />
                            )}
                          </Box>
                          <Image
                            w={116}
                            h={'62.462px'}
                            mt="20px"
                            src={a?.logo?.url}
                            alt={a?.logo?.alt}
                            objectFit="contain"
                            mx="auto"
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              );
            })
            .slice(1, -1)}
        </Slider>
      </Box>
    </Container>
  );
};

export default LeaderReimagined;
