/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import {useRef, useState, useLayoutEffect} from 'react'
/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
/**
 *
 * @param {Object} postData
 * @returns
 */
const PopUpCard = ({ postData }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.scrollHeight);
    setClientHeight(ref.current.clientHeight)
  }, []);

  return (
    <Flex flexDir={{ base: 'column', md: 'row' }} justifyContent="space-between">
      <Box display="flex" className="Individual container">
        <Box width={{ base: '100%' }}>
          <LazyLoadImageComponent
            alt="Participant"
            src={postData.image_src || postData?.image?.url}
            w={{ base: 'auto', md: '355px' }}
            h={{ base: '341px', md: '447.16px' }}
            objectFit={{ base: 'unset', md: 'cover' }}
            mx="auto"
          />
        </Box>
      </Box>
      <Box
        maxW={700}
        ml={{ base: '0px', md: '40px' }}
        mt={{ base: '30px', md: '0' }}
        position='relative' 
      >
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Text
             fontFamily="FedraSansStd-medium, sans-serif"
            fontStyle="normal"
            letterSpacing="-0.098px"
            fontWeight={500}
            color="#210303"
            fontSize="32px"
            lineHeight={{ base: '22.495px', md: '27.139px' }}
            pb={{base:"9px"}}
            // mt={{ base: '30px', md: '30px' }}
          >
            {postData?.title}
          </Text>
          <Box
            width="2px"
            height="66px"
            bg="#F27123"
            ml="27px"
            mr="30px"
            display={{ base: 'none', md: 'block' }}
          ></Box>

          <Text
            color="#210303"
            maxW={{ base: '100%', md: '348.931px' }}
            lineHeight={{ base: '20px', md: '24px' }}
            fontSize="16px"
            // mt={{ base: '1px', md: '5px' }}
            fontWeight={400}
            fontStyle='italic'
            pb={{base:"24px"}}
          >
            {postData?.designation}
          </Text>
        </Flex>
        <Box maxH={{ base: '130px', md: '390px' }} pb="30px" className='scrollbar' ref={ref}>
          
          <StructuredTextParser
            color="#5E5E5E"
            mt={{ base: '12px', md: '37px' }}
            lineHeight={{ base: '24px', md: '32px' }}
            fontSize={{ base: '16px', md: '18px' }}
            str={render(postData?.description)}
            pb="70px"
          />
     
        </Box>
        {/* {
          clientHeight < height ?

          (
            <Box display={{base:'none', lg:'flex'}} justifyContent="center" bgGradient="linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)" height="90px" position="absolute" bottom="0px" w="full">
            <Box display="flex" alignItems="end" h="full">
            <svg width="32" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L16 16L30 2" stroke="#848484" stroke-width="4"/>
            </svg>
            </Box>
          </Box>
          ) : null
        } */}
       
      </Box>
      <style jsx global> 
        {`
        .scrollbar {
          overflow-y: auto;
          padding-right:20px
        }

        .scrollbar::-webkit-scrollbar {
            width: 12px;
          }
          
          .scrollbar::-webkit-scrollbar-track {
            background: #E4E4E4;
          }
          
          .scrollbar::-webkit-scrollbar-thumb {
            background: #F27123;
          }
          
          .scrollbar ::-webkit-scrollbar-thumb:hover {
            background: #E4E4E4;
          }
          @media (max-width:767px) {
            .scrollbar {
              overflow-y: unset;
            }
           
          }
        `}
        
      </style>
    </Flex>
  );
};

export default PopUpCard;
