/* Built In Imports */
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { getEmbedUrl } from '@ila/components/Utility/utils';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BsArrowRightCircle } from 'react-icons/bs';

/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import PopUpCard from '@ila/components/Card/PopUpCard';
import { render } from 'datocms-structured-text-to-html-string';
import { CgPlayButtonO } from 'react-icons/cg';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const LeadersCard = ({ section, loadMorePost }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cards, setCards] = useState([]);
  const [popupCard, setPopupCard] = useState({});
  const cardCount = 9;
  const router = useRouter();
  const pathName = usePathname();
  const [clickOption, setPopupCardOption] = useState({});
  const [opts, setOpts] = useState({});

  const loadMorePosts = async () => {
    const morePosts = section?.cards.slice(
      cards.length,
      cards.length + cardCount
    );

    if (morePosts?.length > 0) {
      setCards(prev => [...prev, ...morePosts]);
    }
  };

  const openPopup = (leader, key) => {
    const parentUrl = `${pathName}?popup=1`;
    window.history.pushState(window.history.state, '', parentUrl);
    setPopupCard(leader);
    setPopupCardOption(key);
    onOpen();
  };

  useEffect(() => {
    loadMorePosts();
  }, []);

  useEffect(() => {
    setOpts({
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global.window]);

  const onReady = event => {
    event.target.playVideo();
  };

  return (
    <>
      <Flex flexWrap="wrap" w="full">
        <Heading
          fontFamily="var(--FedraSansCondensedMedium), sans-serif"
          textTransform="uppercase"
          color="#fff"
          fontSize={['40px', '40px', '70px', '90px', '90px']}
          lineHeight={['42px', '42px', '70px', '90px', '90px']}
          letterSpacing={{ base: '1px', lg: '2px' }}
          as="h1"
        >
          {section?.title}
        </Heading>

        <Flex
          flexWrap="wrap"
          w="full"
          fontSize={{ base: '14px', md: '18px' }}
          color="#D4D4FA"
          pb="26px"
        >
          <StructuredTextParser str={render(section?.description)} />
        </Flex>
      </Flex>
      <Flex
        textAlign="center"
        gridGap={{ md: '30px' }}
        flexWrap="wrap"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        mb="40px"
      >
        {!loadMorePost &&
          section?.cards &&
          section?.cards?.map(item => (
            <Flex
              key={item?.uuid}
              flexDirection="column"
              maxW={{ base: '293.513px', md: '47%', lg: '31%', xl: '355px' }}
              mb="40px"
            >
              <Box
                display="flex"
                flexDirection={{ md: 'row' }}
                marginTop="25px"
                className="Individual container"
              >
                <Box width={{ base: 'auto' }}>
                  <LazyLoadImageComponent
                    alt="Participant"
                    src={item?.image?.url}
                    w={{ base: '293.513px', md: '100%', lg: '355px' }}
                    h={{ base: '370.646px', md: '100%', lg: '447.16px' }}
                    objectFit="cover"
                  />
                </Box>
              </Box>
              <Box>
                <Text
                  fontFamily="var(--FedraSansStd-medium), sans-serif"
                  fontStyle="normal"
                  letterSpacing="-0.098px"
                  fontWeight={500}
                  color="#fff"
                  fontSize={{ base: '20px', md: '24px' }}
                  lineHeight={{ base: '22.495px', md: '27.139px' }}
                  mt={{ base: '30px', md: '30px' }}
                >
                  {item?.title}
                </Text>
                <Text
                  color="#D4D4FA"
                  lineHeight={{ base: '20px', md: '24px' }}
                  fontSize={{ base: '14px', md: '16px' }}
                  mt={{ base: '1px', md: '5px' }}
                  fontWeight={400}
                  fontStyle="normal"
                >
                  {item?.designation}
                </Text>
                <Text
                  onClick={() => {
                    openPopup(item, 'readMore');
                  }}
                  cursor="pointer"
                  color="#F27123"
                  fontFamily="var(--FedraSansStd-book)"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontStyle="normal"
                  fontWeight="450"
                  lineHeight={{ base: '16.871px', md: '20.354px' }}
                  letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                  textDecoration="underline"
                  display="flex"
                  justifyContent="center"
                  mt={{ base: '16px', md: '26px' }}
                >
                  <Text as="span" mr="5px">
                    Read More
                  </Text>
                  <BsArrowRightCircle style={{ marginTop: '3px' }} />
                </Text>
                {item.embedLink && (
                  <Text
                    onClick={() => {
                      openPopup(item, 'viewExcerpts');
                    }}
                    cursor="pointer"
                    color="#F27123"
                    fontFamily="var(--FedraSansStd-book)"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontStyle="normal"
                    fontWeight="450"
                    lineHeight={{ base: '16.871px', md: '20.354px' }}
                    letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                    textDecoration="underline"
                    display="flex"
                    justifyContent="center"
                    mt={{ base: '16px', md: '26px' }}
                  >
                    <Text as="span" mr="5px">
                      View Excerpts
                    </Text>
                    <CgPlayButtonO style={{ marginTop: '3px' }} />
                  </Text>
                )}
              </Box>
            </Flex>
          ))}
        {loadMorePost &&
          cards &&
          cards?.map(item => (
            <Flex
              key={item?.uuid}
              flexDirection="column"
              maxW={{ base: '293.513px', md: '47%', lg: '31%', xl: '355px' }}
              mb="40px"
            >
              <Box
                display="flex"
                flexDirection={{ md: 'row' }}
                marginTop="25px"
                className="Individual container"
              >
                <Box width={{ base: 'auto' }}>
                  <LazyLoadImageComponent
                    alt="Participant"
                    src={item?.image?.url}
                    w={{ base: '293.513px', md: '100%', lg: '355px' }}
                    h={{ base: '370.646px', md: '100%', lg: '447.16px' }}
                    objectFit="cover"
                  />
                </Box>
              </Box>
              <Box>
                <Text
                  fontFamily="var(--FedraSansStd-medium), sans-serif"
                  fontStyle="normal"
                  letterSpacing="-0.098px"
                  fontWeight={500}
                  color="#fff"
                  fontSize={{ base: '20px', md: '24px' }}
                  lineHeight={{ base: '22.495px', md: '27.139px' }}
                  mt={{ base: '30px', md: '30px' }}
                >
                  {item?.title}
                </Text>
                <Text
                  color="#D4D4FA"
                  lineHeight={{ base: '20px', md: '24px' }}
                  fontSize={{ base: '14px', md: '16px' }}
                  mt={{ base: '1px', md: '5px' }}
                  fontWeight={400}
                  fontStyle="normal"
                >
                  {item?.designation}
                </Text>
                <Text
                  onClick={() => {
                    openPopup(item, 'readMore');
                  }}
                  cursor="pointer"
                  color="#F27123"
                  fontFamily="var(--FedraSansStd-book)"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontStyle="normal"
                  fontWeight="450"
                  lineHeight={{ base: '16.871px', md: '20.354px' }}
                  letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                  textDecoration="underline"
                  display="flex"
                  justifyContent="center"
                  mt={{ base: '16px', md: '26px' }}
                >
                  <Text as="span" mr="5px">
                    Read More
                  </Text>
                  <BsArrowRightCircle style={{ marginTop: '3px' }} />
                </Text>
                {item.embedLink && (
                  <Text
                    onClick={() => {
                      openPopup(item, 'viewExcerpts');
                    }}
                    cursor="pointer"
                    color="#F27123"
                    fontFamily="var(--FedraSansStd-book)"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontStyle="normal"
                    fontWeight="450"
                    lineHeight={{ base: '16.871px', md: '20.354px' }}
                    letterSpacing={{ base: '-0.023px', md: '-0.027px' }}
                    textDecoration="underline"
                    display="flex"
                    justifyContent="center"
                    mt={{ base: '16px', md: '26px' }}
                  >
                    <Text as="span" mr="5px">
                      View Excerpts
                    </Text>
                    <CgPlayButtonO style={{ marginTop: '3px' }} />
                  </Text>
                )}
              </Box>
            </Flex>
          ))}
      </Flex>
      {loadMorePost &&
        section.cards &&
        cards.length < section?.cards.length && (
          <Button
            onClick={loadMorePosts}
            display="block"
            width="216px"
            p="10px 23px"
            fontWeight="500"
            color="#D8A24C"
            fontSize="16px"
            fontFamily="var(--FedraSansStd-medium)"
            textAlign="center"
            m="30px auto 50px"
            border="2px solid #D8A24C"
            borderRadius="1px"
            title="Load More"
            rel="next"
            _hover={{
              textDecoration: 'none',
              bgColor: '#D8A24C',
              color: 'white',
            }}
          >
            LOAD MORE ({cards?.length}/{section?.cards?.length})
          </Button>
        )}

      {clickOption == 'viewExcerpts' && (
        <Box
          maxW={{ base: '100%', md: 'none', lg: '670px' }}
          px="0px"
          mt={{ base: '0px', md: '0px' }}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="6xl"
            isCentered
            maxW={{ base: 'auto', md: '1256px' }}
            h={{ base: '100vh', md: '657px' }}
          >
            <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
            <ModalContent
              p={0}
              width={{ base: '100%', md: '95%' }}
              m="0"
              bg={{ base: '#000000' }}
              // transform={{ base: 'rotate(270deg)', md: 'none' }}
              h={{ base: '100%', md: 'auto' }}
            >
              <ModalCloseButton
                _hover={{ bg: '#fff', color: '#000' }}
                _active={{ bg: '#fff', color: '#000' }}
                _focus={{ bg: '#fff', color: '#000' }}
                zIndex="20"
                top={{ base: '12px', md: '-24px' }}
                bg="#fff"
                color="#000"
                w={{ base: '30px', md: '48px' }}
                height={{ base: '30px', md: '48px' }}
                right={{ base: '0', md: '-24px' }}
                className="closeButton"
                rounded="full"
              />
              <ModalBody p={0} m="0" w="100%" position="relative" height="100%">
                <Box
                  pos={{ base: 'static', md: 'static' }}
                  left="50%"
                  top="50%"
                  // transform={{ md: 'none', base: 'translate(-50%, -50%)' }}
                  display={{ base: 'flex', md: 'block' }}
                  justifyContent={{ base: 'center' }}
                  alignItems={{ base: 'center' }}
                  height="100%"
                >
                  <Box
                    bg="#FFFFFF"
                    onClick={onClose}
                    w="48px"
                    h="48px"
                    rounded="full"
                    _hover={{ bg: '#ffffff' }}
                    display={{ base: 'flex', md: 'none' }}
                    // placeItems="center"
                    justifyContent="center"
                    alignItems="center"
                    pos="absolute"
                    top="-145px"
                    left="-185px"
                    fontFamily="var(--FedraSansStd-Book), sans-serif"
                    zIndex="10"
                    cursor="pointer"
                    color="black"
                  >
                    x
                  </Box>
                  <Box
                    w="100%"
                    height={{ base: '100vh', md: '90vh', lg: '90vh' }}
                    id="msr-vid"
                    bg={{ base: '#000', md: '#fff' }}
                    p="14px"
                  >
                    <YouTube
                      videoId={getEmbedUrl(popupCard.embedLink)}
                      onReady={onReady}
                      opts={opts}
                      loading="lazy"
                      className="insightVideo"
                    />{' '}
                  </Box>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      <style global jsx>
        {`
          .insightVideo {
            height: 100%;
          }
          .insightVideo iframe {
            height: inherit;
          }
          .closeButton:hover {
            background: #ffffff !important;
            color: black !important;
          }
          .textWhite p {
            font-family: 'FedraSansStd-book', sans-serif;
          }
          .textWhite p strong {
            font-family: 'FedraSansStd-medium', sans-serif;
            font-weight: 400;
          }
          @media only screen and (max-width: 550px) {
            .insightVideo {
              width: 100%;
              height: 100%;
            }
            .insightVideo iframe {
              width: 100%;
              height: 100%;
            }
          }
        `}
      </style>

      {clickOption == 'readMore' && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          // size={{ base: 'xs', md: 'xs' }}
          // isCentered
        >
          <ModalOverlay />
          <ModalContent
            rounded="none"
            maxW={1150}
            p={{ base: '69px 18px 0 16px', md: '40px 0px 31px 26px' }}
            height={{ base: '90dvh', md: 'auto' }}
            overflow={{ base: 'scroll', md: 'unset' }}
          >
            <ModalCloseButton outline="0" />
            <ModalBody px={{ base: '0px', md: '20px' }}>
              <PopUpCard postData={popupCard} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LeadersCard;
