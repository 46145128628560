/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Link } from '@chakra-ui/react';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@ila/components/Utility/utils';

/* Styles */

/* Services */

/**
 * Renders parsed string for structured text ShortCode.
 *
 * @param {string} str - HTML string.
 * @param {string} className - Class Name
 * @param {string} region - Region of the page
 * @param {string} textAlign - Alignment of the page
 * @returns {ReactElement} Structured Text Parser Code component
 */

const StructuredTextParser = ({
  str,
  mt,
  className,
  textAlign,
  region,
  lang,
  px,
  maxW,
  fontSize,
  sectionContent,
  display,
  mode,
  mb,
  ...props
}) => {
  const allTags = ['readmore'];
  const [readMore, setReadMore] = useState(true);
  const structured = useRef();
  const color = '#C1C3DD';
  let innerText = '';
  let initialString = '';
  let startTagIndex = -1;

  if (textAlign && textAlign === 'center-align') {
    textAlign = 'center';
  }

  // <a([^>]* )href=\"([^\"]+)\"
  const re = new RegExp('href="([^"]+)"', 'g');
  const strMatched = str && str.match(re);
  if (strMatched && strMatched.length) {
    forEach(strMatched, function (matched) {
      matched = matched.replace('href=', '').replace(/"/g, '');
      str = str.replace(
        `"${matched}"`,
        `"${refineUrlDomain(matched, region, lang)}"`
      );
    });
  }

  forEach(allTags, function (tag) {
    const matched = str && str.match(/\[(.*?)\]/g);
    if (matched && matched.length && matched[0].indexOf(tag) > -1) {
      startTagIndex = str.indexOf(matched[0]);
    }

    initialString = str?.substring(0, startTagIndex) || str;
    if (startTagIndex && startTagIndex > -1) {
      const endTagStartIndex = str.indexOf(`[/${tag}]`);
      const endTagEndIndex = endTagStartIndex + tag.length + 3;
      const tagTextString = str?.substring(startTagIndex, endTagEndIndex);
      innerText = tagTextString
        .replace(`[${tag}]`, '')
        .replace(`[/${tag}]`, '')
        .replace(matched[0], '');
    }
  });

  useEffect(
    () => () => {
      setReadMore(true);
    },
    [str]
  );

  return (
    <Box ref={structured} display={display || 'block'} >
      <Box
        className={className || (mode === 'light' ? "st-text-light" : "st-text")}
        m={{ base: '0 auto' }}
        mt={{ base: mt || '15px', md: mt || '30px', lg: mt || '30px' }}
        maxW={maxW || '696px'}
        textAlign={textAlign || 'left'}
        fontSize={`${fontSize || '1rem'} `}
        color={color}
        px={px || '0'}
        mb={{ base: mb || '0', md: mb || '0', lg: mb || '0' }}
        {...props}
      >
        {initialString && parser(initialString)}
      </Box>
      {startTagIndex > -1 && (
        <Box
          mx="auto"
          mt={{ base: mt || '10px', md: mt || '30px', lg: mt || '30px' }}
          maxW={{
            base: '100%',
            md: '515px',
            lg: '600px',
            xl: '770px',
          }}
        >
          <Collapse in={!readMore} animateOpacity duration={20000}>
            <Box
              fontFamily="FedraSansStd-book"
              pb="30px"
              className={`${className || ''}`}
              textAlign={textAlign || 'left'}
            >
              {parser(innerText)}
            </Box>
          </Collapse>
          <Box textAlign={'center'} position="relative">
            <Link
              display="inline-block"
              mb="20px"
              fontFamily="FedraSansStd-medium"
              borderRadius="3px"
              bgColor="transparent"
              border="solid 1px #cf4520 !important"
              color={'#cf4520 !important'}
              fontSize="18px !important"
              padding="10px 35px"
              textDecoration="none !important"
              transition="none !important"
              _hover={{
                textDecoration: 'none',
                bgColor: '#cf4520',
                color: 'white !important',
              }}
              textAlign="center"
              width={'200px'}
              onClick={() => setReadMore(!readMore ? true : false)}
            >
              Read {readMore ? 'More' : 'Less'}
              {readMore ? (
                <ChevronDownIcon w={7} h={10} />
              ) : (
                <ChevronUpIcon w={7} h={10} />
              )}
            </Link>
          </Box>
        </Box>
      )}
      <style>
        {`
          .st-text-light p {
            color: #1A202C;
            margin-bottom: 20px;
          }
          .st-text-light ol, .st-text-light ul {
            margin-left: 20px;
          }
          .st-text p {
            margin-bottom: ${'20px'};
          }
          .st-text p a, .st-text-light p a{
            color: #F27123;
            text-decoration:underline;
            line-height:20px;
          }
        `}
      </style>
    </Box>
  );
};

export default StructuredTextParser;
