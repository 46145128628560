import SectionTitle from '@ila/components/Text/SectionTitle';

import {
  Box,
  Flex,
  Show
} from '@chakra-ui/react';
import { programOverview } from '@utils/microsites/Data';
import StructuredTextParser from '@landing_pages/ila/components/Text/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

export default function ProgramOverview({ sectionContent }) {
  // console.log(sectionContent, 'program overview');
  return (
    <Box py={{ base: '30px', md: '40px', xl: '60px' }} >
      <Box
        bgImage="https://static.sadhguru.org/d/103645/1691647753-artboard-1-2.png"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        fontFamily="FedraSansStd-book"
        mx="auto"
        w="100%"
        maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330 }}
      
      >
        <Flex
          w="100%"
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
          maxWidth="1330px"
          margin="0 auto"
          padding={{
            xl: '4rem 5rem',
            lg: '4rem 4rem',
            md: '4rem 2rem',
            base: '4rem 1rem',
          }}
        >
          <Box
            w={{ md: 'calc(50%- 25px)', base: '100%' }}
            marginRight={{ md: '25px', base: '' }}
          >
            <Box>
              <SectionTitle titleObj={sectionContent[0]} />

              <StructuredTextParser
                str={render(sectionContent[1].body)}
                color="#FFFFFF"
                fontWeight={450}
                // textAlign="right"
                fontSize={{ xl: '22px', md: '18px', base: '16px' }}
                lineHeight={{ xl: '34px', md: '28px', base: '24px' }}
                marginTop="20px"
                maxWidth="420px"
                marginBottom="50px"
                id="programOverText"

              />
              <style global jsx>
                {`
                #programOverText p mark {
                  color: #c3934c;
                  background-color: transparent;
                }
                #programOverText ul{
                  margin:20px;
                  padding:0px
                  
                }
              `}
              </style>
              {/* <Show above="md">
              <Link
                href="#"
                color="#F27123"
                fontWeight={500}
                textAlign="center"
                fontSize="20px"
                lineHeight="25px"
                textDecorationLine="underline"
              >
                {programOverview.sectionContent.text5}
              </Link>
            </Show> */}

              {/* <Show above="md">
              <Flex flexDirection="row" marginTop="30px">
                <InformationIcon width="30px" height="30px" />
                <StructuredTextParser
                  str={render(sectionContent[3].body)}
                  color="#ECECEC"
                  fontWeight={400}
                  fontSize="16px"
                  mt="0"
                  lineHeight="23px"
                  fontStyle="italic"
                  width="420px"
                  paddingLeft="10px"
                ></StructuredTextParser>
              </Flex>
            </Show> */}
            </Box>
          </Box>

          <Box
            w={{ md: 'calc(50%- 25px)', base: '100%' }}
            marginLeft={{ md: '25px', base: '' }}
            overflow="auto"
          >
            <Box overflow="auto">
              <Show above="md">
                <LazyLoadImageComponent
                  float={{ base: 'left', md: 'unset' }}
                  src={sectionContent[3]?.image?.url}
                  alt={sectionContent[3]?.image?.alt || ''}
                />
              </Show>
              <Show breakpoint="(max-width: 767px)">
                <LazyLoadImageComponent
                  width={{ base: '100%' }}
                  src={sectionContent[3]?.image?.url}
                  alt={sectionContent[3]?.image?.alt || ''}
                />
              </Show>
            </Box>

           {/*  <Show breakpoint="(max-width: 767px)">
              <Box overflow="auto" padding="40px 0" background='green'>
                <Box overflow="auto" display="inline-block" float="left">
                  <LazyLoadImageComponent
                    src={programOverview.sectionContent.img2}
                    width="30px"
                    height="30px"
                  />
                </Box>
                <Box 
                  color="#ECECEC"
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="23px"
                  fontStyle="italic"
                  width="90%"
                  float="left"
                  paddingLeft="10px"
                >
                  <StructuredTextParser
                    str={render(sectionContent[3].body)}
                    color="#ECECEC"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="23px"
                    mt="0"
                    fontStyle="italic"
                    width="420px"
                    paddingLeft="10px"
                  ></StructuredTextParser>
                </Box>
              </Box>
            </Show>*/}
            {/* <Show breakpoint="(max-width: 767px)">
            <Center>
              <Link
                href="#"
                color="#F27123"
                fontWeight={500}
                textAlign="center"
                fontSize="20px"
                lineHeight="25px"
                textDecorationLine="underline"
              >
                Download Brochure
              </Link>
            </Center>
          </Show> */}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
