import { Flex, Heading } from '@chakra-ui/react';

const SectionTitle = ({
  titleObj,
  textAlign,
  color,
  t1level,
  t2level,
  mt,
  lineHeight,
  ...props
}) => {
  // console.log('==>', titleObj)
  return (
    <Flex flexDirection="column" maxW={934}>
      <Heading
        fontFamily="FedraSansCondensedMedium, sans-serif"
        textTransform="uppercase"
        color={titleObj?.color || color || '#fff'}
        textAlign={textAlign || 'left'}
        as={t1level || 'h2'}
        fontSize={['40px', '40px', '70px', '90px', '90px']}
        lineHeight={lineHeight || ['42px', '42px', '70px', '90px', '90px']}
        letterSpacing={{ base: '1px', lg: '2px' }}
      >
        {titleObj?.title1 || titleObj?.titleText}
      </Heading>
      <Heading
        color={titleObj?.color || '#D8A24C'}
        textTransform="uppercase"
        fontFamily="FedraSansCondensedMedium, sans-serif"
        lineHeight={lineHeight || ['30px', '40px', '45px', '70px', '70px']}
        fontSize={['26px', '26px', '45px', '60px', '60px']}
        textAlign={textAlign || 'left'}
        as={t2level || 'h3'}
        letterSpacing="1px"
      >
        {titleObj?.title2 || titleObj?.titleSubtext}
      </Heading>
    </Flex>
  );
};

export default SectionTitle;
