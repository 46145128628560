import { Box } from '@chakra-ui/react';
import React from 'react';

const LeftQuotes = () => {
  return (
    <Box
      display="inline"
      className="ilaQuotes"
      pos="relative"
      top={{ base: '-5px', md: '-5px' }}
    >
      <style>
        {`
        .ilaQuotes svg{
            display:inline !important;
        }
        `}
      </style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
      >
        <path
          d="M0.00619779 8.42343C-0.12615 5.24046 1.99461 2.34254 4.45126 0.6391C4.919 0.314815 5.55677 -0.136264 6.1532 0.0400924C6.63489 0.182486 7.11242 0.879357 6.80222 1.37346C6.70314 1.5312 6.55538 1.64818 6.41838 1.77196C5.85327 2.28241 5.12939 3.13979 4.97111 3.91238C4.80035 4.74435 5.7701 4.79366 6.24885 5.16424C8.40215 6.83145 7.52953 10.3513 5.45892 11.6459C4.60073 12.1825 3.53851 12.3787 2.56143 12.1397C0.826711 11.7153 0.0764081 10.0954 0.00668654 8.42343L0.00619779 8.42343Z"
          fill="#D8A24C"
        />
        <path
          d="M8.48405 8.76641C8.45641 8.12689 8.54252 7.47531 8.68783 6.88636C9.26249 4.55826 10.7403 2.45682 12.6045 1.03515C13.2073 0.575519 13.9451 0.0132425 14.735 0.0706023C15.4719 0.124188 15.7026 1.14509 15.1878 1.62888C14.6514 2.13329 14.1834 2.68651 13.7721 3.3142C13.3281 3.9917 13.2877 4.28328 14.1124 4.74191C16.2682 5.94043 16.6354 8.20715 15.3028 10.3647C14.0072 12.4626 10.6506 12.9471 9.13112 10.8364C8.70324 10.2419 8.51634 9.51233 8.48405 8.76641Z"
          fill="#D8A24C"
        />
      </svg>
    </Box>
  );
};

const RightQuotes = () => {
  return (
    <Box
      display="inline"
      className="ilaQuotes"
      pos="relative"
      top={{ base: '-5px', md: '-5px' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
      >
        <path
          d="M16.0934 3.8168C16.2258 6.99977 14.105 9.8977 11.6484 11.6011C11.1806 11.9254 10.5428 12.3765 9.94641 12.2001C9.46472 12.0577 8.98719 11.3609 9.29739 10.8668C9.39647 10.709 9.54423 10.5921 9.68123 10.4683C10.2463 9.95783 10.9702 9.10045 11.1285 8.32785C11.2993 7.49588 10.3295 7.44657 9.85076 7.076C7.69746 5.40879 8.57008 1.88896 10.6407 0.594336C11.4989 0.0577199 12.5611 -0.138511 13.5382 0.100488C15.2729 0.5249 16.0232 2.14481 16.0929 3.8168L16.0934 3.8168Z"
          fill="#D8A24C"
        />
        <path
          d="M7.59701 3.47358C7.62465 4.11309 7.53854 4.76468 7.39322 5.35363C6.81857 7.68173 5.34072 9.78317 3.47658 11.2048C2.8738 11.6645 2.13597 12.2267 1.34604 12.1694C0.609189 12.1158 0.378496 11.0949 0.893213 10.6111C1.4297 10.1067 1.89769 9.55348 2.30893 8.92579C2.75294 8.24829 2.79331 7.95671 1.96864 7.49808C-0.187106 6.29956 -0.554307 4.03284 0.778232 1.87531C2.07383 -0.222604 5.4305 -0.707144 6.94994 1.4036C7.37781 1.99808 7.56471 2.72765 7.59701 3.47358Z"
          fill="#D8A24C"
        />
      </svg>
    </Box>
  );
};

export { LeftQuotes, RightQuotes };
