/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
/* External Imports */
import {
  Box, Container, Heading, Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
import { CheckIcon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';
import { getCurrentRegion } from 'services/commonService';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const Pricing = ({ sectionContent }) => {
  const router = useRouter()
  const [currRegion, setCurrRegion] = useState('in');
  const [hideRegion, setHideRegion] = useState("in")

  useEffect(() => {
    (async () => {
      /* Logging the value of currRegion and currRegion?.country.toLowerCase() */
      let cRegion;
      try {
        cRegion = await getCurrentRegion();
        // console.log('cRegion', cRegion);
        setCurrRegion(router?.query?.region || cRegion?.country?.toLowerCase());
      } finally {
        if (cRegion?.length === 0) {
          setCurrRegion('us');
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (currRegion) {
      if (currRegion === "in") {
        setHideRegion("us")
      }
      else if (currRegion === "us") { setHideRegion("in") }
    }
  }, [currRegion])
  // console.log(sectionContent, router.query.region, "section content")
  return (
    <Box
      fontFamily="FedraSansStd-book"
      mx="auto"
      w="100%"
      maxW={{ base: '100%', md: '100%', lg: '100%', xl: 1330, '2xl': '1330px' }}
      py={{ base: "60px", md: "90px", xl: "100px" }}
    >
      <Box
        bg={`url(${config.imgPath}/d/103700/1691417871-what-to-expect-bg.png)`}
        position="relative"
        bgRepeat="no-repeat"
        bgSize={{ md: "100% 452px", base: "100% 243px" }}
      >
        <Box
          padding={{
            '2xl': '50px 0px 50px 135px',
            xl: '50px 0px 50px 100px',
            md: '50px 0px 50px 45px',
            base: '25px 0px 22px 35px',
          }}
        >
          <Heading
            color="#ffff"
            fontSize={{
              '2xl': '90px',
              xl: '80px',
              lg: '60px',
              md: '50px',
              base: '40px',
            }}
            lineHeight={{ md: '70px', base: '42px' }}
            paddingBottom={{ base: '0px', xl: '10px' }}
            fontFamily="FedraSansCondensedMedium, sans-serif"
            as="h2"
          >
            {sectionContent?.[0]?.text} &nbsp;
            {sectionContent?.[1]?.text}
          </Heading>
          {/* <Heading
            color="#181818"
            fontSize={{ xl: '60px', lg: '50px', md: '40px', base: '26px' }}
            lineHeight={{ xl: '70px', base: '42px' }}
            fontFamily="FedraSansCondensedMedium, sans-serif"
          >
          </Heading> */}
        </Box>
        <Box
          minH={{ base: "", md: "260px", xl: "300px" }}
          display="flex"
          alignItems="center"
          // position="absolute"
          background="#FFFFFF"
          margin={{ base: 'auto', md: '0px auto' }}
          padding="0px"
          maxW="954px"
          // top={{ base: '130px', md: '-26px', xl: '-38px' }}
          // zIndex="6"
          // right={{
          //   '2xl': '152px',
          //   xl: '100px',
          //   lg: '60px',
          //   md: '50px',
          //   base: 0,
          // }}
          // left={{ base: 0, md: 'unset' }}
          width={{ base: 'calc(100% - 40px)', lg: "100%" }}
        >
          <Container padding={{ md: '20px 60px', base: '5px 20px' }} maxW="100%">
            {/* <Text
              color="#000000"
              display="block"
              fontSize={{ md: '20px', lg: '24px' }}
              lineHeight={{ base: '31px', md: '40.06px', }}
              fontWeight="400"
              textAlign="center"
              paddingBottom={{ base: '5px', md: '25px', xl: '45px' }}
            >
              {sectionContent?.[2]?.text}
            </Text>

            <Text
              color="#000000"
              display="block"
              fontSize={{ xl: '68px', lg: '55px', md: '45px', base: '30px' }}
              lineHeight={{ md: '40.06px', base: '31px' }}
              textAlign="center"
              fontWeight="bold"
              padding={{ base: '0px 0px 5px 0px', md: sectionContent?.[5]?.text ? '0px 0px 40px 0px' : '0px 0px 0px 0px', xl: sectionContent?.[5]?.text ? '0px 0px 53px 0px' : '0px 0px 0px 0px' }}
              fontFamily="FedraSansCondensedMedium, sans-serif"
            >
              {currRegion?.toLowerCase() === 'in' ? sectionContent?.[3]?.text : sectionContent?.[4]?.text}
            </Text>
            {currRegion?.toLowerCase() === 'in' && sectionContent?.[5]?.text && (
              <Text
                display="block"
                fontSize={{ md: '24px', lg: '32px' }}
                lineHeight={{ base: '22px', xl: '40.06px' }}
                fontStyle="italic"
                color="#FF9900"
                textAlign="center"
              >
                {sectionContent?.[5]?.text}
              </Text>
            )} */}
            <TableContainer w="100%" mb="15px" whiteSpace="normal !important" maxW="100%">
              <Table variant={{ base: "unstyled", md: "simple" }} className="JsonTable" >
                <Tbody>
                  {sectionContent[6].table.data.map((col, i) => {
                    if (col['New Column'].toLowerCase().slice(0, 2) !== hideRegion) {
                      return (
                        <Tr key={nanoid()} borderBottom={i === sectionContent[6]?.table?.data?.length - 1 ? "0px solid " : "1px solid rgba(0, 0, 0, 0.2)"} h="100%">
                          {
                            sectionContent[6].table.columns.map((row, index) => {
                              if (index === 0) {

                                return (
                                  <Td key={nanoid()}
                                    w={{ base: "100%", md: "30%" }}
                                    display={{ base: "inherit", md: "table-cell" }}
                                    pl="9px"
                                    // w="100%"
                                    verticalAlign="baseline !important"
                                  >
                                    <Box
                                      mt={{ base: "20px", md: "0" }}
                                    >

                                      <Text color="#686868" fontSize="18px" fontFamily="FedraSansStd-book"
                                        mb={{ base: "10px", md: "0" }} lineHeight={{ base: "30px", md: "40px" }}
                                      >

                                        {col[row]}
                                      </Text>
                                    </Box>
                                  </Td>
                                )
                              }
                              else if (index === 1) {
                                return (
                                  <Td key={nanoid()}
                                    display={{ base: "inherit", md: "table-cell" }}
                                  // w="100%"
                                  >
                                    <Box
                                      mb={{ base: "20px", md: "0" }}
                                    >

                                      <Text fontSize={{ base: i === 0 ? "30px" : "16px", md: i === 0 ? "30px" : "18px" }} lineHeight={{ base: i === 0 ? "30px" : "22px", md: "30px" }} fontFamily={i === 0 ? "FedraSansCondensedMedium,sans-serif" : "FedraSansStd-medium, sans-serif"}
                                      >

                                        {col[row] === "Y" ? <CheckIcon color="#F58220" fontWeight="bold" /> : col[row]}<Text as="span" color="#FF9900" fontFeatureSettings="'case' on"
                                          fontFamily='FedraSansStd-medium, sans-serif'
                                          fontSize='18px'
                                          fontStyle='italic'
                                          fontWeight={500}
                                          lineHeight='31px'>
                                          &nbsp;{col[sectionContent[6].table.columns[3]] ?
                                            col[sectionContent[6].table.columns[3]] : ""}
                                        </Text>
                                      </Text>
                                    </Box>

                                  </Td>
                                )
                              }
                            })
                          }
                        </Tr>
                      )
                    }
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;
