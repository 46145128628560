import { Avatar, Box, Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import StructuredTextParser from '@landing_pages/ila/components/Text/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


const NextArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide % 1 == 0 && (
        <Box
          pos="absolute"
          textAlign={'center'}
          top="45%"
          display={{ base: 'none', md: 'flex' }}
          zIndex="10"
          cursor="pointer"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ md: '54px', base: '34.4px' }}
          h={{ md: '54px', base: '34.4px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094409-right-arrow.svg)`}
          right={{ base: '54px', md: '100px', lg: '10px' }}
          bgSize="13px"
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4224 11.5241L0.77002 1.87175L2.64177 0L14.1659 11.5241L2.64177 23.0482L0.77002 21.1765L10.4224 11.5241Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const PrevArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <Box
          pos="absolute"
          top="45%"
          zIndex="10"
          display={{ base: 'none', md: 'flex' }}
          cursor="pointer"
          bgSize="13px"
          bgColor="#F27123"
          _hover={{ background: '#BA5010' }}
          border="0"
          borderRadius="60px"
          w={{ base: '34.4px', md: '54px' }}
          h={{ base: '34.4px', md: '54px' }}
          bgPos="center"
          bgRepeat="no-repeat"
          color="transparent"
          alignItems="center"
          justifyContent="center"
          //bgImage={`url(${config.staticPath}/d/46272/1692094406-left-arrow.svg)`}
          left={{ base: '3px' }}
          onClick={onClick}
          boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
        >
          <svg
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.74364 11.9997L13.396 2.34734L11.5242 0.475586L0.000130653 11.9997L11.5242 23.5238L13.396 21.6521L3.74364 11.9997Z"
              fill="white"
            />
          </svg>
        </Box>
      )}
    </>
  );
};

const ParticipantsSharing = ({ sectionContent }) => {
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });
  const sliderDotSettings = {
    base: 1,
    md: 2
  };

  const settings = {
    dots: true,
    // arrows: false,
    infinite: false,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 2.2,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    appendDots: dots => (
      <Flex
        height="40px"
        position="relative"
      >
        <Flex
          marginRight="auto"
          marginLeft="auto"
          bgColor="#0E0E6B"
          // width="128px"
          height="14px"
          borderRadius="9px"
          justifyContent="space-between"
          className="Glimpses appendDots"
          marginTop="3em"
        >
          <UnorderedList
            m="0"
            display="flex"
          >
            {/* {dots}{' '} */}
            {dots.map((dot, ind) => {
              const button = dot.props.children;
              return (
                <ListItem
                  onClick={e => {
                    // console.log('button.props', dot, activeTab, Math.ceil(activeTab) % sliderDotSettings.md);
                    button.props.onClick(e);
                  }}
                  key={ind}
                  width="19px !important"
                  height="13px !important"
                  borderRadius="9px !important"
                  background={(Math.ceil((activeTab) / (sliderDotSettings[isSmallScreen ? 'base' : 'md'])) === parseInt(dot.key)) ? "#F27123" : "#0E0E6B"}
                  margin="0 !important"
                />
              );
            })}
          </UnorderedList>
        </Flex>
      </Flex>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 934,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      px={{ base: '16px', sm: 0 }}
      mx="auto"
      w="100%"
      pt={{ base: '30px', md: '40px', lg: '60px' }}
      pb={{ base: '50px', md: '70px', lg: '106px' }}

    >
      {sectionContent ? (
        <>
          <Heading
            fontSize={{ base: '30px', md: '42px' }}
            fontWeight="semibold"
            lineHeight="51px"
            color="#D8A24C"
            fontFamily="FedraSansCondensedMedium, sans-serif"
            marginBottom="20px"
            letterSpacing="2px"
            as="h2"
          >
            {sectionContent[0].title[0].titleText}
          </Heading>

          <Box position="relative"
            _before={{
              display: true,
              content: "''",
              height: '99%',
              position: 'absolute',
              bottom: '0px',
              width: { base: "0", md: '5%' },
              top: '0px',
              right: activeTab % 1 == 0 ? '0px' : 'none',
              zIndex: 2,
              // background: isSmallScreen ? 'none' : (activeTab % 1 == 0 ?
              //     'linear-gradient(90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)': 'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
            }}

            _after={{
              display: true,
              content: "''",
              height: '99%',
              position: 'absolute',
              bottom: '0px',
              width: { base: "0", md: '5%' },
              top: '0px',
              left: activeTab > 0 ? '0' : 'none',
              zIndex: 2,
              // background: isSmallScreen ? 'none' : (activeTab  > 0 &&
              //     'linear-gradient(-90deg, rgba(8, 8, 74, 0) 0%, #08084A 100%)'),
            }}>
            <Slider
              {...settings}
              beforeChange={(old, newChange) => {
                setActiveTab(Math.ceil(newChange));
              }}
            >
              {sectionContent[0]?.testimonials?.map(postData => {
                return (
                  <Flex paddingRight="1.6rem" key={nanoid()}>
                    <StructuredTextParser
                      color="#fff"
                      maxW="100%"
                      mx="0"
                      lineHeight={{ base: '24px', md: '32px' }}
                      fontSize={{ base: '16px', md: '18px' }}
                      str={render(postData.testimonialText)}
                    />
                    <Box
                      display="flex"
                      flexDirection={{ md: 'row' }}
                      marginTop="25px"
                      width={{ base: '100%', xl: "70%" }}
                      mx="auto"
                    >
                      <Box width={{ base: 'auto' }}>
                        <Avatar
                          name="Participant"
                          size="xl"
                          src={postData?.photo?.url}
                        />
                      </Box>

                      <Box margin={{ base: '30px 0 20px 16px', md: '5px 0 0 24px' }}>
                        <Text
                          // fontStyle="italic"
                          // fontWeight={400}
                          fontFamily="FedraSansStd-medium"
                          color="#fff"
                          fontSize={{ base: '16.47px', md: '20px', }}
                          lineHeight={{ base: '23.21px', md: '28px' }}
                        >
                          {postData.userName}
                        </Text>
                        <StructuredTextParser
                          paddingBottom={{ base: '15px' }}
                          color="#D8A24C"
                          lineHeight={{ base: '16.2px', md: '28px' }}
                          fontSize={{ base: '14px', md: '20px' }}
                          fontStyle="italic"
                          str={render(postData?.userDescription)}
                        />
                      </Box>
                    </Box>
                  </Flex>
                );
              })}
            </Slider>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default ParticipantsSharing;
