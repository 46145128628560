import { Box } from '@chakra-ui/react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import SingleLineText from '@landing_pages/ila/components/Text/SingleLineText';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import BigScreenPastShareSlider from '../components/Gallery/BigScreenPastShareSlider';
import MobileScreenPastShareSlider from '../components/Gallery/MobileScreenPastShareSlider';

const PastSpeakersShare = ({ sectionContent }) => {
  const [activeTab, setActiveTab] = useState(0);

  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  return sectionContent ? (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      px={{ base: '16px', xl: 0 }}
      py={{ base: '30px', md: '40px', xl: '60px' }}
      mx="auto"
      w="100%">
      {sectionContent ? (
        <SingleLineText
          sectionContent={sectionContent[0]}
          fontSize={{ base: '30px', md: '42px' }}
          fontWeight="semibold"
          lineHeight="51px"
          color="#D8A24C"
          letterSpacing="1px"
          fontFamily="FedraSansCondensedMedium, sans-serif"
          mt="0px"
        />
      ) : null}



      {sectionContent && (
        <>
          {isSmallScreen ? (
            <MobileScreenPastShareSlider
              shares={sectionContent[1]?.testimonials}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <BigScreenPastShareSlider
              activeTab={activeTab}
              shares={sectionContent[1]?.testimonials}
              setActiveTab={setActiveTab}
            />
          )}
        </>
      )}
    </Box>
  ) : null;
};

export default PastSpeakersShare;
