import { Box, Flex } from '@chakra-ui/react';
import IlaSectionCard from '@ila/components/Card/IlaSectionCard';
import SectionTitle from '@ila/components/Text/SectionTitle';
import { nanoid } from 'nanoid';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const ILASection = ({ sectionContent }) => {
  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
      }}
      mx="auto"
      w="100%"
      py={{ base: "30px", md: '0', xl: '0' }}
    >
      <Box
        pr={{ base: '12px', md: '0' }}
        pl={{ base: '20px', md: '0' }}
        // mt={{ base: '40px', lg: '80px' }}
        pb={{ base: '20px', md: '30px', xl: '50px' }}
      >
        <SectionTitle titleObj={sectionContent[0]} />
      </Box>
      <Flex
        px={{ base: '20px', md: '0' }}
        flexWrap="wrap"
        // justifyContent="center"
        maxW={{ base: '100%', md: '100%', xl: '100%' }}
        mx="auto"
      // alignItems="center"
      >
        {sectionContent[1].cards.map((card, index) => {
          return (
            <IlaSectionCard
              w="100%"
              card={card}
              // maxW="299px"
              pl={{
                base: '0',
                md: index % 2 !== 0 && '29.17px',
                lg: index % 2 !== 0 && '29.17px',
                xl: index !== 0 && '29.17px',
              }}
              paddingRight={{ base: '0', md: '19.83px' }}
              paddingBottom={{ base: '20px', md: '0px' }}
              borderRight={{
                base: 'none',
                md:
                  index !== sectionContent[1].cards.length - 1 &&
                  '2px dotted rgba(255, 255, 255, 0.36)',
              }}
              borderBottom={{
                base:
                  index !== sectionContent[1].cards.length - 1 &&
                  '2px dotted rgba(255, 255, 255, 0.36)',
                md: 'none',
              }}
              maxW={{ base: '100%', md: '50%', xl: '290px' }}
              key={nanoid()}
              flexDir="column"
              my={{ base: '16px', md: '60px' }}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default ILASection;
